import React from 'react';
import PropTypes from 'prop-types';

const PoweredBy360 = React.memo(({ fill}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg" width="70" height="20" viewBox="0 0 70 20">
        <g transform="translate(-1630 -992)">
            <g className="d" transform="translate(1634.116 995.492)">
                <g className="e" transform="translate(0.118 0)">
                    <path fill={fill} className="a" d="M1432.026,13.052c-.265-.031-.533-.051-.8-.1a5.8,5.8,0,0,1-3.219-1.731,5.694,5.694,0,0,1-1.668-3.353,5.8,5.8,0,0,1,1.353-4.663,5.672,5.672,0,0,1,3.221-1.989,5.9,5.9,0,0,1,5.45,1.423,5.56,5.56,0,0,1,1.7,2.795,6,6,0,0,1-2.966,6.916,5.558,5.558,0,0,1-2.465.678.753.753,0,0,0-.108.02Zm5.1-5.993a4.854,4.854,0,1,0-4.85,4.854,4.854,4.854,0,0,0,4.85-4.854" transform="translate(-1386.819 -1.036)"/>
                    <path fill={fill} className="a" d="M1102.848,0l.077.03a.58.58,0,0,1,.27.96c-.244.3-.5.6-.745.9q-.643.773-1.284,1.547a.271.271,0,0,0-.086.136,4.18,4.18,0,0,1,.636-.022,4.053,4.053,0,0,1,2.7,1.074,4.092,4.092,0,0,1,1.376,2.746,4.22,4.22,0,0,1-3.625,4.606,1.624,1.624,0,0,0-.37.043h-.444a8.418,8.418,0,0,1-.832-.133,4.316,4.316,0,0,1-3.147-3.536,4.145,4.145,0,0,1,.821-3.065c.553-.758,1.177-1.46,1.769-2.187.744-.914,1.5-1.819,2.241-2.733a1.148,1.148,0,0,1,.479-.362Zm-4.3,7.8a3.09,3.09,0,1,0,3.094-3.09,3.112,3.112,0,0,0-3.094,3.09" transform="translate(-1066.993 0)"/>
                    <path fill={fill} className="a" d="M4.717,15.915A.735.735,0,0,1,4.2,15.4Q2.262,10.459.312,5.518.157,5.125,0,4.732V4.455a1.057,1.057,0,0,1,.135-.241.575.575,0,0,1,.976.167c.264.656.52,1.315.779,1.973Q3.434,10.276,4.978,14.2c.013.033.018.072.055.1.049-.019.055-.07.074-.109q.854-1.8,1.7-3.595a.589.589,0,0,1,.769-.324.565.565,0,0,1,.29.8c-.317.683-.644,1.362-.966,2.043-.382.806-.766,1.611-1.144,2.419a.682.682,0,0,1-.488.391Z" transform="translate(-0.109 -3.9)"/>
                    <path fill={fill} className="a" d="M1859.808,5.785a1.761,1.761,0,0,1-.508.918,1.679,1.679,0,0,1-2.184.01,1.584,1.584,0,0,1,1.4-2.726,1.518,1.518,0,0,1,1.248,1.14c.013.044.029.087.043.131Zm-1.584.969a1.172,1.172,0,0,0,1.163-1.21,1.189,1.189,0,1,0-2.376-.016,1.2,1.2,0,0,0,1.213,1.227" transform="translate(-1805.166 -3.842)"/>
                    <path fill={fill} className="a" d="M736.254,2.129a.283.283,0,0,0-.16-.016h-4.814a.528.528,0,0,1-.569-.584.531.531,0,0,1,.4-.479,1.028,1.028,0,0,1,.262-.023h5.383a2.081,2.081,0,0,1,.29.022.742.742,0,0,1,.63.886,1.644,1.644,0,0,1-.47.8q-.9.982-1.793,1.969c-.03.033-.091.062-.072.108s.09.032.138.035a4.239,4.239,0,0,1,2.394.849,3.375,3.375,0,0,1,1.349,2.467,3.969,3.969,0,0,1-.942,3.04,4.758,4.758,0,0,1-2.549,1.561,6.142,6.142,0,0,1-3.938-.3,4.875,4.875,0,0,1-1.881-1.406.541.541,0,0,1-.027-.623.535.535,0,0,1,.564-.237.646.646,0,0,1,.359.224,3.873,3.873,0,0,0,2.269,1.321,4.844,4.844,0,0,0,2.9-.2A3.274,3.274,0,0,0,737.9,9.781,2.662,2.662,0,0,0,737.654,7a2.786,2.786,0,0,0-1.885-1.035,4.609,4.609,0,0,0-1.68.062.544.544,0,0,1-.51-.923q1.309-1.443,2.617-2.887c.023-.026.06-.045.058-.092" transform="translate(-709.652 -0.998)"/>
                    <path fill={fill} className="a" d="M124.012,2h-3.829a1.107,1.107,0,0,1-.275-.026.573.573,0,0,1-.434-.559.566.566,0,0,1,.434-.557,1.024,1.024,0,0,1,.234-.024c2.534,0,5.068-.017,7.6,0a3.93,3.93,0,0,1,3.877,3.444A3.893,3.893,0,0,1,130.5,7.591a3.12,3.12,0,0,1-.789.607c-.115.061-.122.113-.054.22q1.034,1.619,2.06,3.243c.045.07.09.14.133.211a.576.576,0,1,1-.969.624q-.953-1.474-1.9-2.952l-.742-1.155a.582.582,0,0,1,.321-.929,2.766,2.766,0,0,0,1.894-2.169,2.8,2.8,0,0,0-2.324-3.262A3.2,3.2,0,0,0,127.7,2h-3.69" transform="translate(-116.267 -0.806)"/>
                    <path fill={fill} className="a" d="M1892.71,32.471c0-.2.007-.4,0-.6-.006-.135.029-.2.176-.213a4.081,4.081,0,0,1,.678-.007.453.453,0,0,1,.464.426.37.37,0,0,1-.194.357c-.032.02-.108.037-.037.094a.657.657,0,0,1,.2.4.466.466,0,0,1,.019.081c0,.084.106.19.035.238s-.215.024-.325.012c-.06-.007-.076-.064-.091-.112-.031-.1-.053-.206-.085-.307a.325.325,0,0,0-.387-.181c-.057.022-.054.069-.055.114,0,.12,0,.24,0,.36,0,.092-.034.131-.13.134-.266.008-.266.012-.266-.26v-.541m.4-.308c.009.089-.044.22.149.21.222-.011.341-.075.343-.217s-.11-.22-.321-.22c-.163,0-.171.01-.171.227" transform="translate(-1840.293 -30.765)"/>
                </g>
            </g>
        </g>
    </svg>
));
PoweredBy360.propTypes = { fill: PropTypes.string };
PoweredBy360.defaultProps = { fill: "#eeebe9" };
export { PoweredBy360 };
