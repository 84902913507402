import styled from 'styled-components'
import { Col, Layout } from 'antd';

export const FullScreenColor = styled(Layout)`
  background-color: transparent; 
  height:100%; 
  width: 100vw;
`
export const ContentLabel = styled.span`
  font-size:14px; 
  font-family:Martel;
  color: #EEEBE9; 
  z-index: 1; 
  font-style: normal; 
  line-height: 1.05;

  @media (min-width: 768px) {
    font-size:21px; 
  }
`
export const ContentWrap = styled(Col)`
  width: 100%; 
  min-height: 1px; 
  display: flex; 
  flex-direction: column; 
  justify-content: space-between; 
  padding:0 20px; 
  padding-bottom: 0;
  :hover {
    .still{
      opacity:0
    } 
    .label-box {
      opacity: 1; 
      @media (min-width: 768px) {
        height: 70px;
      }
    } 
    .shadow{
      opacity:0
    }
  }

  &.mobile{
    .still{
      opacity:0
    } 
    .label-box {
      opacity: 1;
    } 
    .shadow{
      opacity:0
    }
  }
`
export const ContentLabelBox = styled.div`
  opacity: 0; 
  position: absolute; 
  z-index: 2; 
  bottom: -25px; 
  left: 0px; 
  width: 86%; 
  padding: 16px 30px 18px 37px; 
  background: #444444; 
  color: #7B8179;
  letter-spacing: 0.66px; 
  font-size: 9px; 
  font-family:Overpass; 
  cursor: pointer; 
  display: flex; 
  flex-direction: column; 
  justify-content: space-between; 
  -webkit-transition: opacity .5s ease-in-out, all 0.5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out, all 0.5s ease-in-out;
  -ms-transition: opacity .5s ease-in-out, all 0.5s ease-in-out;
  -o-transition: opacity .5s ease-in-out, all 0.5s ease-in-out;
  transition: opacity .5s ease-in-out, all 0.5s ease-in-out; 
  
  span {
    display: flex; 
    justify-content: space-between;
  } 
  &.active {
    background:#EEEBE9;
    span{
      color:#444444;
    }
    svg {
      path{
        fill:#444444;
      }
    }
    opacity: 1; 
    .shadow{
      opacity:0
    }
  }

  @media (min-width: 768px) {
    width: 75%; 
    height: 45px; 
    padding: 24px 8px 20px 24px; 
    bottom: -45px;

    &.active {
      height: 70px; 
    }
  }
`
export const ContentLabelFixed = styled.span`
  opacity:1; 
  position: absolute; 
  z-index: 2; 
  bottom: -45px; 
  left: 20px; 
  font-size:14px; 
  font-family:Martel; 
  color: #FFF;

  @media (min-width: 768px) {
    font-size:21px; 
  }
`
export const ContentScrollable = styled.main`
  overflow: auto;
  margin-top:50px;
`
export const ContentImage = styled.img`
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  width: 100%; 
  height: 100%; 
  position: absolute;
`
export const ContentImageDiv = styled.div`
  width: 100%; 
  min-height: 1px; 
  height: auto; 
  padding-top: 56.5%; 
  position: relative; 
  cursor: pointer;
`
export const ContentSpace = styled.div`
  height:100px; 
  display:flex; 
  flex-direction: row; 
  width: 100%;
`
export const ContentRow = styled.div`
  min-height: 250px; 
  display:flex; 
  flex-direction: row; 
  width: 100%;

  @media (max-width: 767px) {
    &:last-child{
      margin-bottom: 40px;
    }
  }

  @media (min-width: 768px) {
    padding: 0 40px;
    
  }
`
export const ContentRows = styled.div`
  min-height: 1px; 
  display: flex; 
  flex-direction: column; 
  justify-content: space-between; 
  padding: 0;
  @media (max-width: 767px) {
    margin-top: .5rem;
  }
`
export const ContentShadow = styled.div`
  &.shadow{
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    width: 100%; 
    height: 100%; 
    position: absolute; 
    z-index: 1; 
    background: linear-gradient(61deg, #12121200 0%, #121212 100%); 
    -webkit-transition: opacity .5s ease-in-out, all 0.5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out, all 0.5s ease-in-out;
    -ms-transition: opacity .5s ease-in-out, all 0.5s ease-in-out;
    -o-transition: opacity .5s ease-in-out, all 0.5s ease-in-out;
    transition: opacity .5s ease-in-out, all 0.5s ease-in-out;
  }
`