import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateMuted } from "./../../effects/actions";

const sound_animation = keyframes`
  from {opacity: 1; transform: translateX(0px);}
  to {opacity: 0; transform:translateX(10px);}
`
const StyledContainer = styled.div`
  position: fixed !important;
  bottom: 40px;
  right: 20px;
  z-index: 1;
  width: 40px;
  height: 40px;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 50px;
    height: 50px;
    bottom: 50px;
    right: 40px;
  }

  svg {
    width: 100%;
    height: 100%;

    #Path_725 {
      transition: all 0.5s ease-in-out;
    }

    #sound_off {
      transition: all 0.2s ease-in-out;
    }

    #sound_on_left {
      animation-name: ${sound_animation};
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }
  }

  .hover {
    svg {
    }
  }
`;

const MuteControl = ({ muted, updateMuted }) => {
  const [hover, setHover] = useState(false);

  return (
    <StyledContainer
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => {
        updateMuted(!muted);
      }}
    >
      <div className={hover ? "hover hover-image" : "hover-image"}>
        <svg
          id="sound_button"
          data-name="sound button"
          xmlns="http://www.w3.org/2000/svg"
          width="58"
          height="58"
          viewBox="0 0 58 58"
        >
          <g
            id="Path_725"
            data-name="Path 725"
            fill="#121212"
            opacity={1.0}
          >
            <path
              d="M 29 57.5 C 25.15229988098145 57.5 21.41996955871582 56.74652862548828 17.90666961669922 55.26052856445312 C 14.51296997070312 53.82511901855469 11.46500968933105 51.77009963989258 8.84745979309082 49.15253829956055 C 6.229899883270264 46.53498840332031 4.174880027770996 43.48703002929688 2.7394700050354 40.09333038330078 C 1.253469944000244 36.58002853393555 0.5 32.84769821166992 0.5 29 C 0.5 25.15229988098145 1.253469944000244 21.41996955871582 2.7394700050354 17.90666961669922 C 4.174880027770996 14.51296997070312 6.229899883270264 11.46500968933105 8.84745979309082 8.84745979309082 C 11.46500968933105 6.229899883270264 14.51296997070312 4.174880027770996 17.90666961669922 2.7394700050354 C 21.41996955871582 1.253469944000244 25.15229988098145 0.5 29 0.5 C 32.84769821166992 0.5 36.58002853393555 1.253469944000244 40.09333038330078 2.7394700050354 C 43.48703002929688 4.174880027770996 46.53498840332031 6.229899883270264 49.15253829956055 8.84745979309082 C 51.77009963989258 11.46500968933105 53.82511901855469 14.51296997070312 55.26052856445312 17.90666961669922 C 56.74652862548828 21.41996955871582 57.5 25.15229988098145 57.5 29 C 57.5 32.84769821166992 56.74652862548828 36.58002853393555 55.26052856445312 40.09333038330078 C 53.82511901855469 43.48703002929688 51.77009963989258 46.53498840332031 49.15253829956055 49.15253829956055 C 46.53498840332031 51.77009963989258 43.48703002929688 53.82511901855469 40.09333038330078 55.26052856445312 C 36.58002853393555 56.74652862548828 32.84769821166992 57.5 29 57.5 Z"
              stroke="none"
            />
            <path
              d="M 29 1 C 25.21958160400391 1 21.55278015136719 1.740180969238281 18.1014404296875 3.199970245361328 C 14.76734161376953 4.61016845703125 11.77280044555664 6.629219055175781 9.201011657714844 9.201011657714844 C 6.629219055175781 11.77280044555664 4.61016845703125 14.76734161376953 3.199970245361328 18.1014404296875 C 1.740180969238281 21.55278015136719 1 25.21958160400391 1 29 C 1 32.78041839599609 1.740180969238281 36.44721984863281 3.199970245361328 39.8985595703125 C 4.61016845703125 43.23265838623047 6.629219055175781 46.22719955444336 9.201011657714844 48.79898834228516 C 11.77280044555664 51.37078094482422 14.76734161376953 53.38983154296875 18.1014404296875 54.80002975463867 C 21.55278015136719 56.25981903076172 25.21958160400391 57 29 57 C 32.78041839599609 57 36.44721984863281 56.25981903076172 39.8985595703125 54.80002975463867 C 43.23265838623047 53.38983154296875 46.22719955444336 51.37078094482422 48.79898834228516 48.79898834228516 C 51.37078094482422 46.22719955444336 53.38983154296875 43.23265838623047 54.80002975463867 39.8985595703125 C 56.25981903076172 36.44721984863281 57 32.78041839599609 57 29 C 57 25.21958160400391 56.25981903076172 21.55278015136719 54.80002975463867 18.1014404296875 C 53.38983154296875 14.76734161376953 51.37078094482422 11.77280044555664 48.79898834228516 9.201011657714844 C 46.22719955444336 6.629219055175781 43.23265838623047 4.61016845703125 39.8985595703125 3.199970245361328 C 36.44721984863281 1.740180969238281 32.78041839599609 1 29 1 M 29 0 C 45.01625823974609 0 58 12.98374176025391 58 29 C 58 45.01625823974609 45.01625823974609 58 29 58 C 12.98374176025391 58 0 45.01625823974609 0 29 C 0 12.98374176025391 12.98374176025391 0 29 0 Z"
              stroke="none"
              fill="#a19d9c"
            />
          </g>
          {/* <path
            id="sound_on"
            data-name="sound on"
            d="M-654.62-868.251a2.166,2.166,0,0,1-1.078-.289l-5.189-2.965H-663.3a2.156,2.156,0,0,1-2.162-2.146v-7.2A2.156,2.156,0,0,1-663.3-883h2.409l5.189-2.965a2.151,2.151,0,0,1,2.162,0,2.119,2.119,0,0,1,1.078,1.858v13.7a2.121,2.121,0,0,1-1.078,1.858A2.161,2.161,0,0,1-654.62-868.251Zm-8.676-13.315a.719.719,0,0,0-.721.716v7.2a.719.719,0,0,0,.721.716h2.793l5.522,3.155a.72.72,0,0,0,.722,0,.706.706,0,0,0,.359-.62v-13.7a.706.706,0,0,0-.359-.62.709.709,0,0,0-.722,0l-5.522,3.155Z"
            transform="translate(686.458 906.252)"
            fill="#e9e5e4"
            opacity="0.65"
          /> */}
          <path
            id="sound_off"
            data-name="sound off"
            d="M-325.744,11407.314a.731.731,0,0,1-.069-1.042l15-17.022a.756.756,0,0,1,1.056-.066.731.731,0,0,1,.069,1.042l-15,17.023a.755.755,0,0,1-.564.252A.735.735,0,0,1-325.744,11407.314Zm9.5-.6-4.758-2.717.973-1.1,4.5,2.573a.716.716,0,0,0,.721,0,.706.706,0,0,0,.361-.621v-8.248l1.44-1.623v9.871a2.12,2.12,0,0,1-1.079,1.858,2.167,2.167,0,0,1-1.082.292A2.169,2.169,0,0,1-316.239,11406.711Zm-9.76-5.11v-7.2a2.157,2.157,0,0,1,2.161-2.147h2.411l5.188-2.966a2.157,2.157,0,0,1,2.164,0,2.124,2.124,0,0,1,1.022,1.423l-1.386,1.56v-1.125a.7.7,0,0,0-.361-.618.713.713,0,0,0-.721,0l-5.523,3.155h-2.794a.716.716,0,0,0-.719.716v7.2a.717.717,0,0,0,.719.716h.484l-1.162,1.313A2.146,2.146,0,0,1-326,11401.6Z"
            transform="translate(346.999 -11368.999)"
            fill="#e9e5e4"
            opacity={!muted ? 0 : 0.65}
          />
          <g
            id="Group_655"
            data-name="Group 655"
            transform="translate(-1815 -971.001)"
            opacity={!muted ? 1 : 0}
          >
            <path
              id="sound_on"
              data-name="sound on"
              d="M-654.62-868.251a2.166,2.166,0,0,1-1.078-.289l-5.189-2.965H-663.3a2.156,2.156,0,0,1-2.162-2.146v-7.2A2.156,2.156,0,0,1-663.3-883h2.409l5.189-2.965a2.151,2.151,0,0,1,2.162,0,2.119,2.119,0,0,1,1.078,1.858v13.7a2.121,2.121,0,0,1-1.078,1.858A2.161,2.161,0,0,1-654.62-868.251Zm-8.676-13.315a.719.719,0,0,0-.721.716v7.2a.719.719,0,0,0,.721.716h2.793l5.522,3.155a.72.72,0,0,0,.722,0,.706.706,0,0,0,.359-.62v-13.7a.706.706,0,0,0-.359-.62.709.709,0,0,0-.722,0l-5.522,3.155Z"
              transform="translate(2498.458 1877.252)"
              fill="#e9e5e4"
              opacity="0.65"
            />
            <g id='sound_on_left'>
              <path
                id="Path_726"
                data-name="Path 726"
                d="M0,0S2.924,3.343,2.924,6.849,0,14.021,0,14.021"
                transform="translate(1852.55 993.017)"
                fill="none"
                stroke="#e9e5e4"
                stroke-width="1"
              ></path>
              <path
                id="Path_727"
                data-name="Path 727"
                d="M0,0S2,2.183,2,4.472,0,9.156,0,9.156"
                transform="translate(1849.5 995.286)"
                fill="none"
                stroke="#e9e5e4"
                stroke-width="1"
              ></path>
              <path
                id="Path_728"
                data-name="Path 728"
                d="M0,0A4.065,4.065,0,0,1,1.385,2.456,4.519,4.519,0,0,1,0,5.28"
                transform="translate(1845.5 997.026)"
                fill="none"
                stroke="#e9e5e4"
                stroke-width="1"
              ></path>
            </g>
          </g>
        </svg>
      </div>
    </StyledContainer>
  );
};

MuteControl.propTypes = {};

const mapStateToProps = ({ app }) => {
  const { muted } = app;
  return { muted };
};

export default connect(mapStateToProps, {
  updateMuted,
})(React.memo(MuteControl));
