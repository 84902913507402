import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";

const PlayerComponent = ({ currentActSong, muted }) => {
  const ref = useRef();

  const src = currentActSong ? require(`./../../assets/music/${currentActSong}.mp3`) : '';

  useEffect(() => {
    if (!ref.current || !currentActSong) return;

    if (currentActSong === "living-room" || currentActSong === "suite") {
      ref.current.volume = 0.5;
    } else {
      ref.current.volume = 1.0;
    }

    // player.oncanplay = function() {
    //   player.play();
    // };

  }, [currentActSong, muted]);

  return <audio ref={ref} id="audio-background-player" autoPlay loop playsinline src={src} muted={muted}/>;
};

const mapStateToProps = ({ app }) => {
  const { currentActSong, muted } = app;

  return { currentActSong, muted };
};

export default connect(mapStateToProps, null)(React.memo(PlayerComponent));
