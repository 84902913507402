import React, { useState } from 'react';
import { MdErrorOutline, MdCheckCircle } from 'react-icons/md'
import { Container, 
        InputText, 
        Form, 
        InputTextContainer, 
        ValidationSuccess, 
        InputTitle, 
        ButtonsContainer, 
        ValidationError, 
        FormButton,
        ForgotContainer,
        TextForgotPassword
      } from './index.styled';
import { useIntl } from 'react-intl';
import { FormattedMessage } from '..';
import { signInAPI } from './../../effects/actions'
import { store } from './../../effects/store'

const FormLogin = ({ createAccount, forgotPassword }) => {

  const initialInputs = { password: '', email: '' }
  const minLenghtPassword = 8;
  const [ inputs, setAllInputs ] = useState(initialInputs);
  const intl = useIntl();

  const [ emailError, setEmailError ] = useState(false);
  const [ passwordError, setPasswordError ] = useState(false);
  const [ logged, setLogged ] = useState(false);

  const handleChange = (e) => setAllInputs({...inputs, [e.target.name]: e.target.value})

  const CheckForm = () => {
    let allValid = true;
    setEmailError(!validateEmail(inputs.email))

    if(validatePassword(inputs.password) !== true) allValid = false;
    if(validateEmail(inputs.email) !== true) allValid = false;

    return allValid;
  }

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const validatePassword = (password) => {
    setPasswordError(password.length < minLenghtPassword);
    return password.length >= minLenghtPassword;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (CheckForm()) {
      console.log("send")
      setAllInputs(initialInputs);
      setLogged(true);
      // redirect
    } else {
      console.log("missing values")
      setLogged(false);
    }
  }

  return (
    <Container>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <InputTextContainer>
            <InputTitle><FormattedMessage id="login.email" /></InputTitle>
            <InputText
              className={inputs.email !== '' ? 'b-bottom filled': 'b-bottom empty'}
              placeholder={intl.formatMessage({ id: "login.enter-your-email" })}
              name="email"
              value={inputs.name}
              onChange={handleChange}
            />
            {emailError && <ValidationError><MdErrorOutline /><FormattedMessage id="login.invalid-email" /></ValidationError>}
        </InputTextContainer>
        <InputTextContainer>
          <InputTitle><FormattedMessage id="login.password" /></InputTitle>
          <InputText
            className={inputs.password !== '' ? 'b-bottom filled': 'b-bottom empty'}
            autoComplete="no"
            placeholder={intl.formatMessage({ id: "login.enter-your-password" })}
            name="password"
            type="password"
            value={inputs.password}
            onChange={handleChange}
          />
          {passwordError && <ValidationError><MdErrorOutline /><FormattedMessage id="login.min-password" /></ValidationError>}
          {logged && <ValidationSuccess><MdCheckCircle /><FormattedMessage id="login.sucessuful" /></ValidationSuccess>}
        </InputTextContainer>
        <ForgotContainer>
          <TextForgotPassword onClick={forgotPassword}>
            <FormattedMessage id="login.forgot-password" />
          </TextForgotPassword>
        </ForgotContainer>
        <ButtonsContainer>
          <FormButton 
            type="submit" 
            disabled={false} 
            bg="#444444" 
            bghover="#353535" 
            border="#444444" 
            borderHover="#353535"
            onClick={() => {
              store.dispatch(signInAPI({ email: inputs.email, password: inputs.password }))
            }}
          >
            <FormattedMessage id="login.start-session" />
          </FormButton>
          <FormButton
            onClick={createAccount}
            border="#FFF" 
            borderHover="#FFF" 
            bghover="rgba(255,255,255,.04)"
          >
            <FormattedMessage id="login.create-account" />
          </FormButton>
        </ButtonsContainer>
      </Form>
    </Container>
    
  )
}
export default React.memo(FormLogin)