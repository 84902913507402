/* eslint-disable no-unused-vars */
import * as THREE from 'three';
import React from 'react';
import { Html } from "@react-three/drei";
import {  WalkSprite } from '../../assets';
import { HotspotProductIcon, HotspotLocationIcon } from '../icons';
import { propsAreEqual } from '../../utils';
import { useResize } from '../../hooks';

function HotspotComponent({ history, hotspot, setCurrentLayer }) {
    const [hovered, setHover] = React.useState(false);
    const scale = hovered ? hotspot.hoverSize : (hotspot.size ?? [-50, 50]);
    const position = hotspot.position ?? [0, 0, 0];
    const rotation = hotspot.rotation ?? [0, 0, 0];
    const onClick = () => hotspot.to && history && history.push(hotspot.to, history.location.pathname)
    const changeTexture = () => { hotspot.zone && setCurrentLayer(hotspot.zone) };
    const isMobile = useResize().isMobile;
    if (hotspot.type === "panorama-spot"){
        return (
            <mesh key={hotspot.name} onPointerOver={(e) => { setHover(true) }} onPointerOut={(e) => { setHover(false) }} onPointerDown={onClick} onClick={onClick} position={position} rotation={rotation} scale={scale}>
               <Html scaleFactor={isMobile ? (hotspot.scale || 1000) + 120 : (hotspot.scale || 1000)} zIndexRange={[3, 0]} center={true}>
                    <div className="hover-image" onClick={onClick} >
                        <HotspotLocationIcon />
                    </div>
                </Html>
            </mesh>);
    } else if (hotspot.type === "product-sprite"){
        return (
            <mesh key={hotspot.name} position={position} rotation={rotation} scale={scale} needsUpdate={false}>
                <Html scaleFactor={isMobile ? (hotspot.scale || 1000) + 120 : (hotspot.scale || 1000)} zIndexRange={[3, 0]} center={true}>
                    <div className="hover-image">
                        <HotspotProductIcon onClick={changeTexture} />
                    </div>
                </Html>
            </mesh>
        );
    }else if (hotspot.type === "walk-sprite"){
        return (
            <mesh key={hotspot.name} position={position} rotation={rotation} scale={scale} needsUpdate={false}>
                <Html scaleFactor={isMobile ? (hotspot.scale || 1000) + 120 : (hotspot.scale || 1000)} zIndexRange={[3, 0]} center={true}>
                    <img className="hover-image" onClick={onClick} alt="" src={WalkSprite} />
                </Html>
            </mesh>
        );
    }
}
export const Hotspot = React.memo(HotspotComponent, propsAreEqual)