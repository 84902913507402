import axios from 'axios'

export const APIAuth = axios.create({
  baseURL: 'https://auth.revigres.pt/v0',
  timeout: 5000,
})

export const API = axios.create({
  baseURL: 'https://revigres.pt/api',
  timeout: 5000,
})
