import React, { useState } from 'react';
import { useIntl } from 'react-intl'
import { FormattedMessage, FormLogin, FormRegister, FormForgotPassword } from '../../components';
import { Container, TitleContainer, Title, FormContainer } from './index.styled';

const LoginComponent = () => {

  const intl = useIntl()
  const [ currentForm, setCurrentForm ] = useState("login");

  const goTocreateAccount = () => {
    setCurrentForm("create-account");
  }

  const gotoLogIn = () => {
    setCurrentForm("login");
  }

  const gotoForgotPassword = () => {
    setCurrentForm("forgot-password");
  }

  const showForm = () => {
    switch(currentForm) {
      case "login":
        return <FormLogin createAccount={goTocreateAccount} forgotPassword={gotoForgotPassword}/>;
      case "create-account":
        return <FormRegister />
        case "forgot-password":
          return <FormForgotPassword />
      default:
        return <FormLogin createAccount={goTocreateAccount}/>;
    }
  }

  const showTitle = () => {
    switch(currentForm) {
      case "login":
        return <Title dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "login.primary-text" }) }}></Title>;
      case "create-account":
        return <Title><FormattedMessage id="login.already-have-an-account" /> <span onClick={gotoLogIn}><FormattedMessage id="login.start-session" /></span></Title>
        case "forgot-password":
          return <Title><FormattedMessage id="login.primary-text-forget-password" /></Title>;
      default:
        return <Title><FormattedMessage id="login.primary-text" /></Title>;
    }
  }

  return (
    <Container className={currentForm === "create-account" ? 'expand': ''}>
      <TitleContainer key={currentForm + "0"}>
        {showTitle()}
      </TitleContainer>
      <FormContainer key={currentForm}>{showForm()}</FormContainer>
    </Container>
  )
}
export default React.memo(LoginComponent)