import styled from 'styled-components'
import { fadeOut } from '../loading/index.style'

export const FullScreenColor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: ${p => p.vh * 100}px; 
  background-color: #777777; 
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1010;

  @media (min-height: 768px) {
    height: 100vh;
  }

  &.invisible{
    opacity:0; 
    pointer-events: none; 
    touch-action: none; 
    display: none !important;
    animation: ${fadeOut} 1s ease forwards;
  }


`
export const HeaderContainer = styled.div`
  width: 100vw;
`