import { lazy } from 'react'
import LoadingPage from './loading'
export { LoadingPage }
export const NewVRPage = lazy(() => import('./new-vr'))
export const SplashPage = lazy(() => import('./splash'))
export const CollectionPage = lazy(() => import('./collection'))
export const LoginPage = lazy(() => import('./login'))
export const MainMenu = lazy(() => import('./menu'))
export const StartTourPage = lazy(() => import('./start-tour'))
export const ExplorePage = lazy(() => import('./explore'))
export const HelpPage = lazy(() => import('./help'))
export const ReadyPage = lazy(() => import('./newsplash'))