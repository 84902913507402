import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  font-family:'Martel';
  font-size: 16px;

  @media (min-width: 768px) {
    height: 100%;
  }
`

export const TitleContainer = styled.div`
  width: 100%;
  color: #FFF;
  padding-top: 10px;

  @media (min-width: 768px) {
    padding-top: 30px;
  }
`

export const Title = styled.h1`
  color: #FFF;
  font-family:'Martel';
  font-size: 22px;
  letter-spacing: 1.4px;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: 54px;
  }
`

export const BottonsContainer = styled.ul`
  width: 60%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50%;
  list-style: none;
  padding: 0;
  margin: 0;
  @media (min-width: 768px) {
    width: 100%;
    padding-left: 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  li:not(:last-child){
    margin-bottom: 10px;
  }
`

export const Button = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 240px;
  }
`

export const Icon = styled.img`
  width: 60px;
  height: 60px;

  @media (min-width: 768px) {
    width: 180px;
    height: 180px;
  }
`

export const Description = styled.p`
  color: #FFF;
  font-size: 12px;
  letter-spacing: 1.2px;
  font-family: Overpass;
  font-weight: lighter;

  @media (min-width: 768px) {
    font-size: 18px;
    margin-top: -22px;
    letter-spacing: 1.4px;
    text-align: center;
  }
`