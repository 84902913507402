import React from 'react'
import { Tooltip } from 'antd'
import { useIntl } from 'react-intl'
import styled from 'styled-components';
import { useLocation } from 'react-router';

const SVG = styled.svg`
  margin-left: -2px;
  margin-bottom: 6px;
  // @media(min-width: 768px){
  //   margin-left: -61px;
  // }
`
const ActMap = ({ height, width, compass = true, history = {} }) => {
  const intl = useIntl()
  const location = useLocation()
  const renderHotspot = (
    <g id="Rectangle_703" onClick={() => history?.push('/vr/wc/1')} 
      data-name="Rectangle 703" transform="translate(340.785 176.421) rotate(45)" fill="rgba(145,112,82,0.13)" stroke="#917052" strokeWidth="1"
      className={location.pathname === '/vr/wc/1' ? 'hotspot-animation-active' : 'hotspot-animation'}
    >
      <Tooltip title={intl.formatMessage({ id: "wc" })}>
        <g>
          <rect width="15.922" height="15.922" stroke="none" />
          <rect x="0.5" y="0.5" width="14.922" height="14.922" fill="none" />
        </g>
      </Tooltip>
    </g>
  )

  const renderCompass = (
    <g id="compass" transform="translate(275 130) rotate(-60)">
      <g id="compass" width="78px" height="78px" ref={r => window.compass = r}>
        <path id="square" d="M25 54 L55 54 55 24 25 24 Z" fill="#917052" fillOpacity="1" stroke="none" />
        <path id="arrow" d="M64 22 L57 15 66 13 64 22 Z" fill="#91877e" fillOpacity="1" stroke="none" />
        <path id="back" d="M78 39 C78 17.461 60.539 0 39 0 17.461 0 0 17.461 0 39 0 60.539 17.461 78 39 78 60.539 78 78 60.539 78 39 Z" fill="#917052" fillOpacity="0" stroke="none" />
        <path id="back" d="M0 78 L78 78 78 0 0 0 Z" fill="#917052" fillOpacity="0" stroke="none" />
      </g>
    </g>
  )

  return (
    <SVG xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 312.908 327.314">
      <g id="WC-0" transform="translate(-143.5 14.098)">
        {compass ? renderCompass : renderHotspot}

        <g id="Group_507" data-name="Group 507" transform="translate(12669.1 -9582.001)">
          <path id="Path_617" data-name="Path 617" d="M-12242.686,9640.441v54.425h41.193v202.952h-99.585" transform="translate(-12.699 -4.101)" fill="none" stroke="#777675" strokeWidth="3" />
          <path id="Path_618" data-name="Path 618" d="M-12382.373,9897.888h-97.509V9809.45h-44.218V9694.936h76.531v-53.29" transform="translate(0 -4.171)" fill="none" stroke="#777675" strokeWidth="3" />
          <path id="Path_619" data-name="Path 619" d="M-12250.626,9633.441v-65l-192.022-.043v65.425" transform="translate(-4.637)" fill="none" stroke="#a9a9a7" strokeWidth="1" />
          <g id="Rectangle_668" data-name="Rectangle 668" transform="translate(-12412.725 9593.484)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="132.971" height="58.47" rx="29.235" stroke="none" />
            <rect x="0.5" y="0.5" width="131.971" height="57.47" rx="28.735" fill="none" />
          </g>
          <g id="Rectangle_669" data-name="Rectangle 669" transform="translate(-12354.256 9584.055)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="4.715" height="5.658" stroke="none" />
            <rect x="0.5" y="0.5" width="3.715" height="4.658" fill="none" />
          </g>
          <g id="Rectangle_671" data-name="Rectangle 671" transform="translate(-12345.769 9584.055)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="9.431" height="5.658" stroke="none" />
            <rect x="0.5" y="0.5" width="8.431" height="4.658" fill="none" />
          </g>
          <g id="Rectangle_670" data-name="Rectangle 670" transform="translate(-12349.539 9584.055)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="3.772" height="23.576" stroke="none" />
            <rect x="0.5" y="0.5" width="2.772" height="22.576" fill="none" />
          </g>
          <g id="Rectangle_673" data-name="Rectangle 673" transform="translate(-12217.04 9742.96) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="3.772" height="21.69" stroke="none" />
            <rect x="0.5" y="0.5" width="2.772" height="20.69" fill="none" />
          </g>
          <g id="Rectangle_674" data-name="Rectangle 674" transform="translate(-12217.04 9827.835) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="3.772" height="21.69" stroke="none" />
            <rect x="0.5" y="0.5" width="2.772" height="20.69" fill="none" />
          </g>
          <g id="Ellipse_79" data-name="Ellipse 79" transform="translate(-12387.262 9618.947)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <circle cx="3.772" cy="3.772" r="3.772" stroke="none" />
            <circle cx="3.772" cy="3.772" r="3.272" fill="none" />
          </g>
          <g id="Rectangle_672" data-name="Rectangle 672" transform="translate(-12251.462 9726.457)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="28.292" height="36.779" rx="5" stroke="none" />
            <rect x="0.5" y="0.5" width="27.292" height="35.779" rx="4.5" fill="none" />
          </g>
          <g id="Rectangle_675" data-name="Rectangle 675" transform="translate(-12251.462 9811.332)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="28.292" height="36.779" rx="5" stroke="none" />
            <rect x="0.5" y="0.5" width="27.292" height="35.779" rx="4.5" fill="none" />
          </g>
          <path id="Path_620" data-name="Path 620" d="M-12204.409,9722.694v62.128" transform="translate(-12.546 -8.785)" fill="none" stroke="#a9a9a7" strokeWidth="1" />
          <path id="Path_621" data-name="Path 621" d="M-12204.409,9722.694v62.128" transform="translate(-12.546 76.09)" fill="none" stroke="#a9a9a7" strokeWidth="1" />
          <g id="Rectangle_676" data-name="Rectangle 676" transform="translate(-12479.682 9831.136)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <path d="M0,0H32.535A13.674,13.674,0,0,1,46.21,13.674v0A13.674,13.674,0,0,1,32.535,27.349H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" stroke="none" />
            <path d="M.838.5h31.7A13.174,13.174,0,0,1,45.71,13.674v0A13.174,13.174,0,0,1,32.535,26.849H.838A.338.338,0,0,1,.5,26.511V.838A.338.338,0,0,1,.838.5Z" fill="none" />
          </g>
          <g id="Rectangle_677" data-name="Rectangle 677" transform="translate(-12463.649 9833.966)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <path d="M3.615,0H16.5A10.845,10.845,0,0,1,27.349,10.845v0A10.845,10.845,0,0,1,16.5,21.69H3.615A3.615,3.615,0,0,1,0,18.075V3.615A3.615,3.615,0,0,1,3.615,0Z" stroke="none" />
            <path d="M3.711.5H16.5A10.345,10.345,0,0,1,26.849,10.845v0A10.345,10.345,0,0,1,16.5,21.19H3.711A3.211,3.211,0,0,1,.5,17.98V3.711A3.211,3.211,0,0,1,3.711.5Z" fill="none" />
          </g>
          <g id="Rectangle_678" data-name="Rectangle 678" transform="translate(-12457.992 9841.51)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <path d="M1.1,0H6.13a3.3,3.3,0,0,1,3.3,3.3v0a3.3,3.3,0,0,1-3.3,3.3H1.1A1.1,1.1,0,0,1,0,5.5V1.1A1.1,1.1,0,0,1,1.1,0Z" stroke="none" />
            <path d="M1.369.5H6.13a2.8,2.8,0,0,1,2.8,2.8v0a2.8,2.8,0,0,1-2.8,2.8H1.369A.869.869,0,0,1,.5,5.232V1.369A.869.869,0,0,1,1.369.5Z" fill="none" />
          </g>
          <g id="Rectangle_679" data-name="Rectangle 679" transform="translate(-12521.177 9693.449)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="74.502" height="109.395" stroke="none" />
            <rect x="0.5" y="0.5" width="73.502" height="108.395" fill="none" />
          </g>
          <path id="Path_622" data-name="Path 622" d="M-12241.5,9649.54h-5.037v43.273h5.037" transform="translate(-15.804 -4.619)" fill="none" stroke="#a9a9a7" strokeWidth="1" />
          <path id="Path_623" data-name="Path 623" d="M5,0H0V37.187H5" transform="translate(-12268.715 9886.523) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1" />
          <path id="Path_624" data-name="Path 624" d="M-12239.031,9698v197.247" transform="translate(-16.232 -3.255)" fill="none" stroke="#a9a9a7" strokeWidth="1" />
          <path id="Path_625" data-name="Path 625" d="M-12511.817,9698v107.923" transform="translate(-0.699 -3.86)" fill="none" stroke="#a9a9a7" strokeWidth="1" />
          <g id="Rectangle_680" data-name="Rectangle 680" transform="translate(-12490.056 9700.052)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="20.747" height="3.772" stroke="none" />
            <rect x="0.5" y="0.5" width="19.747" height="2.772" fill="none" />
          </g>
          <g id="Rectangle_682" data-name="Rectangle 682" transform="translate(-12482.512 9719.855)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="5.658" height="6.601" stroke="none" />
            <rect x="0.5" y="0.5" width="4.658" height="5.601" fill="none" />
          </g>
          <g id="Rectangle_683" data-name="Rectangle 683" transform="translate(-12507.031 9702.881)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="54.697" height="77.331" stroke="none" />
            <rect x="0.5" y="0.5" width="53.697" height="76.331" fill="none" />
          </g>
          <path id="Path_626" data-name="Path 626" d="M-12455.282,9729h-46.083" transform="translate(-1.294 -9.145)" fill="none" stroke="#a9a9a7" strokeWidth="1" />
          <path id="Path_627" data-name="Path 627" d="M-12477,9711.4v-3.343" transform="translate(-2.682 -7.952)" fill="none" stroke="#a9a9a7" strokeWidth="1" />
        </g>
      </g>
    </SVG>
  )
}
//bathroom
export const ActMap3 = React.memo(ActMap)
