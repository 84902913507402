/* eslint-disable react-hooks/exhaustive-deps */
import * as THREE from 'three'
import React from 'react'
import { Canvas, extend } from 'react-three-fiber'
import { VRCanvas, DefaultXRControllers, Hover } from '@react-three/xr'
import { Zoombar } from '../../components'
import { VRButton } from './VRButton'
import { useHistory } from 'react-router'
import { OrbitControls } from '../../utils/OrbitControls'
import { Map } from './map'
import { Hotspot } from './hotspot'
import { Panorama } from './panorama'
import { Controls } from './controls'
import { Texture } from './texture'
import { Loading } from '..';
import { propsAreEqual } from '../../utils'
import MuteControl from './mute';

export const FOV = window.innerWidth < 600 ? 90 : 60
const camera = new THREE.PerspectiveCamera(FOV, window.innerWidth / window.innerHeight, 0.1, 1500);
const createVRCanvas = ({ gl }) => (VRButton.createXR(gl))
window.camera = camera;

extend({ OrbitControls })
const VRComponent = ({ vr = false, panorama, layers = [], initialPosition = [0, 0, 0], hotspots = [], initialCompass = 0, rotate, currentAct, previewPanorama, setCurrentLayer }) => {
    const vrRef = React.useRef()
    const history = useHistory()
    const [loaded, setLoaded] = React.useState(false)
    const [loadedTexture, setLoadedTexture] = React.useState(false)
    const [opacityStyle, setOpacityStyle] = React.useState("preview-invisible")
    const [progress, setProgress] = React.useState(0)
    if (window.controls) { if (window.controls.autoRotate !== rotate) { window.controls.autoRotate = rotate; camera.updateProjectionMatrix() } }
    const onClick = (hotspot, history) => hotspot.to && history && history.push(hotspot.to, history.location.pathname)

    React.useEffect(() => { camera.position.set(...initialPosition) }, [camera])
    React.useEffect(() => { camera.position.set(...initialPosition) }, [initialPosition])

    React.useEffect(() => {
        if(loaded === false && opacityStyle === "preview-invisible"){
            setOpacityStyle("opacity-05")
        }else if(loaded === true && opacityStyle === "opacity-05"){
            setTimeout(() => {
                setOpacityStyle("preview-invisible")
            }, 1000);
        }
    }, [loaded])

    React.useEffect(() => {
        if(loadedTexture === false && opacityStyle === "preview-invisible"){
            setOpacityStyle("opacity-05")
        }else if(loadedTexture === true && opacityStyle === "opacity-05"){
            setTimeout(() => {
                setOpacityStyle("preview-invisible")
            }, 1000);
        }
    }, [loadedTexture])

    React.useEffect(() => {
        setLoadedTexture(false)
        setProgress(0)
    }, [layers])

    return (
        <div className="fullscreen">
            <button ref={vrRef} id="VRButton" className="d-none" />
            <Loading previewPanorama={previewPanorama} className={opacityStyle} forceLogoOnly redirect={false} dark={true} progress={progress} isImage={true} />
            <div className="gradient-fullscreen" />
            <Map currentAct={currentAct} />
            {!vr && <Zoombar />}
            {!vr && <MuteControl />}
            {vr && (
                <VRCanvas onCreated={createVRCanvas} camera={camera} pixelRatio={window.devicePixelRatio} webgl1={true} colorManagement={false}>
                    <Controls initialPosition={initialPosition} initialCompass={initialCompass} autoRotate={rotate} />
                    <DefaultXRControllers />
                    {hotspots.map(x => ({ ...x, size: [120, 120] })).map((hotspot, i) => (
                        <Hover key={i} onChange={(e) => { if (e.controller.hoverRayLength > 40 && e.controller.hovering) onClick(hotspot, history) }}>
                            <Hotspot setCurrentLayer={setCurrentLayer} history={history} hotspot={hotspot} />
                        </Hover>
                    ))}
                    {layers?.map((layer, i) => (<Texture key={i} transparent={true} map={layer} loaded={true} />))}
                    <Panorama map={panorama} loaded={true} setLoaded={() => { }} progress={progress} />
                </VRCanvas>
            )}

            {!vr && (
                <Canvas shadowMap camera={camera} pixelRatio={window.devicePixelRatio} colorManagement={false} gl={{ antialias: true, alpha: true }}>
                    <Controls initialPosition={initialPosition} initialCompass={initialCompass} autoRotate={rotate} />
                    {hotspots.map((hotspot, i) => (<Hotspot setCurrentLayer={setCurrentLayer} history={history} hotspot={hotspot} key={i} />))}
                    {layers?.map((layer, i) => <Texture key={i} transparent={true} setLoaded={setLoadedTexture} map={layer} loaded={loadedTexture} setProgress={(progress) => setProgress(progress)} progress={progress} />)}
                    <Panorama map={panorama} loaded={loaded} setLoaded={setLoaded} progress={progress} setProgress={(progress) => setProgress(progress)} />
                </Canvas>
            )}

        </div>
    )
}
export const VR = React.memo(VRComponent, propsAreEqual)