import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router';
import { PoweredBy360 } from '../../components/icons'
import { HelpIcon, FooterStyled, FooterStyledTextContainer, FormattedMessage } from '../../components';
import { ListItemIconsStyled } from './index.styled';



const Footer = ({showHelp = true, className = "", locale}) =>  {
  const history = useHistory();

  const gotoHelp = () => {
    history.push('/help', {})
  }

  const getPrivacyURL = () => {
    if(locale === "pt"){
      return "https://revigres.pt/legal/politica-de-privacidade"
    }

    return "https://revigres.pt/en/legal/privacy-policy"
  }
  return (
    <FooterStyled className={`footer-mb ${className}`}>
      <FooterStyledTextContainer>
        <p>© 2021 Revigrés</p>
        {/* <p className="powered" onClick={() => window.open('https://vr360.pt/', '_target')}>Powered by: <PoweredBy360 /></p> */}
        <p className="powered no-cursor">Powered by: <PoweredBy360 /></p>
      </FooterStyledTextContainer>
      {showHelp && <ListItemIconsStyled className="info" onClick={gotoHelp}><HelpIcon fill="#EEEBE9" /></ListItemIconsStyled>}
    </FooterStyled>
  )
}

const mapStateToProps = ({ app }) => {
  const { locale } = app
  return { locale }
}

export default connect(mapStateToProps, null)(React.memo(Footer))