export const AppActionType = {
    UPDATE_CURRENT_ACT: 'APP.UPDATE_CURRENT_ACT',
    UPDATE_CURRENT_ACT_SONG: 'APP.UPDATE_CURRENT_ACT_SONG',
    UPDATE_MUTED: 'APP.UPDATE_MUTED',
    UPDATE_NETWORK_SPEED: 'APP.UPDATE_NETWORK_SPEED',
    UPDATE_PROGRESS: 'VISITOR.UPDATE_PROGRESS',
    UPDATE_SELECT_PRODUCT_MENU: 'APP.UPDATE_SELECT_PRODUCT_MENU',
    UPDATE_LANGUAGE: 'APP.UPDATE_LANGUAGE',

    SIGN_IN_API: 'APP.SIGN_IN_API',
    SIGN_IN_API_SUCCESS: 'APP.SIGN_IN_API_SUCCESS',
    SIGN_IN_API_FAILURE: 'APP.SIGN_IN_API_FAILURE',

    RECOVERY_PASSWORD_API: 'APP.RECOVERY_PASSWORD_API',
    RECOVERY_PASSWORD_API_SUCCESS: 'APP.RECOVERY_PASSWORD_API_SUCCESS',
    RECOVERY_PASSWORD_API_FAILURE: 'APP.RECOVERY_PASSWORD_API_FAILURE',
    
    SIGN_UP_API: 'APP.SIGN_UP_API',
    SIGN_UP_API_SUCCESS: 'APP.SIGN_UP_API_SUCCESS',
    SIGN_UP_API_FAILURE: 'APP.SIGN_UP_API_FAILURE',

    UPDATE_CURRENT_LAYER: 'APP.UPDATE_CURRENT_LAYER',
    UPDATE_ACTIVE_LAYERS: 'APP.UPDATE_ACTIVE_LAYERS',

    SAVE_ON_MY_REVIGRES_API: 'APP.SAVE_ON_MY_REVIGRES_API',
    SAVE_ON_MY_REVIGRES_API_SUCCESS: 'APP.SAVE_ON_MY_REVIGRES_API_SUCCESS',
    SAVE_ON_MY_REVIGRES_API_FAILURE: 'APP.SAVE_ON_MY_REVIGRES_API_FAILURE',

    UPDATE_CURRENT_PANO: 'APP.UPDATE_CURRENT_PANO',

    CREATE_USER_ON_MY_REVIGRES_API: 'APP.CREATE_USER_ON_MY_REVIGRES_API',
    CREATE_USER_ON_MY_REVIGRES_API_SUCCESS: 'APP.CREATE_USER_ON_MY_REVIGRES_API_SUCCESS',
    CREATE_USER_ON_MY_REVIGRES_API_FAILURE: 'APP.CREATE_USER_ON_MY_REVIGRES_API_FAILURE',

    GET_COUNTRIES_API: 'APP.GET_COUNTRIES_API',
    GET_COUNTRIES_API_SUCCESS: 'APP.GET_COUNTRIES_API_SUCCESS',
    GET_COUNTRIES_API_FAILURE: 'APP.GET_COUNTRIES_API_FAILURE',
    
    GET_DISTRICTS_API: 'APP.GET_DISTRICTS_API',
    GET_DISTRICTS_API_SUCCESS: 'APP.GET_DISTRICTS_API_SUCCESS',
    GET_DISTRICTS_API_FAILURE: 'APP.GET_DISTRICTS_API_FAILURE',

    UPDATE_FROM_START: 'APP.UPDATE_FROM_START'
    
}