import React, { useState } from 'react';
import { MdErrorOutline, MdCheckCircle } from 'react-icons/md'
import { Container, 
        InputText, 
        Form, 
        InputTextContainer, 
        ValidationSuccess, 
        InputTitle, 
        ButtonsContainer, 
        ValidationError, 
        FormButton
      } from './index.styled';
import { useIntl } from 'react-intl';
import { FormattedMessage } from '..';
import { recoveryPasswordAPI } from './../../effects/actions'
import { store } from './../../effects/store'

const FormForgotPassword = () => {

  const initialInputs = { email: '' }
  const [ inputs, setAllInputs ] = useState(initialInputs);
  const intl = useIntl();

  const [ emailError, setEmailError ] = useState(false);
  const [ sent, setSent ] = useState(false);

  const handleChange = (e) => setAllInputs({...inputs, [e.target.name]: e.target.value})

  const CheckForm = () => {
    let allValid = true;
    setEmailError(!validateEmail(inputs.email))

    if(validateEmail(inputs.email) !== true) allValid = false;

    return allValid;
  }

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (CheckForm()) {
      setAllInputs({ email: "" });
      setSent(true);
      // redirect
    } else {
      console.log("missing values")
      setSent(false);
    }
  }

  return (
    <Container className="teste">
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <InputTextContainer>
            <InputTitle><FormattedMessage id="login.email" /></InputTitle>
            <InputText
              className={inputs.email !== '' ? 'b-bottom filled': 'b-bottom empty'}
              placeholder={intl.formatMessage({ id: "login.enter-your-email" })}
              name="email"
              value={inputs.name}
              onChange={handleChange}
            />
            {emailError && <ValidationError><MdErrorOutline /><FormattedMessage id="login.invalid-email" /></ValidationError>}
            {sent && <ValidationSuccess><MdCheckCircle /><FormattedMessage id="login.email-sent" /></ValidationSuccess>}
        </InputTextContainer>
        <ButtonsContainer>
          <FormButton 
            type="submit" 
            disabled={false} 
            bg="#444444" 
            bghover="#353535" 
            border="#444444" 
            borderHover="#353535"
            onClick={() => {
              store.dispatch(recoveryPasswordAPI({ email: inputs.email }))
            }}
          >
            <FormattedMessage id="login.recovery-password" />
          </FormButton>
        </ButtonsContainer>
      </Form>
    </Container>
    
  )
}
export default React.memo(FormForgotPassword)