import * as THREE from 'three';
import React from 'react';
import { propsAreEqual } from '../../utils'

export function PanoramaComponent({ 
    loaded, map, setLoaded = (() => { }), setProgress = (() => { }), transparent = false, progress
}) {
    const geometry = new THREE.SphereBufferGeometry(500, 60, 40);
   
    if (!map) return <mesh />;
 
    let manager = new THREE.LoadingManager();

    manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
        console.log("url", url, "items", itemsLoaded, "itemtotal", itemsTotal)
        if(progress < 10) setProgress(10)
        
    };
    
    manager.onLoad = function ( ) {
        setProgress(100)
        setTimeout(() => {
            setLoaded(true)
        }, 2000);
    };
    
    
    manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
        console.log("url", url, "items", itemsLoaded, "itemtotal", itemsTotal)
        if(progress < 70) setProgress(70)    
    };
    manager.onError = function(map) {
        console.log('Error loading texture: ' + map);
    };


    const textureLoader = new THREE.TextureLoader(manager);
    textureLoader.crossOrigin = 'anonymous';
    const texture = textureLoader.load(
        map
      );

    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    // texture.wrapT = THREE.ClampToEdgeWrapping;
    texture.anisotropy = 16;
    const debugXYZ = false;
    return (
        <mesh geometry={geometry} position={[0, 0, 0]} scale={[1, 1, -1]} onClick={(e) => debugXYZ && console.log('canvas', [e.point.x.toFixed(0), e.point.y.toFixed(0), e.point.z.toFixed(0)])}>
            <meshBasicMaterial transparent={true} visible={loaded} map={texture} side={THREE.BackSide} opacity={1} transparent={transparent} needsUpdate={false} />
        </mesh>);
}
export const Panorama = React.memo(PanoramaComponent, propsAreEqual)