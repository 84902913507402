import React, { useRef } from 'react'
import { useHistory } from 'react-router';
import { useResize } from '../../hooks'
import { FooterStyled, ForwardIcon, FormattedMessage } from '..'
import { MainMenu } from '../../pages';
import { MenuHeader } from '../../pages/menu';
import {  
  ContentImage,
  ContentImageDiv,
  ContentLabel,
  ContentLabelBox,
  ContentLabelFixed,
  ContentRow,
  ContentRows,
  ContentScrollable,
  ContentShadow,
  ContentSpace,
  ContentWrap,
  FullScreenColor 
} from './index.styled';

const CollectionComponent = ({ headerText, onClose, items, translate = true }) => {
  const history = useHistory()
  const isMobile = useResize().isMobile;
  const onCloseAction = onClose ?? (() => history.goBack())
  const [currentSelected, setSelectedUrl] = React.useState('')
  const [visibleMenu, setVisibleMenu] = React.useState('')
  const goToVR = (url) => {
    if (currentSelected !== '') return;
    setSelectedUrl(url);
    setTimeout(() => {
      history.push(url, {})
    }, 450);
  }
  const onClickItem = (url) => { if (currentSelected === url) history.push(url, {}); else goToVR(url) }

  const allItems = items.concat.apply([], items);

  return (
    <FullScreenColor className="no-overflow space-between">
      {visibleMenu && <MainMenu menu={visibleMenu} closeMenu={() => setVisibleMenu(false)} btnOpen={false}/>}
      <MenuHeader 
        headerText={headerText}
        close={false}
        btnOpen={true}
        onClickLogo={() => history.push("/")} 
        onClickAction={() => setVisibleMenu(true)}
        onClickClose={onCloseAction} 
      />
      <ContentScrollable>
        <ContentRows>
          {!isMobile && items.map((row, i) => (
            <React.Fragment key={i}>
              <ContentRow>
                {row.map((col, j) => {
                  return(
                    <GalleryItem 
                      translate={translate}
                      key={j} 
                      currentSelected={currentSelected} 
                      onClickItem={onClickItem} 
                      url={col.url} 
                      label={col.label} 
                      thumbnail={col.thumbnail} 
                      mobile={isMobile ? true: false}
                    />
                  )}
              )}
              </ContentRow>
              <ContentSpace />
            </React.Fragment>
          ))}
          {isMobile && allItems.map((row, i) => {
            
            return (
              <ContentRow key={i}>
                <GalleryItem 
                  translate={translate}
                  currentSelected={currentSelected} 
                  onClickItem={onClickItem} 
                  url={row.url} 
                  label={row.label} 
                  thumbnail={row.thumbnail} 
                  mobile={true}
                />
              </ContentRow>
            )
            })}
        </ContentRows>
      </ContentScrollable>
      <FooterStyled height="0" padding="0" />
    </FullScreenColor>
  )
}

export const GalleryItem = React.memo(({ url, label, thumbnail, currentSelected, onClickItem, mobile = 'false', translate = true }) => {
  const active = currentSelected === url
  const className = `label-box ${active ? "active" : ""}`
  const classMobile = mobile === true ? "mobile": "";

  const refText = useRef(null);
  const refTextHover = useRef(null);

  return (
    <ContentWrap md={8} className={classMobile}>
      <ContentImageDiv onClick={() => onClickItem(url)}>
        <ContentShadow className="shadow" />
        <ContentImage src={thumbnail} />
        <ContentLabelFixed className="still" ref={refText}>{translate ? <FormattedMessage id={label}  /> : label}</ContentLabelFixed>
        <ContentLabelBox onClick={() => onClickItem(url)} className={className}>
          <ContentLabel>
            <span ref={refTextHover}>{translate ? <FormattedMessage id={label}  /> : label}</span>
            <span className="pr-3"><ForwardIcon height={mobile === true ? 14: 24}/></span>
          </ContentLabel>
        </ContentLabelBox>
      </ContentImageDiv>
    </ContentWrap>
  )
})

export default React.memo(CollectionComponent)




