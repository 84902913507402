import SpeedTester from 'browser-speed-test';
import { ASSETS_FOLDER } from '../constants/consts';
import { updateNetworkSpeed } from './../effects/actions';
import { store } from './../effects/store';
const enviroments = require('./../assets/mock-data/enviroment.json')
export const isMobile = () => (window.innerWidth < 811)

export const mobileClass = (string = "") => {
  return `${string}${isMobile() ? " mobile" : ""}`.trim()
}

export function eventFire(el, etype) {
  if (el.fireEvent) {
    el.fireEvent('on' + etype);
  } else {
    var evObj = document.createEvent('Events');
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}

export function chunk(list, chunkSize) {
  const newList = [...list];
  return [...Array(Math.ceil(newList.length / chunkSize))].map(_ => newList.splice(0, chunkSize));
}

export const getNetworkDownloadSpeed = async () => {
  const tester = new SpeedTester({ url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/502px-Google_2015_logo.svg.png' });
  const speed = await tester.start();
  store.dispatch(updateNetworkSpeed(speed))
}

export const Util = {
  renderMessage: (message) => {
    switch (message) {
      case 'internal_error':
        return 'Internal error, try again.'

      case 'email_or_password_invalid':
        return 'Invalid email or password.'

      case 'email_invalid':
        return 'Invalid email.'

      case 'password_invalid':
        return 'Invalid password.'

      case 'error.user_exists':
        return 'Email already exists.'

      default:
        return 'Internal error, try again.'
    }
  },
  getErrorLogin(error) {
    const errorResponse = error
    errorResponse.data = 'internal_error'
    if (error.data !== undefined) {
      if (error.data.uidField === undefined) {
        errorResponse.data = 'email_invalid'
      } else {
        errorResponse.data = 'password_invalid'
      }
    }
    return errorResponse
  },
  getError(error) {
    const errorResponse = error
    errorResponse.response.data = 'internal_error'
    return errorResponse
  },
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  },
  setTitle(locale) {
    return (locale === "en" && "Revigres - 3D Simulator") || (locale === "pt" && "Revigres - Simulador 3D")
  }
}

// Comparison function to JSON.stringify that can handle circular references and ignores internal React properties.
export const circular = (ignoreHistory = true) => {
  const obj = new WeakSet()
  return (key, value) => {
    if (key.startsWith('_')) return;
    if (ignoreHistory === true && key === 'location') return;
    if (ignoreHistory === true && key === 'history') return;
    if (typeof value === 'function' && value) { obj.add(value) }
    if (typeof value === 'object' && value) { if (!obj.has(value)) obj.add(value) }
    return value
  }
}
export const propsAreEqual = (i, j) => {
  const is = (x, y) => ((x === y) ? (x !== 0 || y !== 0 || 1 / x === 1 / y) : (x !== x && y !== y))/*eslint no-self-compare: "off"*/ // The polyfill of Object.is()
  if (is(i, j)) return true;
  const a = JSON.stringify(i, circular())
  const b = JSON.stringify(j, circular())
  //debug individual values//  if (a!==b) { const keys = Object.keys(i).filter(k=> !k.startsWith('_')); for (let n = 0; n < keys.length; n++) { console.log("key: ", keys[n], "valueA:", JSON.stringify(i[keys[n]], circular()), "valueB:",JSON.stringify(j[keys[n]], circular()), JSON.stringify(i[keys[n]], circular()) === JSON.stringify(j[keys[n]], circular()) ? "equal" : "different" ) } }
  return a === b
}


export const getDefaultLayersByEnv = (env) => {
  let path = `${ASSETS_FOLDER}/${enviroments[0].folder}/${env}/layers/`
  switch (env) {
    case "wc":
      return {
        "WALL-01": {
          path: `${path}WALL-01/3121D3U691.png`,
          code: "3121D3U691"
        },
        "WALL-02": {
          path: `${path}WALL-02/311B139U01.png`,
          code: "311B139U01"
        },
        "FLOOR": {
          path: `${path}FLOOR/3711135071.png`,
          code: "3711135071"
        }
      }

    case "kitchen":
      return {
        "WALL-01": {
          path: `${path}WALL-01/312BD318U1.png`,
          code: "312BD318U1"
        },
        "WALL-02": {
          path: `${path}WALL-02/312BD315U1.png`,
          code: "312BD315U1"
        },
        "FLOOR": {
          path: `${path}FLOOR/311B139U01.png`,
          code: "311B139U01"
        }
      }

    case "living-room":
      return {
        "WALL-01": {
          path: `${path}WALL-01/3101G8G861.png`,
          code: "3101G8G861"
        },
        "WALL-02": {
          path: `${path}WALL-02/3126D31U21.png`,
          code: "3126D31U21"
        },
        "FLOOR": {
          path: `${path}FLOOR/312113S301.png`,
          code: "312113S301"
        }
      }

    case "dining-room":
      return {
        "WALL-01": {
          path: `${path}WALL-01/3101G8G861.png`,
          code: "3101G8G861"
        },
        "WALL-02": {
          path: `${path}WALL-02/3126D31U21.png`,
          code: "3126D31U21"
        },
        "FLOOR": {
          path: `${path}FLOOR/312113S301.png`,
          code: "312113S301"
        }
      }

    case "outside":
      return {
        "WALL-01": {
          path: `${path}WALL-01/311BD3U891.png`,
          code: "311BD3U891"
        },
        "POOL": {
          path: `${path}POOL/3121D636T1.png`,
          code: "3121D636T1"
        },
        "FLOOR": {
          path: `${path}FLOOR/3125K726T1.png`,
          code: "3125K726T1"
        }
      }
    case "suite":
      return {
        "WALL-01": {
          path: `${path}WALL-01/3121D3U711.png`,
          code: "3121D3U711"
        },
        "WALL-02": {
          path: `${path}WALL-02/3126K71U41.png`,
          code: "3126K71U41"
        },
        "FLOOR": {
          path: `${path}FLOOR/312113U681.png`,
          code: "312113U681"
        }
      }

    default:
      return {}
  }
}