import * as THREE from 'three';
import React from 'react';
import { useFrame, useThree } from 'react-three-fiber';
import { propsAreEqual } from '../../utils';
import { FOV } from '.';

const SPEED = -0.3
const AUTO_ROTATE_SPEED = 0.3

function ControlsComponent({ initialPosition, initialCompass = 0, autoRotate }) {
    const mounted = React.useRef();
    const orbitRef = React.useRef();
    const { gl, camera } = useThree();
    useFrame(() => updateCamera());

    if (orbitRef.current && mounted.current !== true) {
        camera.fov = FOV;
        gl.setSize(window.innerWidth, window.innerHeight, false);
        mounted.current = true;
        camera.updateProjectionMatrix();
    }

    const updateCamera = () => {
        if (!window.controls) window.controls = orbitRef.current;
        if (!window.gl) window.gl = gl;
        orbitRef.current && orbitRef.current.update();
        camera.updateProjectionMatrix();

        if (!window.compass) return;
        // if (window.compass) return; //not using below
        const dir = camera.getWorldDirection(new THREE.Vector3());
        const sph = new THREE.Spherical().setFromVector3(dir);

        const currentAngle = THREE.Math.radToDeg(sph.theta);
        const positionAngle = THREE.Math.radToDeg(initialPosition[0] / (100 * Math.PI));
        const rotateZ = initialCompass[2] ?? 0;
        const angle = currentAngle + positionAngle + rotateZ - 40;
        const newTransform = `rotate(${-angle.toFixed(0)}deg)`;
        if (window.compass.style.transform !== newTransform)
            window.compass.style.transform = newTransform;
    };

    //140 eh o spherical.radius default - 
    const defaultSphericalRadius = 40
    return (<orbitControls
        ref={orbitRef}
        args={[camera, gl.domElement]}
        maxPolarAngle={5}
        minPolarAngle={0}
        minDistance={defaultSphericalRadius * 1}
        maxDistance={defaultSphericalRadius * 2.5}
        enableKeys={true}
        enableZoom={false}
        enablePan={false}
        enableDamping={true}
        dampingFactor={0.09}
        autoRotate={autoRotate}
        rotateSpeed={SPEED}
        autoRotateSpeed={AUTO_ROTATE_SPEED} />);
}
export const Controls = React.memo(ControlsComponent, propsAreEqual)