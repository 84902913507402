import React from "react";
import styled from 'styled-components'
import { Row, Col } from 'antd';
import { Link } from "react-router-dom";
import { Header} from '../../components'

const FloatMenuStyled = styled.div`

  width: 100vw;
  height: 100%;
  background-color: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.5s cubic-bezier(0, .52, 0, 1);
  overflow: scroll;
  z-index: 1000;
  &.hide {
    transform: translate3d(100vw, 0, 0);
  }
   
  &.show {
    transform: translate3d(0vw, 0, 0);
    overflow: hidden;
  }

  > m,ul{
    margin-left : 120px;
    margin-top :  240px;
  }
  li{
    list-style: none;
  }
  a {
    margin-left: 15px;
    text-decoration: none;
    font: normal normal 600 56px/94px 'Martel';
    letter-spacing: 1.9px;
    color: #EEEBE9;
    opacity: 1;
  }&hover{
    text-decoration: underline;
  }
`

const MenuComponent = ({ menuVisibility, handleMouseDown }) => {

  return(
    <FloatMenuStyled  className={menuVisibility ? "hide" : "show"}>
      <Header handleMouseDown={handleMouseDown} menuVisibility={menuVisibility}/>
      <Row>
        <Col span={23}>
          <ul>
            <li><Link>Start Tour</Link></li>
            <li><Link>Our Product</Link></li>
            <li><Link>Revigrés</Link></li>
            <li><Link>Download Simulation</Link></li>
            <li><Link>Contacts</Link></li>
          </ul>
        </Col>
        <Col span={1}></Col>
      </Row>
    </FloatMenuStyled>
  )
}  
export default MenuComponent;