import React from 'react'
import PropTypes from 'prop-types'

const InstagramIcon = ({ fill }) => {
  return (
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
            <defs>
              <clipPath id="clipPath">
                <rect id="Retângulo_479" data-name="Retângulo 479" width="25" height="25" fill={fill}/>
              </clipPath>
            </defs>
            <g id="Grupo_340" data-name="Grupo 340" transform="translate(0 0.244)">
              <g id="Grupo_339" data-name="Grupo 339" transform="translate(0 -0.244)" clipPath="url(#clipPath)">
                <path id="Caminho_470" data-name="Caminho 470" d="M8.461.023,9.945,0h4.848l1.175.014,1.5.055a9.106,9.106,0,0,1,3.005.576,6.315,6.315,0,0,1,3.617,3.617,9.145,9.145,0,0,1,.574,3l.053,1.463.019,1.776,0,4.442L24.7,16.624l-.034.843a9.145,9.145,0,0,1-.574,3,6.335,6.335,0,0,1-3.618,3.618,9.091,9.091,0,0,1-3.005.574l-1.376.053-1.954.019-4.336,0-1.383-.023-1.148-.046a9.1,9.1,0,0,1-3-.574,6.113,6.113,0,0,1-2.191-1.428A6.085,6.085,0,0,1,.648,20.47a9.094,9.094,0,0,1-.576-3L.027,16.274,0,14.79l0-1.035L0,9.944.019,8.767l.053-1.5a9.094,9.094,0,0,1,.576-3A6.085,6.085,0,0,1,2.074,2.072,6.085,6.085,0,0,1,4.265.647a9.092,9.092,0,0,1,3-.576L8.461.025ZM16.8,2.27l-1.383-.035-5.876,0L8.2,2.262,7.369,2.3a7.97,7.97,0,0,0-1.776.251l-.367.117-.154.058a3.841,3.841,0,0,0-1.422.924,3.841,3.841,0,0,0-.924,1.422l-.06.155-.115.367a7.584,7.584,0,0,0-.23,1.392L2.251,8.592,2.23,10.537l.009,4.88.018.866L2.3,17.366a7.95,7.95,0,0,0,.253,1.776l.115.367.06.154a3.812,3.812,0,0,0,.924,1.422,3.845,3.845,0,0,0,1.422.926,6.366,6.366,0,0,0,1.915.4l1.292.062.949.025,1.406.007L15.12,22.5l1.164-.021,1.081-.042a6.832,6.832,0,0,0,2.3-.426,4.115,4.115,0,0,0,2.348-2.348,6.423,6.423,0,0,0,.4-1.915l.049-.947.034-1.159.012-1.541,0-4.256-.03-1.567-.037-.91a6.926,6.926,0,0,0-.424-2.3,3.882,3.882,0,0,0-.929-1.424,3.806,3.806,0,0,0-1.421-.924l-.154-.058-.367-.117a7.639,7.639,0,0,0-1.392-.23ZM12.368,6.012a6.353,6.353,0,1,1-6.352,6.353,6.352,6.352,0,0,1,6.352-6.353m0,2.23a4.124,4.124,0,1,0,4.124,4.124,4.123,4.123,0,0,0-4.124-4.124m6.6-3.965a1.484,1.484,0,1,1-1.484,1.484,1.484,1.484,0,0,1,1.484-1.484" transform="translate(0 0.265)" fill={fill} fillRule="evenodd"/>
              </g>
            </g>
          </svg>
  )
}

InstagramIcon.propTypes = {
  fill: PropTypes.string
}

InstagramIcon.defaultProps = {
  fill: "#FFF"
}

export {InstagramIcon}