import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% { opacity: 0}
  100% { opacity: 1}
`
export const fadeOut = keyframes`
  0% { opacity: 1}
  100% { opacity: 0}
`


const showBottomRight = keyframes`
  0% { 
    opacity: .3;
    width: 0;
    background: transparent;
  }
  60% { 
    opacity: .3;
    width: 100%;
    background: #FFF;
  }
  100% { 
    opacity: 1;
    width: 100%;
    background: #FFF;
  }
`

const showBottomLeft = keyframes`
  0% { 
    opacity: .3;
    height: 100%;
    transform: scaleY(0);
    background: transparent;
  }
  60% { 
    opacity: .3;
    height: 100%;
    transform: scaleY(1);
    background: #FFF;
  }
  100% { 
    opacity: 1;
    height: 100%;
    transform: scaleY(1);
    background: #FFF;
  }
`
const showTopLeft = keyframes`
  0% { 
    opacity: .3;
    width: 100%;
    transform: scaleX(0);
    background: transparent;
  }
  100% { 
    opacity: 1;
    width: 100%;
    transform: scaleX(1);
    background: #FFF;
  }
`

const showTopRight = keyframes`
  0% { 
    opacity: .3;
    height: 100%;
    transform: scaleY(0);
    background: transparent;
  }
  100% { 
    opacity: 1;
    height: 100%;
    transform: scaleY(1);
    background: #FFF;
  }
`
const showTexts = keyframes`
  0% { 
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% { 
    opacity: 1;
  }
`

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  opacity: 0.9;
  background: #121212;
  overflow: hidden;
  color: #FFF;
  z-index: 9999;

  &.opacity-05{
    opacity: 0.9;
    pointer-events: none;
    touch-action: none;
    transition: all 0.5s ease-in;
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none; 
  user-select: none;
  padding-bottom: 100px;

  @media (min-height: 768px){
    padding-bottom: 0;
  }
  
`

export const QuadContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  opacity: ${p => p.opacity};
  transition: all 1.5s ease-out;

  @media (min-height: 768px){
    transform: translateX(-${p => p.translate}px);
  }
`

export const TextsContainer = styled.div`
  position: absolute;
  width: 150px;
  height: 200px;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-42px);
  color: #FFF;
  text-align: center;
  animation: ${showTexts} 7s ease forwards;
`

export const Status = styled.h2`
  font-size: 20px;
  color: #FFF;
  margin: 0;
  margin-top: 20px;
`

export const TextCarregar = styled.p`
  font-size: 14px;
  color: #FFF;
  margin-top: 40px;
`

export const Quad = styled.div`
  position: relative;
  transform-origin: center;
  transform: rotate(-45deg);
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  transition: all 1.5s ease-out;
`

export const BorderBlack = styled.div`
  position: absolute;
  z-index: 0;
  opacity: 0;
  animation: ${fadeIn} 2s ease forwards;

  &.top-left {
    top: 0;
    right: 0;
    width: inherit;
    height: 1px;
    background: #000;
  }

  &.top-right {
    top: 0;
    right: 0;
    width: 1px;
    height: inherit;
    background: #000;
  }

  &.bottom-right {
    top: 100%;
    left: 0;
    width: inherit;
    height: 1px;
    background: #000;
  }

  &.bottom-left {
    top: 0;
    left: 0;
    width: 1px;
    height: inherit;
    background: #000;
  }
`

export const Border = styled.div`
  position: absolute;
  z-index: 1;
  &.top-left {
    top: 0;
    right: 0;
    width: inherit;
    height: 1px;
    background: #FFF;
    opacity: 0;
    transform-origin: left;
    animation: ${showTopLeft} 2s ease forwards 3.8s;
  }

  &.top-right {
    top: 0;
    right: 0;
    width: 1px;
    height: inherit;
    background: #FFF;
    opacity: 0;
    transform-origin: bottom;
    animation: ${showTopRight} 2s ease forwards 3.8s;
  }

  &.bottom-right {
    top: 100%;
    left: 0;
    width: 0;
    height: 1px;
    background: #FFF;
    opacity: 0;
    animation: ${showBottomRight} 4.5s ease forwards 1.5s;
  }

  &.bottom-left {
    top: 0;
    left: 0;
    width: 1px;
    height: inherit;
    background: #FFF;
    opacity: 0;
    transform-origin: bottom;
    animation: ${showBottomLeft} 4.5s ease forwards 1.5s;
  }

`

