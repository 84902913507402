import { AppActionType } from '../types'

export const updateCurrentAct = (room) => ({ type: AppActionType.UPDATE_CURRENT_ACT, payload: room })
export const updateCurrentActSong = (song) => ({ type: AppActionType.UPDATE_CURRENT_ACT_SONG, payload: song })
export const updateMuted = (muted = null) => ({ type: AppActionType.UPDATE_MUTED, payload: muted })
export const updateNetworkSpeed = (speed) => ({ type: AppActionType.UPDATE_NETWORK_SPEED, payload: speed })
export const updateSelectProductMenu = (condition) => ({ type: AppActionType.UPDATE_SELECT_PRODUCT_MENU, payload: condition })
export const updateLanguage = (language) => ({ type: AppActionType.UPDATE_LANGUAGE, payload: language })
export const updateCurrentLayer = (currentLayer) => ({ type: AppActionType.UPDATE_CURRENT_LAYER, payload: currentLayer })
export const setActiveLayers = (activeLayers) => ({ type: AppActionType.UPDATE_ACTIVE_LAYERS, payload: activeLayers })
export const updateCurrentPano = (room) => ({ type: AppActionType.UPDATE_CURRENT_PANO, payload: room })
export const setFromStart = (condition) => ({ type: AppActionType.UPDATE_FROM_START, payload: condition })

export const signInAPISuccess = (response) => ({
  type: AppActionType.SIGN_IN_API_SUCCESS,
  payload: response,
})

export const signInAPIFailure = (
  error,
) => ({
  type: AppActionType.SIGN_IN_API_FAILURE,
  payload: error,
})

export const signInAPI = (
  data,
) => ({
  type: AppActionType.SIGN_IN_API,
  payload: data,
})

export const signUpAPISuccess = (response, token) => ({
  type: AppActionType.SIGN_UP_API_SUCCESS,
  payload: {response, token}
})

export const signUpAPIFailure = (
  error,
) => ({
  type: AppActionType.SIGN_UP_API_FAILURE,
  payload: error,
})

export const signUpAPI = (
  data,
) => ({
  type: AppActionType.SIGN_UP_API,
  payload: data,
})

export const saveOnMyRevigresAPISuccess = (response) => ({
  type: AppActionType.SAVE_ON_MY_REVIGRES_API_SUCCESS,
  payload: response,
})

export const saveOnMyRevigresAPIFailure = (
  error,
) => ({
  type: AppActionType.SAVE_ON_MY_REVIGRES_API_FAILURE,
  payload: error,
})

export const saveOnMyRevigresAPI = (
  data,
) => ({
  type: AppActionType.SAVE_ON_MY_REVIGRES_API,
  payload: data,
})


export const recoveryPasswordAPISuccess = (response) => ({
  type: AppActionType.RECOVERY_PASSWORD_API_SUCCESS,
  payload: response,
})

export const recoveryPasswordAPIFailure = (
  error,
) => ({
  type: AppActionType.RECOVERY_PASSWORD_API_FAILURE,
  payload: error,
})

export const recoveryPasswordAPI = (
  data,
) => ({
  type: AppActionType.RECOVERY_PASSWORD_API,
  payload: data,
})



export const createUserOnMyRevigresAPI = (
  data,
) => ({
  type: AppActionType.CREATE_USER_ON_MY_REVIGRES_API,
  payload: data,
})

export const createUserOnMyRevigresAPISuccess = (response) => ({
  type: AppActionType.CREATE_USER_ON_MY_REVIGRES_API_SUCCESS,
  payload: response,
})

export const createUserOnMyRevigresAPIFailure = (
  error,
) => ({
  type: AppActionType.CREATE_USER_ON_MY_REVIGRES_API_FAILURE,
  payload: error,
})

export const getCountriesAPI = () => ({
  type: AppActionType.GET_COUNTRIES_API
})

export const getCountriesAPISuccess = (response) => ({
  type: AppActionType.GET_COUNTRIES_API_SUCCESS,
  payload: response,
})

export const getCountriesAPIFailure = (
  error,
) => ({
  type: AppActionType.GET_COUNTRIES_API_FAILURE,
  payload: error,
})

export const getDistrictsAPI = () => ({
  type: AppActionType.GET_DISTRICTS_API
})

export const getDistrictsAPISuccess = (response) => ({
  type: AppActionType.GET_DISTRICTS_API_SUCCESS,
  payload: response,
})

export const getDistrictsAPIFailure = (
  error,
) => ({
  type: AppActionType.GET_DISTRICTS_API_FAILURE,
  payload: error,
})