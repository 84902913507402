import React from 'react'
import { Container, TitleContainer, Title, BottonsContainer, Button, Icon, Description } from './index.styled';
import { IconNavegueEspaco, IconPersonalizeEspaco, IconPontoVista } from '../../assets'
import { FormattedMessage } from '../../components'

const HelpComponent = () =>  {

  return (
    <Container>
      <TitleContainer>
        <Title><FormattedMessage id="help" /></Title>
      </TitleContainer>
      <BottonsContainer>
        <Button>
          <Icon src={ IconPersonalizeEspaco } />
          <Description><FormattedMessage id="help.customize-your-space" /></Description>
        </Button>
        <Button>
          <Icon src={ IconPontoVista } />
          <Description><FormattedMessage id="help.change-of-viewpoint" /></Description>
        </Button>
        <Button>
          <Icon src={ IconNavegueEspaco } />
          <Description><FormattedMessage id="help.navigate-on-space" /></Description>
        </Button>
      </BottonsContainer>
    </Container>
  )
}

export default React.memo(HelpComponent)
