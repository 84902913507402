import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  NewVRPage,
  SplashPage,
  StartTourPage,
  CollectionPage,
  ExplorePage,
  LoadingPage,
  HelpPage,
  LoginPage,
  ReadyPage,
} from "./pages";
import { getNetworkDownloadSpeed } from "./utils";
import { en, pt } from "./assets/translations";
import Player from "./components/player";
import * as _ from "lodash";
import qs from 'qs'

const whyDidYouRender = require("@welldone-software/why-did-you-render");
whyDidYouRender(React, {
  trackAllPureComponents: false,
  logOnDifferentValues: true,
});

const CustomRoute = ({ component: Component, componentProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Component
          {...componentProps}
          {...props}
          {..._.get(props, "match.params", {})}
          {..._.get(props, "history.location.state", {})}
          {...qs.parse(_.get(props, 'history.location.search'), { ignoreQueryPrefix: true })}
        />
      )}
    />
  );
};

const messages = {
  en,
  pt,
};

const App = ({ locale }) => {
  useEffect(() => {
    getNetworkDownloadSpeed();
  }, []);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <React.Suspense fallback={<LoadingPage redirect={false} />}>
        <Router>
          <Player />
          <Switch>
            <CustomRoute
              exact
              name="start"
              component={StartTourPage}
              path="/start"
            />
            <CustomRoute exact name="help" component={HelpPage} path="/help" />
            <CustomRoute
              exact
              name="login"
              component={LoginPage}
              path="/login"
            />
            <CustomRoute
              exact
              name="explore"
              component={ExplorePage}
              path="/explore"
            />
            <CustomRoute
              exact
              name="vr"
              component={NewVRPage}
              path="/vr/:model/:pano"
            />
            <CustomRoute
              exact
              name="splash"
              component={SplashPage}
              path="/splash"
            />
            <CustomRoute
              exact
              name="ready"
              component={ReadyPage}
              path="/ready"
            />
            <CustomRoute
              exact
              name="collection"
              component={CollectionPage}
              path="/collection/:id"
            />
            <CustomRoute name="loading" component={LoadingPage} path="/" />
          </Switch>
        </Router>
      </React.Suspense>
    </IntlProvider>
  );
};

const mapStateToProps = ({ app }) => {
  const { locale } = app;
  return { locale };
};

export default connect(mapStateToProps, null)(React.memo(App));
