import styled, { keyframes } from 'styled-components';

const colorFilled = 'rgba(255,255,255, 1)';
const colorFocus = 'rgba(255,255,255, .9)';
const colorFont = 'rgba(255,255,255, .7)';

const showOtherInput = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const Form = styled.form`
  width: 100%;
`

export const InputTitle = styled.p`
  color: ${colorFilled};
  font-size: 18px;
  font-family: Martel;
  margin-bottom: 4px;
  @media (min-width: 768px) {
    font-size: 25px;
    margin-bottom: 10px;
  }
`

export const Container = styled.div`
  display: flex;
  color: #FFF;

  @media (min-width: 768px) {
    padding: 0 30px;
  }
`

export const InputTextContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 24px;
  margin-bottom: 10px;
  margin-top: 60px;

  &.no-mb {
    margin-bottom: 0px;
  }

  @media (min-width: 768px) {
    margin-bottom: 38px;
    padding-bottom: 28px;
    margin-top: initial;
  }
`



export const ValidationError = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 0px;
  left: 0px;
  font-size: 14px;
  letter-spacing: 1.2px;
  color: #ff6268;
  animation: ${showOtherInput} .3s ease-in forwards;

  svg {
    fill: #ff6268;
    margin-bottom: 2px;
    margin-right: 6px;
  }
`

export const ValidationSuccess = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 0px;
  left: 0px;
  font-size: 14px;
  letter-spacing: 1.2px;
  color: #2cc266;
  animation: ${showOtherInput} .3s ease-in forwards;

  svg {
    fill: #2cc266;
    margin-bottom: 2px;
    margin-right: 6px;
  }
`

export const InputText = styled.input`
  width: 100%;
  background: transparent !important;
  height: 32px;
  border: none;
  outline: none;
  border-top: solid 1px ${colorFont};
  color: ${colorFont};
  letter-spacing: 1.28px;
  transition: all .3s linear;
  padding: 6px 4px 4px 4px;

  @media (min-width: 768px) {
    padding: 6px 4px 26px 4px;
  }

  &.b-bottom {
    border-top: none;
    border-bottom: solid 1px ${colorFont};
    font-size: 14px;
  }
  
  &:focus {
    border-top: solid 1px ${colorFocus};
    color: ${colorFocus};

    &.b-bottom {
      border-top: none;
      border-bottom: solid 1px ${colorFocus};
    }

    &::placeholder {
      color: ${colorFocus};
    }
  }

  &.filled {
    color: ${colorFilled};
    border-top: solid 1px${colorFilled};

    &.b-bottom {
      border-top: none;
      border-bottom: solid 1px ${colorFilled};
    }

    &:focus {
      border-top: solid 1px ${colorFocus};
      color: ${colorFocus};

      &.b-bottom {
        border-top: none;
        border-bottom: solid 1px ${colorFocus};
      }
    }
  }

  &::placeholder {
    color: ${colorFont};
    transition: all .3s linear;
  }
`

export const ForgotContainer = styled.div`
  display: flex;
  align-items: 'center';
  color: #FFF;

`

export const TextForgotPassword = styled.p`
  cursor: pointer;
  color: rgba(255,255,255,.6);
  transition: all .3s ease;
  text-decoration: underline;

  &:hover {
    color: rgba(255,255,255,1);
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    width: 100%;

    &:last-child {
      margin-top: 14px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;

    > button {
      width: 48%;

      &:last-child {
        margin-top: 0;
      }
    }
  }
`
export const FormButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Overpass;
  width: 100%;
  height: 35px;
  border-radius: 20px;
  background-color: ${p => p.bg || "transparent"};
  border: 1px solid ${p => p.border || "transparent"};
  text-transform: uppercase;
  letter-spacing: .4px;
  color: #FFF;
  font-size: 10px;
  transition: all .1s linear;
  outline: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &:hover {
    &:not(:disabled) {
      cursor: pointer;
      background: ${p => p.bghover || "initial"};
      border: 1px solid ${p => p.borderHover || "initial"};
      color: #FFF;
    }
  }
`