import { REHYDRATE } from 'redux-persist'
import { AppActionType } from './../types'
import { Util } from './../../utils'

const defaultState = {
  currentAct: null,
  currentActSong: null,
  muted: false,
  networkSpeed: 0,
  selectProductMenu: false,
  locale: String(navigator.language || navigator.userLanguage).substr(0, 2) ?? 'en',
  currentLayer: null,
  layersActive: {},
  token: null,
  saved: null,
  currentPano: null,
  countries: [],
  districts: [],
  fromStart: false
}

export default (state = defaultState, action) => {
  switch (action.type) {

    case REHYDRATE:
      action.payload !== undefined
        ? action.payload.app !== undefined ? document.title = Util.setTitle(action.payload.app.locale) : document.title = Util.setTitle(defaultState.locale)
        : document.title = Util.setTitle(defaultState.locale)
      return {
        ...state,
        locale:
          action.payload !== undefined
            ? action.payload.app !== undefined ? action.payload.app.locale : defaultState.locale
            : defaultState.locale,
        currentLayer:
          action.payload !== undefined
            ? action.payload.app !== undefined ? action.payload.app.currentLayer : defaultState.currentLayer
            : defaultState.currentLayer,
        layersActive:
          action.payload !== undefined
            ? action.payload.app !== undefined ? action.payload.app.layersActive : defaultState.layersActive
            : defaultState.layersActive,
        token:
          action.payload !== undefined
            ? action.payload.app !== undefined ? action.payload.app.token : defaultState.token
            : defaultState.token,
        currentPano:
          action.payload !== undefined
            ? action.payload.app !== undefined ? action.payload.app.currentPano : defaultState.currentPano
            : defaultState.currentPano
      }
    case AppActionType.UPDATE_CURRENT_ACT:
      return { ...state, currentAct: action.payload }
    case AppActionType.UPDATE_CURRENT_ACT_SONG:
      return { ...state, currentActSong: action.payload }
    case AppActionType.UPDATE_MUTED:
      return { ...state, muted: action.payload === null ? !state.muted : action.payload }
    case AppActionType.UPDATE_NETWORK_SPEED:
      return { ...state, networkSpeed: action.payload }
    case AppActionType.UPDATE_SELECT_PRODUCT_MENU:
      return { ...state, selectProductMenu: action.payload }
    case AppActionType.UPDATE_LANGUAGE:
      document.title = Util.setTitle(action.payload)
      return { ...state, locale: action.payload }
    case AppActionType.UPDATE_CURRENT_LAYER:
      return { ...state, currentLayer: action.payload }
    case AppActionType.UPDATE_ACTIVE_LAYERS:
      return { ...state, layersActive: action.payload }
    case AppActionType.SIGN_IN_API:
      return { ...state, loading: true }

    case AppActionType.SIGN_IN_API_FAILURE:
      return { ...state, errorMessage: action.payload, loading: false }

    case AppActionType.SIGN_IN_API_SUCCESS:
      return { ...state, loading: false, errorMessage: null, token: action.payload.data?.data?.token }

    case AppActionType.SIGN_UP_API:
      return { ...state, loading: true }

    case AppActionType.SIGN_UP_API_FAILURE:
      return { ...state, errorMessage: action.payload, loading: false }

    case AppActionType.SIGN_UP_API_SUCCESS:
      return { ...state, loading: false, errorMessage: null, token: action.payload.token }

    case AppActionType.SAVE_ON_MY_REVIGRES_API:
      return { ...state, loading: true, saved: null }

    case AppActionType.SAVE_ON_MY_REVIGRES_API_FAILURE:
      return { ...state, errorMessage: action.payload, loading: false, saved: false }

    case AppActionType.SAVE_ON_MY_REVIGRES_API_SUCCESS:
      return { ...state, loading: false, errorMessage: null, saved: true }

    case AppActionType.UPDATE_CURRENT_PANO:
      return { ...state, currentPano: action.payload }

    case AppActionType.RECOVERY_PASSWORD_API:
      return { ...state, loading: true }

    case AppActionType.RECOVERY_PASSWORD_API_FAILURE:
      return { ...state, errorMessage: action.payload, loading: false }

    case AppActionType.RECOVERY_PASSWORD_API_SUCCESS:
      return { ...state, loading: false, errorMessage: null }

    case AppActionType.GET_COUNTRIES_API:
      return { ...state, loading: true }

    case AppActionType.GET_COUNTRIES_API_FAILURE:
      return { ...state, errorMessage: action.payload, loading: false }

    case AppActionType.GET_COUNTRIES_API_SUCCESS:
      return { ...state, loading: false, errorMessage: null, countries: action.payload?.data?.data }

    case AppActionType.GET_DISTRICTS_API:
      return { ...state, loading: true }

    case AppActionType.GET_DISTRICTS_API_FAILURE:
      return { ...state, errorMessage: action.payload, loading: false }

    case AppActionType.GET_DISTRICTS_API_SUCCESS:
      return { ...state, loading: false, errorMessage: null, districts: action.payload?.data?.data }

    case AppActionType.UPDATE_FROM_START:
      return { ...state, fromStart: action.payload }

    default: return { ...state }
  }
}