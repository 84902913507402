import React from 'react'

const HotspotProductIcon = ({ onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 62 62" onClick={onClick}>
      <defs>
        <clipPath id="b">
          <rect width="62" height="62"/>
        </clipPath>
      </defs>
      <g id="a" clipPath="url(#b)">
        <path d="M29,0A29,29,0,1,1,0,29,29,29,0,0,1,29,0Z" transform="translate(2 2)" fill="#121212" opacity="1"/>
        <path d="M0,6.255,13.408,0,26.816,6.255,13.408,12.492Z" transform="translate(17.779 28.027)" fill="#91877e"/>
        <path d="M0,6.255,13.408,0,26.816,6.255,13.408,12.492Z" transform="translate(17.779 21.949)" fill="none" stroke="#eeebe9" strokeLinejoin="round" strokeWidth="1"/>
        <g transform="translate(2 2)" fill="none" strokeLinecap="round">
          <path d="M29,0A29,29,0,1,1,0,29,29,29,0,0,1,29,0Z" stroke="none"/>
          <path d="M 29 0 C 12.98374176025391 0 0 12.98374176025391 0 29 C 0 45.01625823974609 12.98374176025391 58 29 58 C 45.01625823974609 58 58 45.01625823974609 58 29 C 58 12.98374176025391 45.01625823974609 0 29 0 M 29 -1 C 33.04953002929688 -1 36.97846984863281 -0.2066688537597656 40.67765808105469 1.357959747314453 C 44.25016021728516 2.868999481201172 47.45838165283203 5.031970977783203 50.21319961547852 7.786800384521484 C 52.9680290222168 10.54161834716797 55.13100051879883 13.74983978271484 56.64204025268555 17.32233810424805 C 58.20666885375977 21.02153015136719 59 24.95046997070312 59 29 C 59 33.04953002929688 58.20666885375977 36.97846984863281 56.64204025268555 40.67765808105469 C 55.13100051879883 44.25016021728516 52.9680290222168 47.45838165283203 50.21319961547852 50.21319961547852 C 47.45838165283203 52.9680290222168 44.25016021728516 55.13100051879883 40.67765808105469 56.64204025268555 C 36.97846984863281 58.20666885375977 33.04953002929688 59 29 59 C 24.95046997070312 59 21.02153015136719 58.20666885375977 17.32233810424805 56.64204025268555 C 13.74983978271484 55.13100051879883 10.54161834716797 52.9680290222168 7.786800384521484 50.21319961547852 C 5.031970977783203 47.45838165283203 2.868999481201172 44.25016021728516 1.357959747314453 40.67765808105469 C -0.2066688537597656 36.97846984863281 -1 33.04953002929688 -1 29 C -1 24.95046997070312 -0.2066688537597656 21.02153015136719 1.357959747314453 17.32233810424805 C 2.868999481201172 13.74983978271484 5.031970977783203 10.54161834716797 7.786800384521484 7.786800384521484 C 10.54161834716797 5.031970977783203 13.74983978271484 2.868999481201172 17.32233810424805 1.357959747314453 C 21.02153015136719 -0.2066688537597656 24.95046997070312 -1 29 -1 Z" stroke="none" fill="#eeebe9"/>
        </g>
      </g>
    </svg>
  )
}

export {HotspotProductIcon}