import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects'

import {
  signInAPIFailure,
  signInAPISuccess,
  signUpAPIFailure,
  signInAPI as signInAPI2,
  saveOnMyRevigresAPISuccess,
  saveOnMyRevigresAPIFailure,
  recoveryPasswordAPIFailure,
  recoveryPasswordAPISuccess,
  createUserOnMyRevigresAPISuccess,
  createUserOnMyRevigresAPIFailure,
  getCountriesAPISuccess,
  getCountriesAPIFailure,
  getDistrictsAPIFailure,
  getDistrictsAPISuccess,
  signUpAPISuccess
} from './../actions'
import {
  AppActionType,
} from './../types'
import { API, APIAuth } from './../../utils/api'

const signInRequest = async (user) => APIAuth.post('v0/auth/token', user)
function* signInAPI({ payload }) {
  try {
    const request = yield call(signInRequest, payload)
    yield put(signInAPISuccess(request))
  } catch (error) {
    yield put(signInAPIFailure(error))
  }
}

export function* signIn() {
  yield takeEvery(AppActionType.SIGN_IN_API, signInAPI)
}

const signUpRequest = async (user) => APIAuth.post('v0/users', user)
const createUserOnMyRevigresRequest = async (data, token) => API({
  method: 'post',
  url: 'users',
  data: data,
  headers: {
    Authorization: `Bearer ${token}`
  }
})

function* signUpAPI({ payload }) {
  try {
    let user = {
      email: payload.email,
      password: payload.password
    }
    yield call(signUpRequest, user)
    let token = yield call(signInRequest, user)

    let typeCountry = payload.country_alpha2 === "PT" ? {district_id: payload.district_id} : {location: payload.location}

    let user2 = yield call(createUserOnMyRevigresRequest, { 
      type: payload.type,
      name: payload.name,
      country_alpha2: payload.country_alpha2,
      phone_number: payload.phone_number,
      company: payload.company,
      professional_type: payload.professional_type,
      job_title: payload.job_title,
      ...typeCountry
    }, token?.data?.data?.token)
    

    yield put(signUpAPISuccess(user2, token?.data?.data?.token))
    
  } catch (error) {
    if(error && error.response){
      if(error.response.status === 409){
        yield put(signInAPI2(payload))
      }else{
        yield put(signUpAPIFailure(error))
      }
    }else{
      yield put(signUpAPIFailure(error))
    }
    
  }
}

export function* signUp() {
  yield takeEvery(AppActionType.SIGN_UP_API, signUpAPI)
}


const saveOnMyRevigresRequest = async (data) => API.post('me/favorites/simulator', data)
function* saveOnMyRevigresAPI({ payload }) {
  try {
    const request = yield call(saveOnMyRevigresRequest, payload)
    yield put(saveOnMyRevigresAPISuccess(request))
  } catch (error) {
    yield put(saveOnMyRevigresAPIFailure(error))
  }
}

export function* saveOnMyRevigres() {
  yield takeEvery(AppActionType.SAVE_ON_MY_REVIGRES_API, saveOnMyRevigresAPI)
}


function* createUserOnMyRevigresAPI({ payload }) {
  try {
    const request = yield call(createUserOnMyRevigresRequest, payload)
    yield put(createUserOnMyRevigresAPISuccess(request))
  } catch (error) {
    yield put(createUserOnMyRevigresAPIFailure(error))
  }
}

export function* createUserOnMyRevigres() {
  yield takeEvery(AppActionType.CREATE_USER_ON_MY_REVIGRES_API, createUserOnMyRevigresAPI)
}

const recoveryPasswordRequest = async (data) => APIAuth.post('v0/users/password/forgot', data)
function* recoveryPasswordAPI({ payload }) {
  try {
    const request = yield call(recoveryPasswordRequest, payload)
    yield put(recoveryPasswordAPISuccess(request))
  } catch (error) {
    yield put(recoveryPasswordAPIFailure(error))
  }
}

export function* recoveryPassword() {
  yield takeEvery(AppActionType.RECOVERY_PASSWORD_API, recoveryPasswordAPI)
}

const getCountriesRequest = async () => API.get('countries')
function* getCountriesAPI() {
  try {
    const request = yield call(getCountriesRequest)
    yield put(getCountriesAPISuccess(request))
  } catch (error) {
    yield put(getCountriesAPIFailure(error))
  }
}

export function* getCountries() {
  yield takeEvery(AppActionType.GET_COUNTRIES_API, getCountriesAPI)
}

const getDistrictsRequest = async () => API.get('districts')
function* getDistrictsAPI() {
  try {
    const request = yield call(getDistrictsRequest)
    yield put(getDistrictsAPISuccess(request))
  } catch (error) {
    yield put(getDistrictsAPIFailure(error))
  }
}

export function* getDistricts() {
  yield takeEvery(AppActionType.GET_DISTRICTS_API, getDistrictsAPI)
}

export default function* rootSaga() {
  yield all([
    fork(signIn),
    fork(signUp),
    fork(saveOnMyRevigres),
    fork(recoveryPassword),
    fork(createUserOnMyRevigres),
    fork(getCountries),
    fork(getDistricts)
  ])
}
  