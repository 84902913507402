import React from 'react'
import PropTypes from 'prop-types'

const HelpIcon = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.001" height="27.001" viewBox="0 0 27.001 27.001">
    <path fill={fill} className="a" d="M-4581.5-13740a13.418,13.418,0,0,1-9.547-3.953,13.411,13.411,0,0,1-3.956-9.546,13.412,13.412,0,0,1,3.956-9.547,13.41,13.41,0,0,1,9.547-3.955,13.4,13.4,0,0,1,9.545,3.955,13.413,13.413,0,0,1,3.954,9.547,13.412,13.412,0,0,1-3.954,9.546A13.414,13.414,0,0,1-4581.5-13740Zm-1.482-8.642a1.56,1.56,0,0,0-1.13.5,1.632,1.632,0,0,0-.491,1.182,1.216,1.216,0,0,0,.342.86,1.157,1.157,0,0,0,.877.359,1.573,1.573,0,0,0,1.161-.49,1.625,1.625,0,0,0,.48-1.192,1.2,1.2,0,0,0-.338-.867A1.206,1.206,0,0,0-4582.98-13748.642Zm1.979-9.7a1.841,1.841,0,0,1,1.372.48,1.632,1.632,0,0,1,.47,1.179,2.242,2.242,0,0,1-.38,1.281,4.33,4.33,0,0,1-.96,1c-.364.275-.764.584-1.188.919a5.359,5.359,0,0,0-1.15,1.323,5.5,5.5,0,0,0-.76,1.917l-.08.46h1.938l.08-.46a3.464,3.464,0,0,1,.691-1.51,5.91,5.91,0,0,1,1.15-1.119c.454-.332.856-.643,1.23-.95a4.082,4.082,0,0,0,1.02-1.278,3.929,3.929,0,0,0,.411-1.842,3.247,3.247,0,0,0-.93-2.359,3.474,3.474,0,0,0-2.57-.94,5.106,5.106,0,0,0-3.441,1.271,5.706,5.706,0,0,0-1.841,3.41H-4584a3.454,3.454,0,0,1,1.02-2.052A2.842,2.842,0,0,1-4581-13758.34Z" transform="translate(4595.001 13767.001)"/>
  </svg>
  )
}

HelpIcon.propTypes = {
  fill: PropTypes.string
}

HelpIcon.defaultProps = {
  fill:"#eeebe9"
}

export { HelpIcon }
