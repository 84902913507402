import React from 'react'
import PropTypes from 'prop-types'

const FullScreenIcon = ({ fill }) => {
  return (
            <svg id="Grupo_356" data-name="Grupo 356" xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18">
            <defs>
              <clipPath id="clipPath">
                <rect id="Retângulo_494" data-name="Retângulo 494" width="25" height="18" fill={fill}/>
              </clipPath>
            </defs>
            <g id="Grupo_356-2" data-name="Grupo 356" clipPath="url(#clipPath)">
              <path id="Caminho_483" data-name="Caminho 483" d="M22.894,0H2.106A2.1,2.1,0,0,0,0,2.084V15.916A2.1,2.1,0,0,0,2.106,18H22.894A2.1,2.1,0,0,0,25,15.916V2.084A2.1,2.1,0,0,0,22.894,0m.954,2.084V15.916a.95.95,0,0,1-.954.944H2.106a.95.95,0,0,1-.954-.944V2.084a.95.95,0,0,1,.954-.944H22.894a.95.95,0,0,1,.954.944" fill={fill}/>
              <path id="Caminho_484" data-name="Caminho 484" d="M110.937,190.01h-1.175v3.484a1.059,1.059,0,0,0,1.063,1.052h3.519v-1.163h-3.407Z" transform="translate(-104.603 -181.171)" fill={fill}/>
              <path id="Caminho_485" data-name="Caminho 485" d="M321.009,102.051h-3.521v1.163H320.9v3.374h1.175V103.1a1.059,1.059,0,0,0-1.063-1.052" transform="translate(-302.567 -97.304)" fill={fill}/>
            </g>
          </svg>
          )
}

FullScreenIcon.propTypes = {
  fill: PropTypes.string
}

FullScreenIcon.defaultProps = {
  fill: "#FFF"
}

export {FullScreenIcon}