import React, { useEffect } from 'react'
import { connect } from "react-redux";
import { useHistory } from 'react-router'
import { Loading } from '../../components'
import { updateCurrentActSong, updateLanguage } from "../../effects/actions";

const LoadingPage = ({ updateCurrentActSong, updateLanguage, className = "", forceLogoOnly, redirect = true, redirectPath = '/ready', lang }) => {
  const history = useHistory()
  // const redirectEffect = () => { redirect && setTimeout(() => { history.push(redirectPath) }, 3000) }
  // React.useEffect(redirectEffect, [])

  useEffect(() => {
    // updateCurrentActSong(null)

    redirect && setTimeout(() => { history.push(redirectPath) }, 3000)
    return () => {}
  }, [])

  useEffect(() => {
    if (String(lang).toUpperCase() === "EN" || String(lang).toUpperCase() === "PT") {
      updateLanguage(String(lang).toLowerCase())
    }
    return () => {}
  }, [lang])

  return (
    <div  className={`relative vh-100 d-flex justify-content-center align-items-center z-index-20 no-touch ${className}`} style={{ width: '100%' }}>
    {/* <div className={`background-dark absolute vh-100 z-index-20 no-touch ${className}`} style={{ width: '100%' }}> */}
      <div className="absolute-center">
        {/* <LogoLoadingIcon logoOnly={forceLogoOnly ?? isMobile()} /> */}
        <Loading isImage={false} />
      </div>
    </div>
  )
}

export default connect(null, { updateCurrentActSong, updateLanguage })(React.memo(LoadingPage))
