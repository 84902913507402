import styled from 'styled-components';

export const ListItemIconsStyled = styled.div`
  font-size:16px;
  margin-bottom:16px;
  margin-right: 2px;
  margin-top: -2px;
  opacity: .65;
  transition: opacity .3s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  svg {
    width:12px;
    height:12px;
  }

  @media (min-width: 768px) {
    margin-bottom:  32px;
    /* margin-right: 20px; */
    margin-top: 0;
    svg {
      width:16px;
      height:16px;
    }
  }
`