/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components'
import { useHistory } from 'react-router';
import { Row, Col } from 'antd';
import { CloseIcon, FormattedMessage } from '../';
import { ActMap0, ActMap1, ActMap2, ActMap3, ActMap4, ActMap5 } from "../acts-map";
import { useResize } from '../../hooks';
import {  } from '../../utils';
const acts = require('./../../assets/mock-data/act.json')


const MapComponent = ({ currentAct }) => {
    
    const history = useHistory()
    const [expanded, setExpand] = React.useState(false);
    const [selectedAct, setAct] = React.useState(currentAct);
    const activeAct = (item) => (item.id === (selectedAct?.id ?? currentAct?.id) ? `active` : ``)

    const isMobile = useResize().isMobile;

    const refTitleDesk = useRef(null)
    const refTitleMobile = useRef(null)


    useEffect(() => {
        if(expanded) {
            if(selectedAct?.id !== currentAct?.id){
                if(selectedAct?.id !== currentAct?.id) setAct(currentAct)
            }else{
                const findActive = acts.findIndex(model => activeAct(model) === "active");  
                if(findActive > -1) setAct(acts[findActive]);
            }
        }else{
            if(selectedAct?.id !== currentAct?.id) setAct(currentAct)
        }
    }, [expanded])

    if (!currentAct) return null

    return (
        <React.Fragment>
            {expanded && (
                <>
                    <MapDiv className={`absolute expanded d-flex justify-content-center opacity-animation ${expanded ? '' : 'hidden-delay'}`}>
                        <ExpandedDiv>
                            <Row className="h-100">
                                <div onClick={() => setExpand(false)} className="ant-modal-close-x doubleRight"><CloseIcon fill="#fff" /></div>
                                <Row className="map-row w-100">
                                    <ColMap span={24} key={selectedAct?.act} className="map-zone container-map h-100">
                                        <RenderMap hoverProduct={true} compass={false} map={selectedAct?.act} height="100%" width="100%" history={history} />
                                    </ColMap>
                                </Row>
                                <div className="w-100" style={{ paddingLeft: 8}}>
                                    <label className="mb-2 line-1 panel-title"><FormattedMessage id="map.all-spaces" /></label><br />
                                    <label className="mb-0 line-2 panel-subtitle"><FormattedMessage id="map.primary-text" /></label>
                                </div>
                                <nav role='navigation' className="main-nav" id="main-nav">
                                    <ul id="main-nav-list">
                                        {acts.map((model, i) => (
                                            <li onClick={() => setAct(model)} key={i} className={activeAct(model)}>
                                            <span><FormattedMessage id={model?.id} /></span>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </Row>
                        </ExpandedDiv>
                    </MapDiv>
                    <BgDark />
                </>
            )}

            <MapDiv className={`d-flex flex-row opacity-animation ${expanded ? 'hidden-delay' : ''}`}>
                {!expanded && isMobile && (
                    <MapZone onClick={() => setExpand(true)}>
                        <RenderMap compass={true} map={currentAct?.act} height="140px" width="100%" history={history} />
                        <span ref={refTitleMobile} className="ml-2 map-label">
                            {currentAct && <FormattedMessage id={currentAct?.id} />}
                        </span>
                    </MapZone>
                )}

                {!expanded && !isMobile && (
                    <MapZone onClick={() => setExpand(true)}>
                        <div className="image-container py-1 px-3">
                            <RenderMap compass={true} map={currentAct?.act} height="140px" width="100%" history={history} />
                        </div>
                        <div ref={refTitleDesk} onClick={() => setExpand(true)} className="map-label no-touch pb-1" >
                            {currentAct && <FormattedMessage id={currentAct?.id} />}
                        </div>
                    </MapZone>
                )}
            </MapDiv>
        </React.Fragment >
    )
}

export const Map = React.memo(MapComponent)

const RenderMap = React.memo(({ hoverProduct, map, compass, height, width, history }) => {
    // console.log("map", map)
    switch (map) {
        case '1': return <ActMap3 hoverProduct={hoverProduct} compass={compass} height={height} width={width} history={history} />
        case '2': return <ActMap4 hoverProduct={hoverProduct} compass={compass} height={height} width={width} history={history} />
        case '3': return <ActMap0 hoverProduct={hoverProduct} compass={compass} height={height} width={width} history={history} />
        case '4': return <ActMap1 hoverProduct={hoverProduct} compass={compass} height={height} width={width} history={history} />
        case '5': return <ActMap5 hoverProduct={hoverProduct} compass={compass} height={height} width={width} history={history} />
        case '6': return <ActMap2 hoverProduct={hoverProduct} compass={compass} height={height} width={width} history={history} />
        default: return null;
    }
})

const fadeIn = keyframes`
    0% { opacity: 0;}
    100% { opacity: 1;}
`

const ColMap = styled(Col)`
    opacity: 0;
    animation: ${fadeIn} 1s ease forwards;
`

const BgDark = styled.div`
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    animation: ${fadeIn} 1s ease forwards;
    background: rgba(0,0,0,.75);
`

const MapZone = styled.div`
    cursor: pointer;
    border-radius:20px;
    background-color: transparent;
    border: 2px solid #EEEBE900;
    transition: all .2s ease;
    :hover{
        background-color:#12121232;
        border: 2px solid #EEEBE960;
        transition: all .5s ease;
    }
    margin: 16px;
    z-index:2;
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; 
    width: auto;
    height: inherit;
    margin: 0 auto;

    .map-label{
        font-size:14px;
        font-family:Martel; 
        color: #FFF; 
        z-index: 1;
        user-select: none;
        text-align: center;

        @media (min-width: 768px) {
            min-height: 30px;
            line-height: 1;
            color: #444444;
            font-size: 24px;
            // margin-left: 36px;
            margin-top: -20px;
            margin-bottom: 20px;
            padding: 0 20px;
        }
    }

    .image-container {
        margin:  0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 220px;
    }

    @media (max-width: 767px) {
        box-sizing: border-box;
        padding: 8px 16px 8px 16px;
        background: rgba(0, 0, 0, 0);
        width: 165px;
        height: 155px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .compass-container {
            width: 65px;
            height: 65px;
            position:absolute;
        }
    }
`
export const BlueDotImg = styled.img`
    z-index:3;
    position: absolute; 
    @media (max-width: 768px) {
        left: 0; 
        top: 0;
    }
    @media (min-width: 768px) {
        left: calc(50% - 32px);
        top: calc(50% - 32px);
    }
    width: 65px; 
    height: 65px;
`
export const MapBackground = styled.div`
    z-index:2;
    position: absolute;
    top: 0;
    bottom:0;
    left:0;
    right:0;
    opacity: 0.02;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    &:hover{
        opacity: 0.2
    }
`
export const MapDiv = styled.div`
    position: absolute;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    flex-flow:nowrap;
    margin:20px; 
    color:#FFF; 
    font-family:'Martel';
    bottom: 0;
    z-index: 11;
    opacity: 0;
    animation: ${fadeIn} 1s ease forwards .1s;

    @media (max-width: 768px) {
        margin:24px;
    }

    &.expanded {
        z-index: 11;
        @media (max-width: 768px) {
            margin:10px;
            &:before {
                content: '';
                position: absolute;
                opacity: 0;
                top: -10px;
                left: -10px;
                width: 100vw;
                height: 100%;
                background-color: rgba(0,0,0, .8);
                z-index: 0;
                animation: ${fadeIn} 1s ease forwards;
            }
        }
        @media (min-width: 768px) {
            overflow: hidden;
            height: 100%;
            min-height: 500px;
            margin: 0 85px;
        }
        @media (min-width: 1400px) {
            min-height: 780px;
        }
    }
`
export const ExpandedDiv = styled.div`
    position: absolute;
    padding: 32px;
    
    z-index:11;
    background-color: #EEEBE9eb;
    max-width: 1360px;
    backdrop-filter: blur(2px);

    .panel-title{font-family:Martel; color: #917052; font-size:16px; line-height: 1}
    .panel-subtitle{font-family:Overpass; color: #444444; font-size:14px; line-height: 1.2rem;}

    .tabs-panel-50{
        height:100%;
        @media (max-width: 767px) { 
        height: calc(50% - 12px);
        padding-right:0!important;
        padding-bottom:0!important;
        &.m-overflow-auto{overflow:auto}
    }}

    .map-row{ height: calc(100% - 200px) }
    .map-row{ @media (min-width: 1300px) { padding: 20px; height: calc(100% - 130px) }}
    
    .text-grey{
        color: #545454;
    }

    .current-product{
        text-transform: capitalize; 
    }
    .description-text{
        color: #545454;
        font-family:'Martel';
        font-size: 16px;
    }
    .title-act, .current-act{
        font-family: 'Catamaran', sans-serif !important;
        font-weight: bold;
    }

    @media (max-width: 767px) {
        padding:12px; 
        padding-bottom:24px;
        height: 400px;
        top: 50%;
        transform: translateY(-50%);

        .title-act{
            text-align: center;
            font-family: 'Catamaran', sans-serif !important;
        }
        .current-act{
            display: none;
        }
    }

    @media (min-width: 768px) { 
        height: calc(100% - 32px); 
        max-height: 586px; 
        width: 80%;
        max-width: 930px;
        top: 50%;
        transform: translateY(-50%);
    }

    @media (min-width: 1400px) { 
        max-height: 600px;
    }
`