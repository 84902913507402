import React from 'react'
import PropTypes from 'prop-types'

const FacebookIcon = ({ fill }) => {
  return (
            <svg id="Grupo_338" data-name="Grupo 338" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
              <defs>
                <clipPath id="clipPath">
                  <rect id="Retângulo_478" data-name="Retângulo 478" width="25" height="25" fill={fill}/>
                </clipPath>
              </defs>
              <g id="Grupo_337" data-name="Grupo 337" clipPath="url(#clipPath)">
                <path id="Caminho_469" data-name="Caminho 469" d="M13.354,25H1.38A1.38,1.38,0,0,1,0,23.62V1.38A1.38,1.38,0,0,1,1.38,0H23.62A1.38,1.38,0,0,1,25,1.38V23.62A1.38,1.38,0,0,1,23.62,25H17.25V15.318H20.5l.486-3.771H17.25V9.136c0-1.091.3-1.836,1.87-1.836h2V3.925a26.49,26.49,0,0,0-2.911-.15c-2.882,0-4.854,1.759-4.854,4.988v2.784H10.095v3.771h3.259Z" fill={fill} fillRule="evenodd"/>
              </g>
            </svg>
  )
}

FacebookIcon.propTypes = {
  fill: PropTypes.string
}

FacebookIcon.defaultProps = {
  fill: "#FFF"
}

export {FacebookIcon}