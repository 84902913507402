import { FormattedMessage } from 'react-intl'
import { Button, Layout } from 'antd'
import styled from 'styled-components'
import Header from './header'
import Menu from './menu'
import LoginComponent from './login'
import FormLogin from './form-login'
import FormForgotPassword from './form-forgot-password'
import FormRegister from './form-register'
import Gallery from './gallery'
import Collection from './collection'
import {VR} from './new-vr'
import HelpComponent from './help';
import MenuSocialMedia from './menu-social-media';
import LoadingComponent from './loading'
import ModalLoginComponent from './modal-login'
import Zoombar from './zoombar'
import Footer from './footer'
export * from './icons'

export { 
  Header, Menu, MenuSocialMedia, LoginComponent, FormLogin, FormForgotPassword, FormRegister, Zoombar, Gallery, Collection, VR, 
  LoadingComponent as Loading, FormattedMessage, HelpComponent, Footer , ModalLoginComponent as ModalLogin
}
const { Header: HeaderLayout, Footer: FooterLayout, Content } = Layout;
export const DefaultLabel = styled.label`
  font-size: 12px; 
  font-family:Overpass; 
  letter-spacing: 0.36px; 
  color: #bbb9b8; 
  text-transform: uppercase;
  margin-bottom:4px;
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-top: 14px;
  }
`
export const DefaultSpan = styled.span`
  font-size: 14px; 
  font-family:Overpass; 
  letter-spacing: 0.6px; 
  color: #777675; 
  margin-bottom: 12px;
  @media (min-width: 768px) {
    font-size: 18px; 
  }
`
export const DefaultLink = styled.a`font-size: 10px; font-family:Overpass; font-weight:600; letter-spacing: 0.36px; color: #00678E; text-transform: uppercase; opacity: 1; text-decoration: underline;`
export const DrawerLayout = styled.section`padding: 0; display: flex; flex-direction: column; background-color: ${p => p.backgroundColor ?? '#7B8179'}; max-height: 100%; height: 100%; width: 100vw; position: absolute; top: 0; left: 0;background-size: cover; background-image: url(${p => p.src});`
export const FloatLeftImage = styled.img`
  width: 86px;
  height: auto;
  float: left; 
  pointer-events: none; 
  cursor: pointer;
  @media (min-width: 768px) {
    width: 100px;
  }
`
export const FloatRightImage = styled.img`float: right; z-index: 1;`
export const FooterStyled = styled(FooterLayout)`
  background: transparent;
  display: flex;
  
  color:#EEEBE9;
  margin: 0 30px; 
  height: ${p => p.height ?? "60px"}; 
  padding: ${p => p.padding ?? "unset"};
  @media (min-width: 768px) {
    margin: 44px 60px;
    margin-top: 0; 
  }

  p {
    padding: 0;
    margin: 0;
    font-size: 12px;
    letter-spacing: .3px;

    @media (min-width: 768px) {
      font-size: 14px;
    }
  }

  .powered {
    opacity: .5;
    padding-top: 4px;
    font-size: 12px;
    letter-spacing: .5px;
    cursor: pointer;
  }

  .powered.no-cursor {
    cursor: auto;
  }
  &.mt-auto {
    margin-top: auto;
  }
`

export const FooterStyledTextContainer = styled.div`
  width: 100%;
  height: 30px;
`

export const FullScreenBlack = styled(Layout)`background-color: #121212; height: 100%; width: 100vw; position: absolute; top: 0;left: 0;right: 0;bottom: 0;`
export const PrimaryButton = styled(Button)`
  margin-top: 8px; 
  margin-bottom: 8px; 
  height: 40px;
  background: #444444;
  border: 1px solid #EEEBE9; 
  border-radius: 26px; 
  font-size: 12px; 
  font-family:Overpass; 
  letter-spacing: 0.36px; 
  color: #EEEBE9; 
  text-transform: uppercase;
  @media (min-width: 768px) {
    height: 40px;
    min-height: 40px;
  }
  :focus,:hover,:active{
    background:#7B8179;
    border:1px solid #7B8179; 
    color: #EEEBE9
  };
`
export const SecondaryButton = styled(Button)`
  margin-top: 8px; 
  margin-bottom: 8px; 
  height: 40px; 
  background: #eeebe9; 
  border: 1px solid #444444; 
  border-radius: 26px; 
  font-size: 12px; 
  font-family:Overpass; 
  letter-spacing: 0.36px; 
  color: #444444; 
  text-transform: uppercase;
  padding-left: 2px;
  padding-right: 2px;
  @media (min-width: 768px) {
    height: 40px;
    min-height: 40px;
  }
  :focus,:hover,:active{
    background:#7B8179;
    border:1px solid #7B8179; 
    color: #EEEBE9
  };
`
export const MenuContent = styled(Content)`background: transparent; margin-left: 60px; margin-right: 60px; display: flex; flex-direction: column; justify-content: flex-start; overflow: scroll;`
export const HeaderStyled = styled(HeaderLayout)`
  background: transparent; 
  margin-top: 30px;
  margin-left: ${p => p.margin ? p.margin: '40px'}; 
  margin-right: ${p => p.margin ? p.margin: '40px'}; 
  padding: 0; 
  line-height: 1.5;
  height: 40px !important;
  @media (min-width: 768px) {
    height: 64px;
    margin-top: 60px; 
    margin-left: 60px; 
    margin-right: 60px;
  }
`

export const SpacingDiv = styled.div`
  min-width: 8px;

  @media (min-width: 768px) {
    min-width: 24px;
  }
`