import React from 'react'

const CloseProductIcon = ({ stroke = "#111", fill = "rgba(0,0,0,0.07)"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <path id="Path_463" data-name="Path 463" d="M28.419,0,15,13.419,1.581,0,0,1.581,13.418,15,0,28.419,1.581,30,15,16.581,28.419,30,30,28.419,16.581,15,30,1.581Z" transform="translate(0 0)" fill="#777"/>
    </svg>
  )
}

CloseProductIcon.propTypes = {
}

CloseProductIcon.defaultProps = {

}

export {CloseProductIcon}