import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createGlobalStyle } from 'styled-components'
import { BgFade } from './assets';
import { store } from './effects/store'
import App from './app'
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css'
import './utils.css'
import { fadeOut } from './components/loading/index.style';
import TagManager from 'react-gtm-module'

const GlobalStyle = createGlobalStyle`
html {
    height: 100% !important;
    overflow: hidden !important;
}
body {
    max-height: 100% !important;
    overflow: hidden !important;
}

#root {
    height: 100% !important;
    max-height: 100% !important;
    overflow: hidden !important;
}
*::-webkit-scrollbar { width: 1px; height: 1px } *::-webkit-scrollbar-track { background: transparent } *::-webkit-scrollbar-thumb { background: transparent }
* { scrollbar-color: transparent; scrollbar-width: thin; }
.ant-drawer-wrapper-body, .ant-drawer-body {max-height: 100%; height: 100%; max-width: 100vw; width: 100vw } .ant-layout-header{line-height: 1 !important} *:focus{outline: none}
.ant-modal {
    @media (min-width: 768px) {
        min-width: 670px !important;
    }
}
.ant-modal-body {
    padding: 20px;

    @media (min-width: 768px) {
        padding: 60px 70px 40px 60px;
    }
} 
.ant-modal-content {
    background: #EEEBE9 ;

    @media (min-width: 768px) {
        width: 670px !important;
        /* min-height: 530px; */
    }
} 
.ant-modal-mask{ background-color: #000000d0}
.ant-drawer-close {
    display: none;
}
.modal-content-margin-bottom {
    @media (min-width: 768px) {
        margin-bottom: 20px;
    }
}
.ant-modal-close-x{
    cursor: pointer;
    svg{
        width:100%;
        height:100%
    }
    width:30px;
    height:30px;
    position:absolute;
    right: 0;
    top:-30px;
    cursor: pointer;

    &.doubleRight {
        right: -6px;
        top: -30px;

        svg{
            height: 20px;
        }
    }

    @media (min-width: 768px) {
        right: -60px;
        top: -20px;
        &.doubleRight {
            right: -60px;
            top: -20px;
        }
    }

    
    span{
        color:#EEEBE9}; 
        line-height:1
}
.ant-tooltip.ant-slider-tooltip.ant-tooltip-placement-bottom {
    padding-top: 0px !important;
    z-index: 1;
} 
.ant-tooltip-content {
    margin: 0;
    padding: 0;
    opacity: 0;
    transition: opacity .3s ease;

    &:hover {
        opacity: 1;
    }

    &:active {
        opacity: 1;
    }

    &:focus {
        opacity: 1;
    }

    @media (min-width: 768px){
        opacity: 1;
    }
}
.ant-tooltip-inner{
    margin: -2px;
    padding: 0;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: .4px;
}
.ant-tooltip-arrow-content, .ant-tooltip-inner {
   background-color: transparent !important;
   box-shadow: none;
}
.baseline{padding: unset; display: flex; align-items: flex-end}
.close-white{filter:brightness(100); cursor: pointer;}
@font-face { font-family:Martel;   src: local(Martel),       url('/fonts/Martel-600.woff2')   format('woff2');  font-weight: normal; font-display: swap; unicode-range: U+000-5FF; }
@font-face { font-family:Overpass; src: local(Overpass),     url('/fonts/Overpass.woff2')     format('woff2');  font-weight: normal; font-display: swap; unicode-range: U+000-5FF; }
@font-face { font-family:Overpass; src: local(Overpass-700), url('/fonts/Overpass-700.woff2') format('woff2');  font-weight: 700; font-display: swap; unicode-range: U+000-5FF; }
@font-face { font-family:Overpass; src: local(Overpass-800), url('/fonts/Overpass-800.woff2') format('woff2');  font-weight: 800; font-display: swap; unicode-range: U+000-5FF; }
@font-face { font-family:Overpass; src: local(Overpass-900), url('/fonts/Overpass-900.woff2') format('woff2');  font-weight: 900; font-display: swap; unicode-range: U+000-5FF; }
.slick-list{max-height: 100%} .slick-track {max-height: 100%} .slick-slide { margin:0 40px 0 80px;opacity:0.3 } .slick-current{opacity:1} 
div.fill{flex: 1} .h-100{max-height: 100%; height: 100%} .ant-drawer-body{section{position:fixed}}
.no-overflow{max-height: 100%; overflow: hidden} .space-between{display:flex; justify-content:space-between}
.button-select-block{
    width: 100%; 
    padding-bottom: 10px;
    padding-top: 10px;
    .disabled {
        opacity: 0.5;
        touch-action: none;
        pointer-events: none;
        user-select: none;
    }
    @media (min-width: 768px) {
        max-width:532px; 
    }
}
.fullscreen{ position: absolute; top: 0; left: 0; right: 0; bottom: 0 }
.gradient-fullscreen{
    position: absolute;
    height: 100%;
    top: 0; 
    left: 0; 
    z-index:1; 
    pointer-events: none; 
    touch-action: none;
    max-width:100vw; 
    max-height: 100%; 
    width:100vw; 
    height: 100%; 
    background: url(${BgFade}) repeat-x;

    &:after {
        position: absolute;
        content: '';
        display: block;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 167px;
        background-image: url(${BgFade});
        background-repeat: repeat-x;
        background-position: 0 0, 0 500px;
        transform: rotate(180deg);
    }

    @media (max-width: 767px) {
        background-size: 100% 120px;
        &:after {
            background-size: 100% 120px;
        }
    }
    
}
.opacity-animation{ transition: opacity 0.5s ease-in-out}
.hidden-delay{opacity:0;visibility:hidden;pointer-events: none; touch-action: none;transition-delay:1s; transition: opacity 0.25s ease-in-out}
.no-touch{pointer-events: none; touch-action: none;}
.zindex--1{z-index:-1;}
.zindex-10{z-index:10;}
.visible-delay{opacity:1;}


#main-nav-list {
    li {
            span {
                transition: all .25s ease;
            }
        }
    }

    .preview-invisible{
        opacity:1; 
        pointer-events: none; 
        touch-action: none; 
        display: none !important;
        animation: ${fadeOut} 1s ease forwards;
      }

      .ant-checkbox-checked .ant-checkbox-inner::after{
          margin-left: 1px;
      }

.ant-tooltip {
    &.ant-tooltip-placement-top{
        padding: 5px 10px !important;
        height: 24px !important;
        background: #917052 !important;
    }
}
`
const tagManagerArgs = {
    gtmId: 'GTM-KR7ST8M',
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(<Provider store={store}><GlobalStyle /><App /></Provider>, document.getElementById('root'))
serviceWorker.unregister();