import React from 'react'
import PropTypes from 'prop-types'

const VRIcon = ({ fill }) => {
  return (
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="15" viewBox="0 0 27 15">
            <defs>
              <clipPath id="clipPath">
                <rect id="Retângulo_495" data-name="Retângulo 495" width="27" height="15" transform="translate(-1 -1)" fill="#afaead"/>
              </clipPath>
            </defs>
            <g id="Grupo_357" data-name="Grupo 357" transform="translate(1.043 0.521)">
              <g id="Grupo_357-2" data-name="Grupo 357" transform="translate(-0.042 0.479)" clipPath="url(#clipPath)">
                <path id="Caminho_486" data-name="Caminho 486" d="M92.689,72.9a2.955,2.955,0,1,0,2.955,2.955A2.959,2.959,0,0,0,92.689,72.9m0,4.761A1.806,1.806,0,1,1,94.5,75.854a1.808,1.808,0,0,1-1.806,1.806" transform="translate(-85.483 -69.831)" fill={fill}/>
                <path id="Caminho_487" data-name="Caminho 487" d="M318.689,72.9a2.955,2.955,0,1,0,2.955,2.955,2.959,2.959,0,0,0-2.955-2.955m0,4.761a1.806,1.806,0,1,1,1.806-1.806,1.808,1.808,0,0,1-1.806,1.806" transform="translate(-300.88 -69.831)" fill={fill}/>
                <path id="Caminho_488" data-name="Caminho 488" d="M22.93,0H2.029A2.031,2.031,0,0,0,0,2.029v9.125a2.031,2.031,0,0,0,2.029,2.029H10.99a.739.739,0,0,0,.739-.739V10.811a.443.443,0,0,1,.442-.442h.617a.443.443,0,0,1,.442.442v1.634a.739.739,0,0,0,.739.739H22.93a2.031,2.031,0,0,0,2.029-2.029V2.029A2.031,2.031,0,0,0,22.93,0m0,12.011H14.4v-1.2A1.617,1.617,0,0,0,12.788,9.2h-.617a1.617,1.617,0,0,0-1.615,1.615v1.2H2.029a.857.857,0,0,1-.856-.856V2.029a.857.857,0,0,1,.856-.856h20.9a.857.857,0,0,1,.856.856v9.125a.857.857,0,0,1-.856.856" transform="translate(0.041 -0.352)" fill={fill}/>
              </g>
            </g>
          </svg>
  )
}

VRIcon.propTypes = {
  fill: PropTypes.string
}

VRIcon.defaultProps = {
  fill: "#FFF"
}

export {VRIcon}