import React from 'react'
import { Tooltip } from 'antd'
import { useIntl } from 'react-intl'
import styled from 'styled-components';
import { useLocation } from 'react-router';

const SVG = styled.svg`
  margin-left: 9px;
  @media(min-width: 768px){
    // margin-left: -7px;
  }
`
const ActMap = ({ height, width, history = {}, compass = true }) => {
  const intl = useIntl()
  const location = useLocation()
  const renderHotspot = (
    <g id="Rectangle_703" onClick={() => history?.push('/vr/outside/1')} data-name="Rectangle 703" transform="translate(134.785 61.421) rotate(45)" 
    fill="rgba(145,112,82,0.13)" stroke="#917052" strokeWidth="1" className={location.pathname === '/vr/outside/1' ? 'hotspot-animation-active' : 'hotspot-animation'}>
      <Tooltip title={intl.formatMessage({ id: "outside" })}>
        <g className="animation-hotspot-map">
          <rect width="15.922" height="15.922" stroke="none"/>
          <rect x="0.5" y="0.5" width="14.922" height="14.922" fill="none"/>
        </g>
      </Tooltip>
    </g>
  )

  const renderCompass = (
    <g id="compass" transform="translate(75 130) rotate(-60)">
      <g id="compass" width="78px" height="78px" ref={r => window.compass = r}>
        <path id="square" d="M25 54 L55 54 55 24 25 24 Z" fill="#917052" fillOpacity="1" stroke="none" />
        <path id="arrow" d="M64 22 L57 15 66 13 64 22 Z" fill="#91877e" fillOpacity="1" stroke="none" />
        <path id="back" d="M78 39 C78 17.461 60.539 0 39 0 17.461 0 0 17.461 0 39 0 60.539 17.461 78 39 78 60.539 78 78 60.539 78 39 Z" fill="#917052" fillOpacity="0" stroke="none" />
        <path id="back" d="M0 78 L78 78 78 0 0 0 Z" fill="#917052" fillOpacity="0" stroke="none" />
      </g>
    </g>
  )

  return (
    <SVG xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 552.506 327.249">
      <g id="exterior-0" transform="translate(48.5 36.905)">
        <g id="Group_522" data-name="Group 522" transform="translate(11118.849 -9625)">
          <g id="Rectangle_684" data-name="Rectangle 684" transform="translate(-10875.551 9621.829)" fill="none" stroke="#707070" strokeWidth="1">
            <rect width="81.511" height="152.715" stroke="none"/>
            <rect x="0.5" y="0.5" width="80.511" height="151.715" fill="none"/>
          </g>
          <path id="Path_629" data-name="Path 629" d="M-11165.85,9589.595h180.115v57.842h110.559v109.826h-60.77v102.5h-229.9Z" transform="translate(0.001)" fill="none" stroke="#707070" strokeWidth="3"/>
          <path id="Path_630" data-name="Path 630" d="M-11029.593,9654.458H-11129.9v188.9h145.7v-88.593h-45.4Z" transform="translate(-2.268 -4.093)" fill="none" stroke="#707070" strokeWidth="1"/>
          <path id="Path_631" data-name="Path 631" d="M-10974.546,9811.1h-11.554v41.826" transform="translate(-11.92 -13.345)" fill="none" stroke="#707070" strokeWidth="1"/>
          <path id="Path_632" data-name="Path 632" d="M-10965.9,9811.1h-20.2v49.866" transform="translate(-20.565 -21.385)" fill="none" stroke="#707070" strokeWidth="1"/>
          <path id="Path_633" data-name="Path 633" d="M-10956.93,9811.1h-29.172v59.787" transform="translate(-29.537 -31.306)" fill="none" stroke="#707070" strokeWidth="1"/>
          <path id="Path_634" data-name="Path 634" d="M-10946.966,9811.1h-39.136v69.752" transform="translate(-39.501 -41.271)" fill="none" stroke="#707070" strokeWidth="1"/>
          <path id="Path_635" data-name="Path 635" d="M-10600.279,9597.237H-10844.5v24.218h-15.77v152.631h-45.059v140.24h305.045" transform="translate(-14.564 -0.482)" fill="none" stroke="#707070" strokeWidth="3"/>
        </g>
        {compass ? renderCompass : renderHotspot}
      </g>
    </SVG>
  )
}

//outside
export const ActMap5 = React.memo(ActMap)