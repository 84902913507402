//environemnts (rooms)
export const WalkSprite = require('../assets/icons/walk_sprite.png')
export const ProductSprite = require('../assets/icons/product_sprite.png')
export const Walkspot = require('./icons/walkspot.png')

export const material = require('../assets/backgrounds/material.png')
export const nav = require('../assets/backgrounds/nav.png')
export const SplashMap = require('../assets/backgrounds/map.png')
export const SplashShadow = require('../assets/backgrounds/shadow.png')
export const SplashBg = require('../assets/backgrounds/splash.png')
export const SplashBgMobile = require('../assets/backgrounds/splash_mobile.png')
export const ReadyBackground = require('../assets/backgrounds/EXTERIOR_interface.jpg')

export const preview = require('../assets/backgrounds/preview.jpg')
export const BlueDot = require('../assets/icons/bluedot.png')
export const MapRoomsA = require('../assets/backgrounds/roomsA.png')
export const MapRoomsB = require('../assets/backgrounds/roomsB.png')
export const MapRoomsC = require('../assets/backgrounds/roomsC.png')
export const MapRoomsD = require('../assets/backgrounds/roomsD.png')
export const MapRoomsE = require('../assets/backgrounds/roomsE.png')
export const MapRoomsF = require('../assets/backgrounds/roomsF.png')

export const IconNavegueEspaco = require('../assets/icons/icon-navegue-espaco.svg')
export const IconPersonalizeEspaco = require('../assets/icons/icon-personalize-espaco.svg')
export const IconPontoVista = require('../assets/icons/icon-ponto-vista.svg')

export const ProductPlaceholder = require('../assets/images/afyon_texture_placeholder.png')

export const BgFade = require('../assets/backgrounds/bgFade.png')