/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  FormattedMessage
} from './../'
import { 
  Wrapper, Container, QuadContainer, Quad, Border, BorderBlack, TextsContainer, Status, TextCarregar
} from './index.style'

const LoadingComponent = ({ className, progress = 0, isImage = false, previewPanorama = ""}) => {

  const [squadSize, setSquadSize] = useState(64);
  const [ step, setStep ] = useState(1);
  const [ progress2, setProgress ] = useState(isImage ? 1 : 0);


  useEffect(() => {
    if(isImage){
      if(progress === 0 && step !== 1){
        setStep(1)
      }
    }
    
  }, [progress]);
  
  useEffect(() => {
    if(!isImage){
      if(step === 1) setTimeout(() => {
        setStep(2);
        setSquadSize(1800)
      }, 2500);
    }
  },[step]);

  useEffect(() => {
    if(!isImage){
      if(progress2 >= 0 && progress2 <= 25) {
        setTimeout(() => setProgress(progress2 + 1), 5);
      } else {
        if(progress2 < 100) {
          setTimeout(() => setProgress(progress2 + 1), 8);
        }
      }
    }
  }, [progress2]); 

  useEffect(() => {
    if(isImage){
      if(progress >= 100){
        setTimeout(() => {
          setStep(2);
          setSquadSize(1800)
        }, 2000)
      }
    }
    
  }, [progress]);



  const showStep = () => {
    let whoIs = isImage ? progress : progress2
    switch(step) {
      case 1:
        return (
          <QuadContainer size={squadSize} opacity={1} translate={0}>
            <Quad size={squadSize}>
              <Border className="top-left" />
              <Border className="top-right" />
              <Border className="bottom-left" />
              <Border className="bottom-right" />
              <BorderBlack className="top-left" />
              <BorderBlack className="top-right" />
              <BorderBlack className="bottom-left" />
              <BorderBlack className="bottom-right" />
            </Quad>
            <TextsContainer>
                <Status>{whoIs >= 100 ? 100 : whoIs}%</Status>
                <TextCarregar>
                  <FormattedMessage id="loading" />
                </TextCarregar>
            </TextsContainer>
          </QuadContainer>
        )
        case 2:
          return (
            <QuadContainer size={squadSize} opacity={0} translate={500}>
              <Quad size={squadSize}>
                <Border className="top-left" />
                <Border className="top-right" />
                <Border className="bottom-left" />
                <Border className="bottom-right" />
              </Quad>
              
            </QuadContainer>
          )
      default:
        return null
    }
  }
  const loadingStyle = { backgroundImage: `url(${previewPanorama})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: "center" }

  return (
    <div className={className}>
       {previewPanorama !== "" && <div className={`absolute-100 h-100 w-100 overflow-hidden zindex-10`} style={loadingStyle}></div>}
      <Container>
        <Wrapper>
        {showStep()}
        </Wrapper>
      </Container>
    </div>
  )
}

export default React.memo(LoadingComponent)
