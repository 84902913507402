import styled, { keyframes } from 'styled-components'


const showForm = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  height: 100%;

  @media (min-width: 768px) {
    height: initial;
    flex-direction: row;
    margin: 0 60px;

    &.expand {
      height: 70%;
    }
  }
`

export const TitleContainer = styled.div`
  width: 100%;
  opacity: 0;
  animation: ${showForm} .5s ease-out forwards;
  @media (min-width: 768px) {
    width: 50%;
  }
`

export const Title = styled.h1`
  font-family: Overpass;
  font-size: .9rem;
  color: #FFF;

  span {
    position: relative;
    cursor: pointer;
    &:after {
      position: absolute;
      top: 1.2rem;
      left: 0;
      height: 1px;
      width: 100%;
      background: #FFF;
      content: ''
    }
  }

  @media (min-width: 768px) {
    font-size: 1.2rem;
    line-height: 2rem;
    max-width: 480px;
  }
`

export const FormContainer = styled.div`
  width: 100%;
  color: #FFF;
  /* border: solid 1px red; */
  animation: ${showForm} .5s ease-out forwards;

  @media (min-width: 768px) {
    width: 50%;
    height: 420px;
  }
`