import styled from 'styled-components/macro';

export const Container = styled.div`
    position: fixed !important;
    width: 300px !important;
    height: 54px !important;
    bottom: 50%;
    z-index: 1 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 50%;

    background: rgba(0,0,0,0);
    transition: all .5s ease;

    transform: rotate(-90deg) translate(-10%, 0);
    
    padding: 0 8px;
    border-radius: 26px;
    border: solid 2px transparent;

    &:hover {
      background: rgba(0,0,0, .5);
      border: solid 2px #b3b6af;
    }

    .ant-slider-rail { 
      background-color: rgba(255,255,255, .3) !important;
      height: 2px;
      outline: 1px solid rgba(0,0,0, .05);
    }

    .ant-slider-track {
      background-color: rgba(255,255,255, 1) !important;
      height: 2px;
      outline: 1px solid rgba(0,0,0, .05);
    }

    .ant-slider-handle.ant-tooltip-open {
      width: 6px !important;
      height: 6px !important;
      border: none;
      border-radius: 0%;
      transform: translate(-50%, 40%) rotate(45deg) !important;
    }

    @media (min-width: 768px){
      transform: rotate(0) translate(-50%, 0);
      bottom: 60px !important;
    }
`

export const ButtonZoom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: 54px;
  cursor: pointer;
  font-size: 20px;

  transform: rotate(-90deg);
  
  @media (min-width: 768px){
    transform: rotate(0);
  }


  svg {
    width: 20px;
    height: 20px;
    opacity: ${p => p.initialOpacity};
    transition: all .3s ease;
    fill: #FFF;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`