import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

const ColLogoStyled = styled(Col)` padding:10px; padding-top:30px;`
const CloseButtonStyled = styled(CloseOutlined)` padding:10px; padding-top:30px;`
const HeaderComponent = ({ handleMouseDown = (() => { }), menuVisibility = false }) => {
  return (menuVisibility ?
    <Row>
      <Col span={3}>Revigres</Col>
      <Col span={20} />
      <Col span={1}>
        <MenuOutlined onClick={handleMouseDown} />
      </Col>
    </Row>
    :
    <Row >
      <ColLogoStyled span={3}>Revigres</ColLogoStyled>
      <Col span={20} />
      <Col span={1}>
        <CloseButtonStyled onClick={handleMouseDown} />
      </Col>
    </Row>
  )
}
export default HeaderComponent
