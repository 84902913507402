import styled from 'styled-components';

export const ListStyled = styled.ul`
  margin-top:20px; 
  font-family: Martel, normal, normal; 
  font-size: 24px; 
  letter-spacing: 1.8px;
  color: #EEEBE9;
  list-style: none;
  a {text-decoration: none}; 
  text-align:${p => p.center ? 'center' : 'left'};
  padding: 0;
  @media (min-width: 768px) {
    margin-top:120px; 
    padding: initial;
    font-size: 56px;
    letter-spacing: 1.9px;
    margin-left: ${p => p.alignRight === true ? 'auto': 'initial'};
    width: ${p => p.alignRight === true ? '20px': 'initial'};
  }
`

export const ListItemIconsStyled = styled.li`
  font-size:16px;
  margin-bottom:16px;
  opacity: .6;
  transition: all .3s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &.active{
    opacity: 1;
  }
  
  svg {
    width:12px;
    height:12px;
  }

  @media (min-width: 768px) {
    margin-bottom:  22px;
    svg {
      width:16px;
      height:16px;
    }
  }
`


export const LanguageSelectStyled = styled.select`
  font-size: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 0px;
  padding: 0;
  padding-right: 1.4rem;

  @media (min-width: 768px) {
    padding: 0;
    padding-right: 1.4rem;
  }
`
export const LanguageStyled = styled.span`
  font-size: 11px;
`
