import React, { useRef, useEffect, useState } from 'react';
import { useResize } from '../../hooks'
import { Col, Drawer, Row } from 'antd';
import { useHistory } from 'react-router';
import { MenuSocialMedia, Footer, FloatLeftImage, HeaderStyled, DrawerLayout, FormattedMessage } from '../../components';
import { ColClose, HeaderCenter, HeaderText, LinkStyled, ListStyled, MenuContent } from './index.styled';
import { propsAreEqual } from '../../utils';


export const MenuHeader = React.memo(({ color="white", onClickLogo = () => { }, headerText = "", onClickAction = () => { }, close = true, btnOpen = true, onClickClose = () => { } }) => {
  const [totalChildren, setTotalChildren ] = useState(0);
  const isMobile = useResize().isMobile;
  const ref = useRef(null);
  const space = isMobile ? '15px': '30px';

  const classFlexEnd = totalChildren <= 1 ? 'flex-end' : 'space-between'; 

  const getMenuWidth = () => {
    let _width = 0;
    if(close) {
      _width = isMobile ? '45px' : '90px';
    } else {
      _width = isMobile ? '90px' : '120px';
    }
    return _width;
  }

  console.log(color)
  useEffect(() => {
    setTotalChildren(ref.current.childNodes.length);
  }, [])

  return (
    <HeaderStyled margin="20px">
      <Row justify="space-between" style={{position: 'relative'}}>
        <Col className="zindex-10" flex="120px" onClick={() => onClickLogo()} style={{cursor: 'pointer'}}><FloatLeftImage  alt="revigres" src={color === "white" ? "/logo.png" : "/logo_grey.svg"} /></Col>
        <HeaderCenter className="zindex-10 mt-4"><HeaderText color={color}>{headerText && <FormattedMessage id={headerText} />}</HeaderText></HeaderCenter>
        <ColClose className={`zindex-10 ${classFlexEnd}`} style={{width: getMenuWidth()}} ref={ref}>
          { close && <img  alt="menu" src="/close_icon.png" className="close-white" style={{ width: space , height: space, cursor: 'pointer'}} onClick={() => onClickClose()} />}
          { btnOpen && <img  alt="menu" src={color === "white" ? "/menu_icon_white.png" : "/menu_icon.png"} onClick={() => onClickAction()} style={{ width: space, height: space, cursor: 'pointer' }}/>}
        </ColClose>
      </Row>
    </HeaderStyled>
  )
}, propsAreEqual)

const MainMenu = ({ closeMenu, menu, btnOpen = true, onClickVR, showVR = false }) => {
  const history = useHistory()
  return (
    <Drawer placement="right" closable={true} width="100%" height="100%" closeIcon={null} visible={menu}>
      <DrawerLayout src='#7B8179'>
        <MenuHeader onClickClose={closeMenu} close={true} btnOpen={btnOpen}  />
        <MenuContent>
          <Row justify="center" align="top">
            <Col span={23}>
              <ListStyled>
                <LinkStyled onClick={() => history.push('/start')}>
                  <span><FormattedMessage id={"menu.choose-the-space"} /></span>
                </LinkStyled>
                <li>&nbsp;</li>
                {/* <LinkStyled onClick={() => window.open('https://www.revigres.pt', '_blank')}>
                  <span>Revigrés</span>
                </LinkStyled> */}
              </ListStyled>
            </Col>
            <Col span={1}>
              <MenuSocialMedia alignRight={true} showVR={showVR} onClickVR={() => {
                onClickVR()
                closeMenu()
              }} />
            </Col>
          </Row>
        </MenuContent>
        <Footer />
      </DrawerLayout>
    </Drawer>
  );
};
export default React.memo(MainMenu, propsAreEqual)
