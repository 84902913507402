import React from 'react'
import { Tooltip } from 'antd'
import { useIntl } from 'react-intl'
import styled from 'styled-components';
import { useLocation } from 'react-router';

const SVG = styled.svg`
  margin-left: 7px;
  @media(min-width: 768px){
    margin-left: 0;
  }
`

const ActMap = ({ height, width, history = {}, compass = true }) => {
  const intl = useIntl()
  const location = useLocation()
  const renderHotspot = (
    <g id="Rectangle_705" onClick={() => history?.push('/vr/suite/1')} data-name="Rectangle 705" transform="translate(309.785 216.487) rotate(45)" 
    fill="rgba(145,112,82,0.13)" stroke="#917052" strokeWidth="1" className={location.pathname === '/vr/suite/1' ? 'hotspot-animation-active' : 'hotspot-animation'}>
      <Tooltip title={intl.formatMessage({ id: "suite" })}>
        <g>
          <rect width="15.922" height="15.922" stroke="none"/>
          <rect x="0.5" y="0.5" width="14.922" height="14.922" fill="none"/>
        </g>
      </Tooltip>
    </g>
  )

  const renderCompass = (
    <g id="compass" transform="translate(275 150) rotate(120)">
      <g id="compass" width="78px" height="78px" ref={r => window.compass = r}>
        <path id="square" d="M25 54 L55 54 55 24 25 24 Z" fill="#917052" fillOpacity="1" stroke="none" />
        <path id="arrow" d="M64 22 L57 15 66 13 64 22 Z" fill="#91877e" fillOpacity="1" stroke="none" />
        <path id="back" d="M78 39 C78 17.461 60.539 0 39 0 17.461 0 0 17.461 0 39 0 60.539 17.461 78 39 78 60.539 78 78 60.539 78 39 Z" fill="#917052" fillOpacity="0" stroke="none" />
        <path id="back" d="M0 78 L78 78 78 0 0 0 Z" fill="#917052" fillOpacity="0" stroke="none" />
      </g>
    </g>
  )
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 608.014 330.474">
      <g id="quarto-1" transform="translate(66 -52)">
        <g id="Group_505" data-name="Group 505" transform="translate(14852 -9467)">
          <path id="Path_571" data-name="Path 571" d="M-14771.029,9435.971l-.056,214.983h247.777" transform="translate(-141.94 104.583)" fill="none" stroke="#777675" strokeWidth="3"/>
          <path id="Path_572" data-name="Path 572" d="M-14352.179,9698.713h69.5v-97.3" transform="translate(-28.809 149.261)" fill="none" stroke="#777675" strokeWidth="3"/>
          <path id="Path_573" data-name="Path 573" d="M-14289.919,9596.618V9423.56h-35.445" transform="translate(-21.567 101.231)" fill="none" stroke="#777675" strokeWidth="3"/>
          <path id="Path_574" data-name="Path 574" d="M-14302.714,9423.56h-242.909" transform="translate(-81.054 101.231)" fill="none" stroke="#777675" strokeWidth="3"/>
          <rect id="Rectangle_620" data-name="Rectangle 620" width="42" height="21" transform="translate(-14918 9519)" fill="#777675"/>
          <rect id="Rectangle_621" data-name="Rectangle 621" width="33" height="43" transform="translate(-14696 9519)" fill="#777675"/>
          <path id="Path_575" data-name="Path 575" d="M-14564.776,9427.5h-175.936" transform="translate(-133.737 102.296)" fill="none" stroke="#707070" strokeWidth="1"/>
          <path id="Path_576" data-name="Path 576" d="M-14572.164,9423.56h28.5" transform="translate(-88.219 101.231)" fill="none" stroke="#707070" strokeWidth="1"/>
          <path id="Path_577" data-name="Path 577" d="M-14572.164,9423.56h28.5" transform="translate(193.736 101.231)" fill="none" stroke="#707070" strokeWidth="1"/>
          <path id="Path_578" data-name="Path 578" d="M-14289.446,9441.73h-282.71v-85.453" transform="translate(-96.047 405.659)" fill="none" stroke="#707070" strokeWidth="1"/>
          <rect id="Rectangle_623" data-name="Rectangle 623" width="212" height="129" transform="translate(-14611 9569)" fill="rgba(255,255,255,0.1)"/>
          <rect id="Rectangle_622" data-name="Rectangle 622" width="132" height="181" transform="translate(-14860 9548)" fill="rgba(255,255,255,0.1)"/>
          <g id="Rectangle_624" data-name="Rectangle 624" transform="translate(-14826 9620)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="61" height="82" stroke="none"/>
            <rect x="0.5" y="0.5" width="60" height="81" fill="none"/>
          </g>
          <g id="Rectangle_656" data-name="Rectangle 656" transform="translate(-14913 9718)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="37" height="38" stroke="none"/>
            <rect x="0.5" y="0.5" width="36" height="37" fill="none"/>
          </g>
          <g id="Rectangle_657" data-name="Rectangle 657" transform="translate(-14913 9540)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="37" height="64" stroke="none"/>
            <rect x="0.5" y="0.5" width="36" height="63" fill="none"/>
          </g>
          <g id="Rectangle_658" data-name="Rectangle 658" transform="translate(-14913 9604)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="37" height="114" stroke="none"/>
            <rect x="0.5" y="0.5" width="36" height="113" fill="none"/>
          </g>
          <g id="Rectangle_638" data-name="Rectangle 638" transform="translate(-14684 9569)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="21" height="83" stroke="none"/>
            <rect x="0.5" y="0.5" width="20" height="82" fill="none"/>
          </g>
          <g id="Rectangle_626" data-name="Rectangle 626" transform="translate(-14608 9535)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="37" height="28" stroke="none"/>
            <rect x="0.5" y="0.5" width="36" height="27" fill="none"/>
          </g>
          <g id="Rectangle_627" data-name="Rectangle 627" transform="translate(-14441 9535)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="40" height="28" stroke="none"/>
            <rect x="0.5" y="0.5" width="39" height="27" fill="none"/>
          </g>
          <g id="Rectangle_631" data-name="Rectangle 631" transform="translate(-14342 9591)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="22" height="87" stroke="none"/>
            <rect x="0.5" y="0.5" width="21" height="86" fill="none"/>
          </g>
          <g id="Rectangle_639" data-name="Rectangle 639" transform="translate(-14596 9767)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="58" height="47" stroke="none"/>
            <rect x="0.5" y="0.5" width="57" height="46" fill="none"/>
          </g>
          <g id="Rectangle_634" data-name="Rectangle 634" transform="translate(-14521 9808)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="93" height="7" stroke="none"/>
            <rect x="0.5" y="0.5" width="92" height="6" fill="none"/>
          </g>
          <g id="Rectangle_635" data-name="Rectangle 635" transform="translate(-14521 9802)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="93" height="7" stroke="none"/>
            <rect x="0.5" y="0.5" width="92" height="6" fill="none"/>
          </g>
          <g id="Rectangle_636" data-name="Rectangle 636" transform="translate(-14468 9791)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="36" height="8" stroke="none"/>
            <rect x="0.5" y="0.5" width="35" height="7" fill="none"/>
          </g>
          <g id="Rectangle_637" data-name="Rectangle 637" transform="translate(-14517 9791)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="38" height="8" stroke="none"/>
            <rect x="0.5" y="0.5" width="37" height="7" fill="none"/>
          </g>
          <g id="Subtraction_8" data-name="Subtraction 8" transform="translate(-14539 9762)" fill="none">
            <path d="M131,56H0V0H131V6.364H120.826V52.182H131V56Z" stroke="none"/>
            <path d="M 130 55 L 130 53.18220138549805 L 120.8262329101563 53.18220138549805 L 119.8262329101563 53.18220138549805 L 119.8262329101563 52.18220138549805 L 119.8262329101563 6.364127159118652 L 119.8262329101563 5.364127159118652 L 120.8262329101563 5.364127159118652 L 130 5.364127159118652 L 130 1 L 1 1 L 1 55 L 130 55 M 131 56 L 0 56 L 0 0 L 131 0 L 131 6.364127159118652 L 120.8262329101563 6.364127159118652 L 120.8262329101563 52.18220138549805 L 131 52.18220138549805 L 131 55.99872589111328 L 131 56 Z" stroke="none" fill="#a9a9a7"/>
          </g>
          <g id="Rectangle_629" data-name="Rectangle 629" transform="translate(-14503 9544)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="45" height="22" stroke="none"/>
            <rect x="0.5" y="0.5" width="44" height="21" fill="none"/>
          </g>
          <g id="Rectangle_630" data-name="Rectangle 630" transform="translate(-14550 9544)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="43" height="22" stroke="none"/>
            <rect x="0.5" y="0.5" width="42" height="21" fill="none"/>
          </g>
          <g id="Rectangle_628" data-name="Rectangle 628" transform="translate(-14559 9532)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="109" height="119" stroke="none"/>
            <rect x="0.5" y="0.5" width="108" height="118" fill="none"/>
          </g>
          <g id="Rectangle_625" data-name="Rectangle 625" transform="matrix(0.839, 0.545, -0.545, 0.839, -14760.081, 9538.651)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <path d="M15,0h5.562a15,15,0,0,1,15,15V30.022a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V15A15,15,0,0,1,15,0Z" stroke="none"/>
            <path d="M15,.5h5.562A14.5,14.5,0,0,1,35.062,15V30.022a2.5,2.5,0,0,1-2.5,2.5H3a2.5,2.5,0,0,1-2.5-2.5V15A14.5,14.5,0,0,1,15,.5Z" fill="none"/>
          </g>
          <g id="planta_2" data-name="planta 2" transform="translate(-14371.015 9534.936)">
            <path id="Path_579" data-name="Path 579" d="M-14326.512,9449.465c-3.339-2.056-5.71-19.771-5.71-19.771s6.041,13.287,5.71,19.771" transform="translate(14348.865 -9429.507)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_580" data-name="Path 580" d="M5.71,19.771C2.372,17.715,0,0,0,0S6.041,13.287,5.71,19.771" transform="matrix(0.883, 0.469, -0.469, 0.883, 26.701, 0)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_581" data-name="Path 581" d="M5.71,19.771C2.372,17.715,0,0,0,0S6.041,13.287,5.71,19.771" transform="translate(38.687 5.843) rotate(65)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_582" data-name="Path 582" d="M5.71,19.771C2.372,17.715,0,0,0,0S6.041,13.287,5.71,19.771" transform="translate(43.199 13.049) rotate(87)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_583" data-name="Path 583" d="M5.71,19.771C2.372,17.715,0,0,0,0S6.041,13.287,5.71,19.771" transform="matrix(-0.208, 0.978, -0.978, -0.208, 44.564, 20.273)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_584" data-name="Path 584" d="M5.71,19.771C2.372,17.715,0,0,0,0S6.041,13.287,5.71,19.771" transform="matrix(-0.643, 0.766, -0.766, -0.643, 43.397, 30.096)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_585" data-name="Path 585" d="M5.71,19.771C2.372,17.715,0,0,0,0S6.041,13.287,5.71,19.771" transform="matrix(-0.97, 0.242, -0.242, -0.97, 35.373, 40.252)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_586" data-name="Path 586" d="M5.71,19.771C2.372,17.715,0,0,0,0S6.041,13.287,5.71,19.771" transform="translate(25.726 42.962) rotate(-171)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_588" data-name="Path 588" d="M5.71,19.771C2.372,17.715,0,0,0,0S6.041,13.287,5.71,19.771" transform="translate(12.086 40.918) rotate(-133)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_589" data-name="Path 589" d="M5.71,19.771C2.372,17.715,0,0,0,0S6.041,13.287,5.71,19.771" transform="translate(3.732 32.515) rotate(-102)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_590" data-name="Path 590" d="M5.71,19.771C2.372,17.715,0,0,0,0S6.041,13.287,5.71,19.771" transform="translate(0 25.794) rotate(-87)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_591" data-name="Path 591" d="M5.71,19.771C2.372,17.715,0,0,0,0S6.041,13.287,5.71,19.771" transform="translate(1.743 15.742) rotate(-59)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_592" data-name="Path 592" d="M5.71,19.771C2.372,17.715,0,0,0,0S6.041,13.287,5.71,19.771" transform="matrix(0.927, -0.375, 0.375, 0.927, 8.204, 4.145)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1"/>
            <g id="Ellipse_74" data-name="Ellipse 74" transform="translate(19.015 15.064)" fill="#a9a9a7" stroke="#a9a9a7" strokeWidth="1">
              <ellipse cx="5" cy="5.5" rx="5" ry="5.5" stroke="none"/>
              <ellipse cx="5" cy="5.5" rx="4.5" ry="5" fill="none"/>
            </g>
          </g>
          <g id="Rectangle_641" data-name="Rectangle 641" transform="translate(-14419 9767)" fill="none" stroke="#a9a9a7" strokeWidth="1">
            <rect width="21" height="48" stroke="none"/>
            <rect x="0.5" y="0.5" width="20" height="47" fill="none"/>
          </g>
        </g>
        {compass ? renderCompass : renderHotspot}
        
      </g>
    </SVG>
  )
}
// suite
export const ActMap4 = React.memo(ActMap)