import React, { useRef } from 'react'
import { useHistory } from 'react-router';
import { useResize } from '../../hooks'
import { FooterStyled, ForwardIcon, FormattedMessage } from '..'
import { MainMenu } from '../../pages';
import { MenuHeader } from '../../pages/menu';
import {  
  ContentImage,
  ContentImageDiv,
  ContentLabel,
  ContentLabelBox,
  ContentLabelFixed,
  ContentRow,
  ContentRows,
  ContentScrollable,
  ContentShadow,
  ContentSpace,
  ContentWrap,
  FullScreenColor 
} from './index.styled';
import { ASSETS_FOLDER } from '../../constants/consts';
const enviroments = require('./../../assets/mock-data/enviroment.json')


const GalleryComponent = ({ showTitle=true, headerColor="white", headerText, onClose, items, translate = true, onClickEnv = () => {}, fromStart }) => {
  const history = useHistory()
  const isMobile = useResize().isMobile;
  const onCloseAction = onClose ?? (() => history.goBack())
  const [currentSelected, setSelectedUrl] = React.useState('')
  const [visibleMenu, setVisibleMenu] = React.useState('')
  const goToVR = (url) => {
    if (currentSelected !== '') return;
    setSelectedUrl(url);
    setTimeout(() => {
      history.push(url, {})
    }, 450);
  }
  const onClickItem = (url, id) => { 
    onClickEnv(id)
    if (currentSelected === url) history.push(url, {}); else goToVR(url) 
  }

  const allItems = items.concat.apply([], items);

  const added = fromStart === true ? '?fromStart=true' : ''
  return (
    <FullScreenColor className="no-overflow space-between">
      {visibleMenu && <MainMenu menu={visibleMenu} closeMenu={() => setVisibleMenu(false)} btnOpen={false}/>}
      <MenuHeader 
        headerText={headerText}
        close={false}
        btnOpen={true}
        onClickLogo={() => history.push("/")} 
        onClickAction={() => setVisibleMenu(true)}
        onClickClose={onCloseAction} 
        color={headerColor}
      />
      <ContentScrollable>
        <ContentRows>
          {!isMobile && items.map((row, i) => (
            <React.Fragment key={i}>
              <ContentRow>
                {row.map((col, j) => {
                  return(
                    <GalleryItem 
                      translate={translate}
                      key={j} 
                      currentSelected={currentSelected} 
                      onClickItem={(url) => {
                        onClickItem(url, col.id)
                      }} 
                      url={`/vr/${col.id}/1${added}`} 
                      label={col.id} 
                      thumbnail={`${ASSETS_FOLDER}/${enviroments[0].folder}/${col.id}/preview/1.jpg`} 
                      mobile={isMobile ? true: false}
                      showTitle={showTitle}
                    />
                  )}
              )}
              </ContentRow>
              <ContentSpace />
            </React.Fragment>
          ))}
          {isMobile && allItems.map((row, i) => {
            
            return (
              <ContentRow key={i}>
                <GalleryItem 
                  translate={translate}
                  currentSelected={currentSelected} 
                  onClickItem={(url) => {
                    onClickItem(url, row.id)
                  }} 
                  url={`/vr/${row.id}/1${added}`} 
                  label={row.id} 
                  thumbnail={`${ASSETS_FOLDER}/${enviroments[0].folder}/${row.id}/preview/1.jpg`} 
                  mobile={true}
                />
              </ContentRow>
            )
            })}
        </ContentRows>
      </ContentScrollable>
      <FooterStyled height="0" padding="0" />
    </FullScreenColor>
  )
}

export const GalleryItem = React.memo(({ url, label, showTitle, thumbnail, currentSelected, onClickItem, mobile = 'false', translate = true }) => {
  const active = currentSelected === url
  const className = `label-box ${active ? "active" : ""}`
  const classMobile = mobile === true ? "mobile": "";

  const refText = useRef(null);
  const refTextHover = useRef(null);


  return (
    <ContentWrap md={8} className={classMobile}>
      <ContentImageDiv onClick={() => onClickItem(url)}>
        <ContentShadow className="shadow" />
        <ContentImage src={thumbnail} />
        {showTitle &&
          <ContentLabelFixed className="still" ref={refText}><FormattedMessage id={label} /></ContentLabelFixed>
        }
        <ContentLabelBox onClick={() => onClickItem(url)} className={className}>
          <ContentLabel>
            <span ref={refTextHover}>{translate ? <FormattedMessage id={label}  /> : label}</span>
            <span className="pr-3"><ForwardIcon height={mobile === true ? 14: 24}/></span>
          </ContentLabel>
        </ContentLabelBox>
      </ContentImageDiv>
    </ContentWrap >
  )
})

export default React.memo(GalleryComponent)




