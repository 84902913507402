/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { useHistory } from 'react-router';
import { useResize } from '../../hooks';
import { MenuHeader } from '../../pages/menu';
import { Footer, LoginComponent } from '../../components';
import { FullScreenColor, HeaderContainer } from './index.styled';


const ModalLoginComponent = ({ token, visible, setVisible}) => {
  const history = useHistory();
  const vhMobile = useResize().vhMobile;
  // let url = location.state?.redirect ? location.state?.redirect : redirect

  useEffect(() => {
    if(token !== null){
      setVisible()
    }
  }, [token])
  const className = visible ? "" : "invisible"
  return (
    <FullScreenColor vh={vhMobile} className={className}>
      <HeaderContainer>
        <MenuHeader onClickLogo={() => history.push("/")} onClickClose={() => setVisible()} btnOpen={false}/>
      </HeaderContainer>
      <LoginComponent />
      <Footer showHelp={false} />
    </FullScreenColor>
  )
}

const mapStateToProps = ({ app }) => {
  const { token } = app
  return { token }
}

export default connect(mapStateToProps, null)(React.memo(ModalLoginComponent))