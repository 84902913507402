import React from 'react'
import { connect } from 'react-redux';
import { ListItemIconsStyled, ListStyled, LanguageStyled } from './index.styled'
import { 
  LinkedinIcon, InstagramIcon, FacebookIcon, YoutubeIcon, FullScreenIcon, VRIcon 
} from '../../components';
import { 
  updateLanguage
} from '../../effects/actions';

const MenuSocialMedia = ({alignRight = false, locale, updateLanguage, onClickVR, showVR }) =>  {
  const [fullScreen, setFullScreen] = React.useState(false)
  const redirectToLink = (url) => {
    window.open(url, '_target')
  }

  const activeFullScreen = () => {
    setFullScreen(!fullScreen)
    const el = document.documentElement;
    if(fullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
    } else {
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.webkitRequestFullscreen) { /* Safari */
        el.webkitRequestFullscreen();
      } else if (el.msRequestFullscreen) { /* IE11 */
        el.msRequestFullscreen();
      }
    }
  }

  return (
    <ListStyled alignRight={alignRight}>
      <ListItemIconsStyled onClick={() => redirectToLink('https://www.linkedin.com/company/revigres')}>
        <LinkedinIcon fill="#EEEBE9" />
      </ListItemIconsStyled>
      <ListItemIconsStyled onClick={() => redirectToLink('https://www.instagram.com/revigres_tiles')}>
        <InstagramIcon fill="#EEEBE9" />
      </ListItemIconsStyled>
      <ListItemIconsStyled onClick={() => redirectToLink('https://www.facebook.com/RevigresTiles')}>
        <FacebookIcon fill="#EEEBE9" />
      </ListItemIconsStyled>
      <ListItemIconsStyled onClick={() => redirectToLink('https://www.youtube.com/user/REVIGREScom')}>
        <YoutubeIcon fill="#EEEBE9" />
      </ListItemIconsStyled>
      <li><hr width="100%" style={{border: 'solid .5px #FFF', opacity: '.3', marginBottom: '1.5rem'}} /></li>
      <ListItemIconsStyled onClick={() => activeFullScreen()}>
        <FullScreenIcon fill="#EEEBE9" />
      </ListItemIconsStyled>
      {showVR && (<ListItemIconsStyled onClick={() => onClickVR()}>
        <VRIcon fill="#EEEBE9" />
      </ListItemIconsStyled>)}
      <li><hr width="100%" style={{border: 'solid .5px #FFF', opacity: '.3', marginBottom: '1.5rem'}} /></li>
      <ListItemIconsStyled onClick={() => updateLanguage("pt")} className={locale === "pt" ? "active" : ""}>
        <LanguageStyled >PT</LanguageStyled>
      </ListItemIconsStyled>
      <ListItemIconsStyled onClick={() => updateLanguage("en")} className={locale === "en" ? "active" : ""}>
        <LanguageStyled>EN</LanguageStyled>
      </ListItemIconsStyled>
    </ListStyled>
  )
}

const mapStateToProps = ({ app }) => {
  const { locale } = app
  return { locale }
}

export default connect(mapStateToProps, { updateLanguage })(React.memo(MenuSocialMedia))
