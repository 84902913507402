import React from 'react'
import { Tooltip } from 'antd'
import { useIntl } from 'react-intl'
import styled from 'styled-components';
import { useLocation } from 'react-router';

const SVG = styled.svg`
  margin-left: 9px;
  margin-bottom: 5px;
  @media(min-width: 768px){
    // margin-left: -41px;
  }
`
const ActMap = ({ height, width, history = {}, compass = true }) => {
  const intl = useIntl()
  const location = useLocation()
  const renderHotspot = (
    <g onClick={() => history?.push('/vr/kitchen/1')} id="Rectangle_703" data-name="Rectangle 703" transform="translate(248.785 168.421) rotate(45)" 
    fill="rgba(145,112,82,0.13)" stroke="#917052" strokeWidth="1" className={location.pathname === '/vr/kitchen/1'? 'hotspot-animation-active' : 'hotspot-animation'}>
       <Tooltip title={intl.formatMessage({ id: "kitchen"})}>
          <g>
            <rect width="15.922" height="15.922" stroke="none"/>
            <rect x="0.5" y="0.5" width="14.922" height="14.922" fill="none"/>
          </g>
        </Tooltip>
    </g>
  )

  const renderCompass = (
    <g id="compass" transform="translate(200 140) rotate(120)">
      <g id="compass" width="78px" height="78px" ref={r => window.compass = r}>
        <path id="square" d="M25 54 L55 54 55 24 25 24 Z" fill="#917052" fillOpacity="1" stroke="none" />
        <path id="arrow" d="M64 22 L57 15 66 13 64 22 Z" fill="#91877e" fillOpacity="1" stroke="none" />
        <path id="back" d="M78 39 C78 17.461 60.539 0 39 0 17.461 0 0 17.461 0 39 0 60.539 17.461 78 39 78 60.539 78 78 60.539 78 39 Z" fill="#917052" fillOpacity="0" stroke="none" />
        <path id="back" d="M0 78 L78 78 78 0 0 0 Z" fill="#917052" fillOpacity="0" stroke="none" />
      </g>
    </g>
  )

  return (
    <SVG xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 402.095 328.383" >
      <g id="cozinha-0" transform="translate(-6.523 -6.5)">

        {compass ? renderCompass : renderHotspot}
        <g id="Group_506" data-name="Group 506" transform="translate(13817 -9628.618)">
          <path id="Path_593" data-name="Path 593" d="M-13410.237,9943.231v18.43h-55.845" fill="none" stroke="#777675" strokeWidth="3"/>
          <path id="Path_594" data-name="Path 594" d="M-13764.158,9961.661h-44.818V9636.618h399.095V9881.34" fill="none" stroke="#777675" strokeWidth="3"/>
          <path id="Path_595" data-name="Path 595" d="M-13469.009,9958.474v-3.53h-73.3v3.53" transform="translate(0.571 5.028)" fill="none" stroke="#a8a8a6" strokeWidth="1"/>
          <path id="Path_598" data-name="Path 598" d="M-13469.009,9958.474v-3.53h-73.3v3.53" transform="translate(-146.285 5.028)" fill="none" stroke="#a8a8a6" strokeWidth="1"/>
          <path id="Path_596" data-name="Path 596" d="M-13469.009,9958.474v-3.53h-73.3v3.53" transform="translate(-72.857 5.028)" fill="none" stroke="#a8a8a6" strokeWidth="1"/>
          <path id="Path_597" data-name="Path 597" d="M-13469.009,9958.474v-3.53h-73.3v3.53" transform="translate(-219.713 5.028)" fill="none" stroke="#a8a8a6" strokeWidth="1"/>
          <g id="Rectangle_642" data-name="Rectangle 642" transform="translate(-13526 9784)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="107" height="49" stroke="none"/>
            <rect x="0.5" y="0.5" width="106" height="48" fill="none"/>
          </g>
          <g id="Rectangle_643" data-name="Rectangle 643" transform="translate(-13708 9738)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="73" height="147" stroke="none"/>
            <rect x="0.5" y="0.5" width="72" height="146" fill="none"/>
          </g>
          <g id="Rectangle_646" data-name="Rectangle 646" transform="translate(-13809 9702)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="43" height="183" stroke="none"/>
            <rect x="0.5" y="0.5" width="42" height="182" fill="none"/>
          </g>
          <g id="Rectangle_644" data-name="Rectangle 644" transform="translate(-13702 9767)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="23" height="21" rx="3" stroke="none"/>
            <rect x="0.5" y="0.5" width="22" height="20" rx="2.5" fill="none"/>
          </g>
          <g id="Rectangle_645" data-name="Rectangle 645" transform="translate(-13702 9790)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="23" height="21" rx="3" stroke="none"/>
            <rect x="0.5" y="0.5" width="22" height="20" rx="2.5" fill="none"/>
          </g>
          <g id="Ellipse_75" data-name="Ellipse 75" transform="translate(-13687 9780)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <circle cx="3" cy="3" r="3" stroke="none"/>
            <circle cx="3" cy="3" r="2.5" fill="none"/>
          </g>
          <g id="Ellipse_76" data-name="Ellipse 76" transform="translate(-13687 9792)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <circle cx="3" cy="3" r="3" stroke="none"/>
            <circle cx="3" cy="3" r="2.5" fill="none"/>
          </g>
          <g id="Group_493" data-name="Group 493" style={{ isolation: "isolate"}}>
            <g id="Rectangle_647" data-name="Rectangle 647" transform="translate(-13800 9790)" fill="none" stroke="#a9a9a7" strokeWidth="1">
              <rect width="16" height="16" stroke="none"/>
              <rect x="0.5" y="0.5" width="15" height="15" fill="none"/>
            </g>
            <g id="Ellipse_77" data-name="Ellipse 77" transform="translate(-13795 9795)" fill="none" stroke="#a9a9a7" strokeWidth="1">
              <circle cx="3" cy="3" r="3" stroke="none"/>
              <circle cx="3" cy="3" r="2.5" fill="none"/>
            </g>
            <path id="Path_599" data-name="Path 599" d="M-13792,9790v2" transform="translate(0 0.554)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_600" data-name="Path 600" d="M-13792,9790v2" transform="translate(0 13.379)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_601" data-name="Path 601" d="M-13792,9790v2" transform="translate(-3994.587 23589.967) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_602" data-name="Path 602" d="M-13792,9790v2" transform="translate(-4007.413 23589.967) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
          </g>
          <g id="Group_496" data-name="Group 496" transform="translate(0 15)" style={{ isolation: "isolate"}}>
            <g id="Rectangle_647-2" data-name="Rectangle 647" transform="translate(-13800 9790)" fill="none" stroke="#a9a9a7" strokeWidth="1">
              <rect width="16" height="16" stroke="none"/>
              <rect x="0.5" y="0.5" width="15" height="15" fill="none"/>
            </g>
            <g id="Ellipse_77-2" data-name="Ellipse 77" transform="translate(-13795 9795)" fill="none" stroke="#a9a9a7" strokeWidth="1">
              <circle cx="3" cy="3" r="3" stroke="none"/>
              <circle cx="3" cy="3" r="2.5" fill="none"/>
            </g>
            <path id="Path_599-2" data-name="Path 599" d="M-13792,9790v2" transform="translate(0 0.554)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_600-2" data-name="Path 600" d="M-13792,9790v2" transform="translate(0 13.379)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_601-2" data-name="Path 601" d="M-13792,9790v2" transform="translate(-3994.587 23589.967) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_602-2" data-name="Path 602" d="M-13792,9790v2" transform="translate(-4007.413 23589.967) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
          </g>
          <g id="Group_498" data-name="Group 498" transform="translate(0 30)" style={{ isolation: "isolate"}}>
            <g id="Rectangle_647-3" data-name="Rectangle 647" transform="translate(-13800 9790)" fill="none" stroke="#a9a9a7" strokeWidth="1">
              <rect width="16" height="16" stroke="none"/>
              <rect x="0.5" y="0.5" width="15" height="15" fill="none"/>
            </g>
            <g id="Ellipse_77-3" data-name="Ellipse 77" transform="translate(-13795 9795)" fill="none" stroke="#a9a9a7" strokeWidth="1">
              <circle cx="3" cy="3" r="3" stroke="none"/>
              <circle cx="3" cy="3" r="2.5" fill="none"/>
            </g>
            <path id="Path_599-3" data-name="Path 599" d="M-13792,9790v2" transform="translate(0 0.554)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_600-3" data-name="Path 600" d="M-13792,9790v2" transform="translate(0 13.379)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_601-3" data-name="Path 601" d="M-13792,9790v2" transform="translate(-3994.587 23589.967) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_602-3" data-name="Path 602" d="M-13792,9790v2" transform="translate(-4007.413 23589.967) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
          </g>
          <g id="Group_494" data-name="Group 494" transform="translate(15)" style={{ isolation: "isolate"}}>
            <g id="Rectangle_647-4" data-name="Rectangle 647" transform="translate(-13800 9790)" fill="none" stroke="#a9a9a7" strokeWidth="1">
              <rect width="16" height="16" stroke="none"/>
              <rect x="0.5" y="0.5" width="15" height="15" fill="none"/>
            </g>
            <g id="Ellipse_77-4" data-name="Ellipse 77" transform="translate(-13795 9795)" fill="none" stroke="#a9a9a7" strokeWidth="1">
              <circle cx="3" cy="3" r="3" stroke="none"/>
              <circle cx="3" cy="3" r="2.5" fill="none"/>
            </g>
            <path id="Path_599-4" data-name="Path 599" d="M-13792,9790v2" transform="translate(0 0.554)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_600-4" data-name="Path 600" d="M-13792,9790v2" transform="translate(0 13.379)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_601-4" data-name="Path 601" d="M-13792,9790v2" transform="translate(-3994.587 23589.967) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_602-4" data-name="Path 602" d="M-13792,9790v2" transform="translate(-4007.413 23589.967) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
          </g>
          <g id="Group_495" data-name="Group 495" transform="translate(15 15)" style={{ isolation: "isolate"}}>
            <g id="Rectangle_647-5" data-name="Rectangle 647" transform="translate(-13800 9790)" fill="none" stroke="#a9a9a7" strokeWidth="1">
              <rect width="16" height="16" stroke="none"/>
              <rect x="0.5" y="0.5" width="15" height="15" fill="none"/>
            </g>
            <g id="Ellipse_77-5" data-name="Ellipse 77" transform="translate(-13795 9795)" fill="none" stroke="#a9a9a7" strokeWidth="1">
              <circle cx="3" cy="3" r="3" stroke="none"/>
              <circle cx="3" cy="3" r="2.5" fill="none"/>
            </g>
            <path id="Path_599-5" data-name="Path 599" d="M-13792,9790v2" transform="translate(0 0.554)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_600-5" data-name="Path 600" d="M-13792,9790v2" transform="translate(0 13.379)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_601-5" data-name="Path 601" d="M-13792,9790v2" transform="translate(-3994.587 23589.967) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_602-5" data-name="Path 602" d="M-13792,9790v2" transform="translate(-4007.413 23589.967) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
          </g>
          <g id="Group_497" data-name="Group 497" transform="translate(15 30)" style={{ isolation: "isolate"}}>
            <g id="Rectangle_647-6" data-name="Rectangle 647" transform="translate(-13800 9790)" fill="none" stroke="#a9a9a7" strokeWidth="1">
              <rect width="16" height="16" stroke="none"/>
              <rect x="0.5" y="0.5" width="15" height="15" fill="none"/>
            </g>
            <g id="Ellipse_77-6" data-name="Ellipse 77" transform="translate(-13795 9795)" fill="none" stroke="#a9a9a7" strokeWidth="1">
              <circle cx="3" cy="3" r="3" stroke="none"/>
              <circle cx="3" cy="3" r="2.5" fill="none"/>
            </g>
            <path id="Path_599-6" data-name="Path 599" d="M-13792,9790v2" transform="translate(0 0.554)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_600-6" data-name="Path 600" d="M-13792,9790v2" transform="translate(0 13.379)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_601-6" data-name="Path 601" d="M-13792,9790v2" transform="translate(-3994.587 23589.967) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
            <path id="Path_602-6" data-name="Path 602" d="M-13792,9790v2" transform="translate(-4007.413 23589.967) rotate(90)" fill="none" stroke="#a9a9a7" strokeWidth="1"/>
          </g>
          <g id="Rectangle_648" data-name="Rectangle 648" transform="translate(-13611 9754) rotate(90)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <path d="M10,0h6A10,10,0,0,1,26,10V23a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z" stroke="none"/>
            <path d="M10,.5h6A9.5,9.5,0,0,1,25.5,10V22a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,22V10A9.5,9.5,0,0,1,10,.5Z" fill="none"/>
          </g>
          <g id="Rectangle_651" data-name="Rectangle 651" transform="translate(-13510.5 9760.5)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <path d="M10,0h6A10,10,0,0,1,26,10V23a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z" stroke="none"/>
            <path d="M10,.5h6A9.5,9.5,0,0,1,25.5,10V22a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,22V10A9.5,9.5,0,0,1,10,.5Z" fill="none"/>
          </g>
          <g id="Rectangle_653" data-name="Rectangle 653" transform="translate(-13436.5 9856.5) rotate(180)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <path d="M10,0h6A10,10,0,0,1,26,10V23a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z" stroke="none"/>
            <path d="M10,.5h6A9.5,9.5,0,0,1,25.5,10V22a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,22V10A9.5,9.5,0,0,1,10,.5Z" fill="none"/>
          </g>
          <g id="Rectangle_652" data-name="Rectangle 652" transform="translate(-13462.5 9760.5)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <path d="M10,0h6A10,10,0,0,1,26,10V23a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z" stroke="none"/>
            <path d="M10,.5h6A9.5,9.5,0,0,1,25.5,10V22a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,22V10A9.5,9.5,0,0,1,10,.5Z" fill="none"/>
          </g>
          <g id="Rectangle_654" data-name="Rectangle 654" transform="translate(-13484.5 9856.5) rotate(180)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <path d="M10,0h6A10,10,0,0,1,26,10V23a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z" stroke="none"/>
            <path d="M10,.5h6A9.5,9.5,0,0,1,25.5,10V22a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,22V10A9.5,9.5,0,0,1,10,.5Z" fill="none"/>
          </g>
          <g id="Rectangle_649" data-name="Rectangle 649" transform="translate(-13611 9797) rotate(90)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <path d="M10,0h6A10,10,0,0,1,26,10V23a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z" stroke="none"/>
            <path d="M10,.5h6A9.5,9.5,0,0,1,25.5,10V22a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,22V10A9.5,9.5,0,0,1,10,.5Z" fill="none"/>
          </g>
          <g id="Rectangle_650" data-name="Rectangle 650" transform="translate(-13611 9843) rotate(90)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <path d="M10,0h6A10,10,0,0,1,26,10V23a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V10A10,10,0,0,1,10,0Z" stroke="none"/>
            <path d="M10,.5h6A9.5,9.5,0,0,1,25.5,10V22a.5.5,0,0,1-.5.5H1A.5.5,0,0,1,.5,22V10A9.5,9.5,0,0,1,10,.5Z" fill="none"/>
          </g>
          <g id="Rectangle_655" data-name="Rectangle 655" transform="translate(-13587 9637)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="146" height="28" stroke="none"/>
            <rect x="0.5" y="0.5" width="145" height="27" fill="none"/>
          </g>
          <g id="Rectangle_660" data-name="Rectangle 660" transform="translate(-13623 9637)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="37" height="37" stroke="none"/>
            <rect x="0.5" y="0.5" width="36" height="36" fill="none"/>
          </g>
          <g id="Rectangle_661" data-name="Rectangle 661" transform="translate(-13659 9637)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="37" height="37" stroke="none"/>
            <rect x="0.5" y="0.5" width="36" height="36" fill="none"/>
          </g>
          <g id="Rectangle_666" data-name="Rectangle 666" transform="translate(-13809 9885)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="43" height="39" stroke="none"/>
            <rect x="0.5" y="0.5" width="42" height="38" fill="none"/>
          </g>
          <g id="Rectangle_667" data-name="Rectangle 667" transform="translate(-13809 9923)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="43" height="39" stroke="none"/>
            <rect x="0.5" y="0.5" width="42" height="38" fill="none"/>
          </g>
          <g id="Rectangle_662" data-name="Rectangle 662" transform="translate(-13695 9637)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="37" height="37" stroke="none"/>
            <rect x="0.5" y="0.5" width="36" height="36" fill="none"/>
          </g>
          <g id="Rectangle_663" data-name="Rectangle 663" transform="translate(-13731 9637)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="37" height="37" stroke="none"/>
            <rect x="0.5" y="0.5" width="36" height="36" fill="none"/>
          </g>
          <g id="Rectangle_664" data-name="Rectangle 664" transform="translate(-13767 9637)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="37" height="37" stroke="none"/>
            <rect x="0.5" y="0.5" width="36" height="36" fill="none"/>
          </g>
          <g id="Rectangle_665" data-name="Rectangle 665" transform="translate(-13809 9637)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="43" height="65" stroke="none"/>
            <rect x="0.5" y="0.5" width="42" height="64" fill="none"/>
          </g>
          <g id="Rectangle_659" data-name="Rectangle 659" transform="translate(-13442 9637)" fill="none" stroke="#a8a8a6" strokeWidth="1">
            <rect width="32" height="52" stroke="none"/>
            <rect x="0.5" y="0.5" width="31" height="51" fill="none"/>
          </g>
        </g>
      </g>
    </SVG>
  )
}

//kitchen
export const ActMap2 = React.memo(ActMap)