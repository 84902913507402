import React from 'react'
import PropTypes from 'prop-types'

const LinkedinIcon = ({ fill }) => {
  return (
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
              <defs>
                <clipPath id="clipPath">
                  <rect id="Retângulo_480" data-name="Retângulo 480" width="25" height="24" transform="translate(0 0)" fill={fill}/>
                </clipPath>
              </defs>
              <g id="Grupo_342" data-name="Grupo 342" transform="translate(1 1.164)">
                <g id="Grupo_341" data-name="Grupo 341" transform="translate(-1 -1.164)" clipPath="url(#clipPath)">
                  <path id="Caminho_471" data-name="Caminho 471" d="M22.515,20.779H17.539v-7.3c0-1.912-.778-3.218-2.491-3.218a2.515,2.515,0,0,0-2.375,1.729,3.231,3.231,0,0,0-.108,1.154v7.639H7.636S7.7,7.84,7.636,6.664h4.929V8.879c.291-.969,1.866-2.351,4.381-2.351,3.12,0,5.569,2.03,5.569,6.4ZM2.65,4.9H2.618A2.44,2.44,0,0,1,0,2.452,2.466,2.466,0,0,1,2.681,0,2.457,2.457,0,1,1,2.65,4.9M.568,6.665H4.956V20.779H.568V6.665Z" transform="translate(0.926 1.111)" fill={fill} fillRule="evenodd"/>
                </g>
              </g>
            </svg>
  )
}

LinkedinIcon.propTypes = {
  fill: PropTypes.string
}

LinkedinIcon.defaultProps = {
  fill: "#FFF"
}

export {LinkedinIcon}