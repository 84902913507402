import styled from 'styled-components';
import { Col, Layout } from 'antd';

export const MenuContent = styled(Layout.Content)`
  background: transparent; 
  margin-left: 20px; 
  margin-right: 20px; 
  display: flex; 
  flex-direction: column; 
  justify-content: flex-start; 
  overflow: hidden;
  @media (min-width: 768px) {
    margin-left: 60px; 
    margin-right: 60px;
    overflow: hidden;
  }
`
export const ListStyled = styled.ul`
  margin-top:70px; 
  font-family: Martel, normal, normal; 
  font-size: 24px; 
  letter-spacing: 1.8px;
  color: #EEEBE9;
  list-style: none;
  a {text-decoration: none}; 
  text-align:${p => p.center ? 'center' : 'left'};
  padding: 0;
  @media (min-width: 768px) {
    margin-top:120px; 
    padding: initial;
    font-size: 56px;
    letter-spacing: 1.9px;
  }
`
export const LinkStyled = styled.li`
  text-decoration: none; 
  color: #EEEBE9; 
  &.hover{color: #EEEBE9;} 
  &.click{color: #EEEBE9;} 
  span{color:#EEEBE9; 
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    :hover{
      color:#3C3C3C; 
      margin-left:28px; 
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -ms-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      cursor: pointer;
    }
  }
`
export const LanguageSelectStyled = styled.select`
  font-size: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 0px;
  padding: 0;
  padding-right: 1.4rem;

  @media (min-width: 768px) {
    padding: 0.6rem;
    padding-right: 1.4rem;
  }
`
export const HeaderCenter = styled.div`
  display: flex; 
  flex-direction: column; 
  text-align: center;
`
export const HeaderText = styled.span`
  font-size:18px;
  font-family:Overpass;
  color: ${(props) => props.color === "white" ? "#EEEBE9" : "#444444"}; 
  line-height: 1.25;
  text-align: left;
  @media (min-width: 768px) {
    text-align: center;
    padding-top:20px;
  }
`

export const ColClose = styled(Col)`
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  right: 0;
  &.flex-end{
    justify-content: flex-end;
  }
  
  @media (min-width: 768px) {
    position: relative;
  }
`