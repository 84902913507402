import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { MdErrorOutline, MdCheckCircle } from 'react-icons/md'
import { GoCheck, IoIosRadioButtonOn as On, IoIosRadioButtonOff as Off } from 'react-icons/all';
import { signUpAPI, getCountriesAPI, getDistrictsAPI } from './../../effects/actions'
import { store } from './../../effects/store'
import { FormattedMessage } from '..';
import { useIntl } from 'react-intl';
import { Container,
        UserTypeContainer,
        BulletsContainer,
        Bullet,
        InputText,
        Select,
        Form, 
        InputTextContainer, 
        InputTitle, 
        ButtonsContainer, 
        ValidationError,
        ValidationSuccess, 
        FormButton,
        CheckBoxGroup,
        CheckBoxContainer,
        TextTermsContent,
        TextTerms,
        CheckBox } from './index.styled';

const jobTitles = require('./../../assets/mock-data/jobtitles.json')
const professionals = require('./../../assets/mock-data/professionals.json')
const FormRegister = ({ countries, getCountriesAPI, getDistrictsAPI, districts }) => {

  const arUserType = ['particular', 'professional', 'reseller'];

  const initialInputs = { userType: 'particular', 
                          password: '', 
                          name: '',
                          country: '', 
                          email: '', 
                          phone: '', 
                          district: '', 
                          checked: false,
                          location: '',
                          company: '',
                          professionalType: '',
                          jobTitle: ''
                        }

  const [ inputs, setAllInputs ] = useState(initialInputs);

  const minLenghtPassword = 8;

  const intl = useIntl();

  const [ emailError, setEmailError ] = useState(false);
  const [ passwordError, setPasswordError ] = useState(false);
  const [ accountCreated, setAccountCreated ] = useState(false);
  const [ allFieldsRequired, setAllFieldsRequired ] = useState(false);
  

  useEffect(() => {
    getCountriesAPI()
    getDistrictsAPI()
  }, [getCountriesAPI, getDistrictsAPI])
  const handleChange = (e) => setAllInputs({...inputs, [e.target.name]: e.target.value})

  const CheckForm = () => {
    let allValid = true;
    setEmailError(!validateEmail(inputs.email))

    if(validatePassword(inputs.password) !== true) allValid = false;
    if(validateEmail(inputs.email) !== true) allValid = false;

    return allValid;
  }

  const toggleCheckBox = () => {
    setAllInputs({...inputs, checked: !inputs.checked})
  }

  const handleUserType = (userType) => {
    setAllInputs({...inputs, userType: userType})
  }

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const validatePassword = (password) => {
    setPasswordError(password.length < minLenghtPassword);
    return password.length >= minLenghtPassword;
  }

  

  const checkInputs = () => {
  console.log("inputs", inputs)
    if(inputs.userType === ''){
      return false
    }else if(inputs.password === ''){
      return false
    }else if(inputs.name === ''){
      return false
    }else if(inputs.country === ''){
      return false
    }else if(inputs.phone === ''){
      return false
    }else if(inputs.district === '' && inputs.country.toLocaleUpperCase() === "PT"){
      return false
    }else if(inputs.location === '' && inputs.country.toLocaleUpperCase() !== "PT"){
      return false
    }else if(inputs.company === '' && (inputs.userType === "reseller" || inputs.userType === "professional")){
      return false
    }else if(inputs.professionalType === '' && inputs.userType === "professional"){
      return false
    }else if(inputs.jobTitle === '' && inputs.userType === "reseller"){
      return false
    }else{
      return true
    }
    
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (CheckForm() && checkInputs()) {
      console.log("send")
      setAllInputs(initialInputs);
      setAccountCreated(true);
    } else {
      console.log("missing values")
      setAccountCreated(false);
    }
  }

  return (
    <Container>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <UserTypeContainer>
          <InputTitle><FormattedMessage id="login.type-of-user" /></InputTitle>
          <BulletsContainer>
            {arUserType.map((bullet, index) => {
              const classActive = inputs.userType === bullet;
              const bulletTitle = `login.type-${bullet}`
              return (
                    <Bullet className={classActive ? 'active': ''} onClick={() => handleUserType(bullet)} key={bullet}>
                      {classActive ? <On /> : <Off />}
                      <FormattedMessage id={bulletTitle} />
                    </Bullet>
              )
            })}
          </BulletsContainer>
        </UserTypeContainer>
        {inputs.userType === "professional" && (
          <InputTextContainer>
            <InputTitle><FormattedMessage id="login.type-of-professional" /></InputTitle>
            <Select name="professionalType" size="10px" onChange={(event) => {
              setAllInputs({...inputs, "professionalType": event.target.value})
            }}>
              <option value="" disabled>{intl.formatMessage({ id: "login.select"}) }</option>
              {professionals && professionals?.map((x, i) => {
                return (
                  <option value={x.value} key={i}>{intl.formatMessage({ id: x.id}) }</option>
                )
              })}
            </Select>
          </InputTextContainer>
        )}
        {inputs.userType === "reseller" && (
          <InputTextContainer>
            <InputTitle><FormattedMessage id="login.role" /></InputTitle>
            <Select name="jobTitle" size="10px" onChange={(event) => {
              setAllInputs({...inputs, "jobTitle": event.target.value})
            }}>
              <option value="" disabled>{intl.formatMessage({ id: "login.select"}) }</option>
              {jobTitles && jobTitles?.map((x, i) => {
                return (
                  <option value={x.value} key={i}>{intl.formatMessage({ id: x.id}) }</option>
                )
              })}
            </Select>
          </InputTextContainer>
        )}
        {(inputs.userType === "reseller" || inputs.userType === "professional") && (
          <InputTextContainer>
              <InputTitle><FormattedMessage id="login.company" /></InputTitle>
              <InputText
                className={'b-bottom empty'}
                placeholder={intl.formatMessage({ id: "login.company" })}
                name="company"
                value={inputs.company}
                onChange={handleChange}
              />
          </InputTextContainer>
        )}
        <InputTextContainer>
            <InputTitle><FormattedMessage id="login.name" /></InputTitle>
            <InputText
              className={'b-bottom empty'}
              placeholder={intl.formatMessage({ id: "login.enter-your-name" })}
              name="name"
              value={inputs.name}
              onChange={handleChange}
            />
        </InputTextContainer>
        <InputTextContainer>
            <InputTitle><FormattedMessage id="login.email" /></InputTitle>
            <InputText
              className={inputs.email !== '' ? 'b-bottom filled': 'b-bottom empty'}
              placeholder={intl.formatMessage({ id: "login.enter-your-email" })}
              name="email"
              value={inputs.email}
              onChange={handleChange}
            />
            {emailError && <ValidationError><MdErrorOutline /><FormattedMessage id="login.invalid-email" /></ValidationError>}
        </InputTextContainer>
        <InputTextContainer>
            <InputTitle><FormattedMessage id="login.phone" /></InputTitle>
            <InputText
              className={inputs.email !== '' ? 'b-bottom filled': 'b-bottom empty'}
              placeholder={intl.formatMessage({ id: "login.enter-your-phone" })}
              name="phone"
              value={inputs.phone}
              onChange={handleChange}
            />
        </InputTextContainer>
        <InputTextContainer>
            <InputTitle><FormattedMessage id="login.country" /></InputTitle>
            <Select name="country" size="10px" onChange={(event) => {
              setAllInputs({...inputs, "country": event.target.value})
            }}>
              <option value="" disabled>{intl.formatMessage({ id: "login.select"}) }</option>
              {countries && countries?.map((x, i) => {
                return (
                  <option value={x.alpha2} key={i}>{x.name}</option>
                )
              })}
            </Select>
        </InputTextContainer>
        {
          inputs.country.toLocaleUpperCase() === "PT" ? 
          (
            <InputTextContainer>
              <InputTitle><FormattedMessage id="login.district" /></InputTitle>
              <Select name="district" size="10px" onChange={(event) => {
                setAllInputs({...inputs, "district": event.target.value})
              }}>
                <option value="" disabled>{intl.formatMessage({ id: "login.select"}) }</option>
                {districts && districts?.map((x, i) => {
                  return (
                    <option value={x.id} key={i}>{x.name}</option>
                  )
                })}
              </Select>
            </InputTextContainer>
          )
          :
          (
            <InputTextContainer>
              <InputTitle><FormattedMessage id="login.district" /></InputTitle>
              <InputText
                className={inputs.email !== '' ? 'b-bottom filled': 'b-bottom empty'}
                placeholder={intl.formatMessage({ id: "login.enter-your-district" })}
                name="location"
                value={inputs.location}
                onChange={handleChange}
              />
            </InputTextContainer>
          )
        }
        
        <InputTextContainer>
          <InputTitle><FormattedMessage id="login.password" /></InputTitle>
          <InputText
            className={inputs.password !== '' ? 'b-bottom filled': 'b-bottom empty'}
            autoComplete="no"
            placeholder={intl.formatMessage({ id: "login.enter-your-password" })}
            name="password"
            type="password"
            value={inputs.password}
            onChange={handleChange}
          />
          {passwordError && <ValidationError><MdErrorOutline /><FormattedMessage id="login.min-8-required" /> </ValidationError>}
          {accountCreated && <ValidationSuccess><MdCheckCircle /><FormattedMessage id="login.account-created" /> </ValidationSuccess>}
          {allFieldsRequired && <ValidationError><MdErrorOutline /><FormattedMessage id="login.all-fields-are-required" /> </ValidationError>}

        </InputTextContainer>
        <CheckBoxGroup>
          <CheckBoxContainer>
            <CheckBox onClick={toggleCheckBox}>
              {inputs.checked && <GoCheck />}
            </CheckBox>
            
          </CheckBoxContainer>
          <TextTermsContent>
            <TextTerms>
              <FormattedMessage id="login.terms" />
            </TextTerms>
          </TextTermsContent>
        </CheckBoxGroup>
        <ButtonsContainer>
          <FormButton 
            type="submit"
            bg="#444444" 
            bghover="#353535" 
            border="#444444" 
            borderHover="#353535"
            disabled={!inputs.checked}
            onClick={() => {
              if(checkInputs()){
                setAllFieldsRequired(false)
                store.dispatch(signUpAPI({ 
                  type: inputs.userType,
                  name: inputs.name,
                  country_alpha2: inputs.country,
                  location: inputs.location,
                  district_id: inputs.district,
                  phone_number: inputs.phone,
                  company: inputs.company,
                  professional_type: inputs.professionalType,
                  job_title: inputs.jobTitle,
                  email: inputs.email, 
                  password: inputs.password
                }))
              }else{
                setAllFieldsRequired(true)
              }
            }}
          >
            <FormattedMessage id="login.create-account" />
          </FormButton>
        </ButtonsContainer>
      </Form>
    </Container>
    
  )
}

const mapStateToProps = ({ app }) => {
  const { countries, districts } = app
  return { countries, districts }
}

export default connect(mapStateToProps, {
  getCountriesAPI,
  getDistrictsAPI
})(React.memo(FormRegister));