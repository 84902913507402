import React from 'react'
import PropTypes from 'prop-types'

const YoutubeIcon = ({ fill }) => {
  return (
            <svg xmlns="http://www.w3.org/2000/svg"  width="25" height="17" viewBox="0 0 25 17">
              <defs>
                <clipPath id="clipPath">
                  <rect id="Retângulo_477" data-name="Retângulo 477" width="25" height="17" transform="translate(0.5 -0.243)" fill={fill}/>
                </clipPath>
              </defs>
              <g id="Grupo_336" data-name="Grupo 336" transform="translate(-0.5 0.465)">
                <g id="Grupo_335" data-name="Grupo 335" transform="translate(0 -0.222)" clipPath="url(#clipPath)">
                  <path id="Caminho_468" data-name="Caminho 468" d="M10.2,11.387V4.74l6.945,3.335Zm15.253-7.8a4.869,4.869,0,0,0-1.022-2.347,3.851,3.851,0,0,0-2.576-1C18.259,0,12.862,0,12.862,0H12.85s-5.4,0-8.995.239a3.865,3.865,0,0,0-2.577,1A4.861,4.861,0,0,0,.257,3.589,32.971,32.971,0,0,0,0,7.417v1.8a32.928,32.928,0,0,0,.257,3.827,4.885,4.885,0,0,0,1.021,2.349A4.6,4.6,0,0,0,4.114,16.4c2.057.181,8.742.237,8.742.237s5.4-.008,9-.247a3.862,3.862,0,0,0,2.576-1,4.874,4.874,0,0,0,1.022-2.347,32.971,32.971,0,0,0,.257-3.828v-1.8a32.971,32.971,0,0,0-.257-3.828" transform="translate(0 0.162)" fill={fill} fillRule="evenodd"/>
                </g>
              </g>
            </svg>
          )
}

YoutubeIcon.propTypes = {
  fill: PropTypes.string
}

YoutubeIcon.defaultProps = {
  fill: "#FFF"
}

export {YoutubeIcon}